import React, { memo, forwardRef, useImperativeHandle, useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import classNames from "common/class-names";
import AppSelectInput from "components/app-select-input";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import getEmploymentEndStatusListing from "services/get-employment-end-status-listing";
import AppButton from "components/app-button";

export const AppProbationCompletedModal = (props, ref) => {
	const dispatch = useDispatch();
	const [user, setUser] = useState();
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ rejectReason: "", status: COMMON.STATUS_ID.APPROVED }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			rejectReason: yup.string().when("status", {
				is: (status) => status === COMMON.STATUS_ID.REJECTED,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});
	const isRejected = useMemo(() => formik.values.status === COMMON.STATUS_ID.REJECTED, [formik.values.status]);
	//prettier-ignore
	const radioClassName = useCallback((type) => {
		return classNames({
			"probation-completed-modal__radio": true,
			"probation-completed-modal__radio--active": type === formik.values.status
		});
	}, [formik.values.status]);

	const onHandleSetStatus = (event) => {
		const type = event.currentTarget.getAttribute("data-type");
		formik.setFieldValue("status", type);
	};

	const onHandleShow = useCallback((values) => {
		if (values) setUser(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let payload = null;
		
		try {
			if(values.status === COMMON.STATUS_ID.REJECTED) {
				payload = { email: user.email, rejectReason: values.rejectReason };
				await api.post.employee.rejectProbation(payload);
			}
			else {
				payload = { email: user.email };
				await api.post.employee.approveProbation(payload);
			}

			dispatch(promptAlertMessage({ message: "Status has been updated successfully" }));
			
			onHandleDismiss();

			if (props.onHandleGetList) props.onHandleGetList();
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}
		
	}, [user, formik, props, onHandleDismiss, dispatch]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-probation-completed-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="probation-completed-modal" aria-describedby="probation-completed-modal">
			<div className="probation-completed-modal">
				<form className="probation-completed-modal__main" onSubmit={formik.handleSubmit}>
					<div className="probation-completed-modal__header">
						<p className="probation-completed-modal__title">Employee Confirmation</p>
					</div>

					<div className="probation-completed-modal__body">
						<p className="probation-completed-modal__description">This employee has successfully completed the probationary period. Would you like to confirm them as a permanent staff member?</p>

						<div className="probation-completed-modal__options">
							<div className={radioClassName(COMMON.STATUS_ID.REJECTED)} data-type={COMMON.STATUS_ID.REJECTED} onClick={onHandleSetStatus}>
								<div className="probation-completed-modal__dot" />
								<p className="probation-completed-modal__label">Reject</p>
							</div>

							<div className={radioClassName(COMMON.STATUS_ID.APPROVED)} data-type={COMMON.STATUS_ID.APPROVED} onClick={onHandleSetStatus}>
								<div className="probation-completed-modal__dot" />
								<p className="probation-completed-modal__label">Approve</p>
							</div>
						</div>

						{isRejected && /*prettier-ignore */ <AppSelectInput required type="text" name="rejectReason" label="Reject Reason" placeholder="Please Select" loadOptions={getEmploymentEndStatusListing} value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} disabled={formik.isSubmitting} onChange={formik.handleChange} />}

						<div className="probation-completed-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppProbationCompletedModal));

AppProbationCompletedModal.propTypes = {
	ref: PropTypes.object,
};
