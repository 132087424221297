import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";

const PageSelectClaimType = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [claimTypes, setClaimTypes] = useState([]);
	const [selectedClaim, setSelectedClaim] = useState("");
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.insuranceCoverage.claim);
	}, [navigate]);

	const onHandleNext = useCallback(() => {
		const payload = { ...location.state, claimType: selectedClaim };

		navigate(pathnames.insuranceCoverage.uploadClaimDocuments, { state: payload });
	}, [navigate, location.state, selectedClaim]);

	const onHandleSelectClaim = (obj) => {
		if (obj.id === selectedClaim) setSelectedClaim("");
		else setSelectedClaim(obj.id);
	};

	const onHandleGetClaimTypes = useCallback(async (claimantId) => {
		let response = null;

		try {
			response = await api.get.coverages.policyClaimType(claimantId);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setClaimTypes(response);
	}, []);

	useEffect(() => {
		if (location.state.claimant) onHandleGetClaimTypes(location.state.claimant);
	}, [location.state.claimant, onHandleGetClaimTypes]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.COVERAGES.POLICY_CLAIM_TYPE);
		};
	}, [cancelRequest]);

	return (
		<div className="page-select-claim-type">
			<div className="select-claim-type">
				<h1 className="select-claim-type__title">Type of Claims</h1>
				<p className="select-claim-type__description">Note: Please tick the relevant claims type & refer to Claims Checklist for list of required supporting documents for submission</p>

				<div className="select-claim-type__card">
					{claimTypes.map((o, i) => {
						const value = selectedClaim === o.id;
						return <AppCheckbox key={i} name={o.code} label={o.label} value={value} onClick={() => onHandleSelectClaim(o)} />;
					})}
				</div>

				<div className="select-claim-type__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleCancel} />
					<AppButton type="button" label="Next" disabled={!selectedClaim} onClick={onHandleNext} />
				</div>
			</div>
		</div>
	);
};

export default PageSelectClaimType;
