import React, { useRef, useCallback, useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppTabs from "components/app-tabs";
import AppSearchInput from "components/app-search-input";
import AppClaimSummary from "components/pages/claim-management/app-claim-summary";
import AppClaimHistory from "components/pages/claim-management/app-claim-history";

const PageClaim = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const tabsNav = useMemo(() => [
		{ label: "Claim Summary", value: "Claim Summary" }, 
        { label: "Claim History", value: "Claim History" }, 
    ], []);
	const paramsRef = useRef({ tab: searchParams.get("tab") || tabsNav[0].value });
	const [tab, setTab] = useState(paramsRef.current.tab);
	const [user, setUser] = useState();

	const onHandleChangeTab = useCallback((v) => {
		setTab(v);
		paramsRef.current.tab = v;
		memoSetSearchParams.current({ tab: v }, { replace: true });
	}, []);

	const onHandlePendingApproval = useCallback(() => {
		if (user) navigate(pathnames.claimManagement.claimApproval + "?name=" + user.name);
	}, [user, navigate]);

	const Tab = useCallback(() => {
		switch (tab) {
			case "Claim Summary":
				return <AppClaimSummary />;
			case "Claim History":
				return <AppClaimHistory />;
			default:
				return null;
		}
	}, [tab]);

	useEffect(() => {
		if (state) {
			sessionStorage.setItem("page-claim-user-info", JSON.stringify(state));
			setUser(state);
		} else {
			const data = sessionStorage.getItem("page-claim-user-info");
			if (data) setUser(JSON.parse(data));
		}
	}, [state]);

	return (
		<div className="page-claim">
			<div className="claim">
				<div className="claim__header">
					<p className="claim__name">{user?.name || "-"}</p>
					<p className="claim__employee-id"> {user?.employeeId || "-"}</p>
				</div>

				<AppSearchInput buttons={[{ label: "Pending Approval", onClick: onHandlePendingApproval }]} />

				<AppTabs tabs={tabsNav} value={tab} onChange={onHandleChangeTab} />
				<Tab />
			</div>
		</div>
	);
};

export default PageClaim;
