import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { AppTableEditIcon } from "components/app-table";
import addIcon from "assets/images/add-icon.svg";

const PageBenefits = (props) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.benefits.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEdit = useCallback((obj) => {
		navigate(pathnames.companyBenefits.benefit + obj.id);
	}, [navigate]);

	const onHandleCreate = useCallback(() => {
		navigate(pathnames.companyBenefits.benefit + COMMON.APP_STATUS.NEW);
	}, [navigate]);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
        return <AppTableEditIcon onClick={(event) => onHandleEdit(row.original)} />;
    }, [onHandleEdit]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Package Name",
			accessor: "name",
			disableSortBy: false,
		},
		{
			Header: "Description",
			accessor: "description",
			disableSortBy: true,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.BENEFITS.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-benefits">
			<div className="benefits">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" label="Add New Benefit" onClick={onHandleCreate} />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput buttons={[{ label: "Add Benefit", icon: addIcon, onClick: onHandleCreate }]} />
						
						<AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default PageBenefits;
