import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo, useEffect, Fragment, useRef } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import { CALENDAR_FORMAT, formatDatePattern } from "common/calendar";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

const invoiceMonthFormat = CALENDAR_FORMAT.MONTH_FORMAT;

const invoiceDateFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_SHORT_FORMAT;

export const AppUnpaidDebitNoteModal = (props, ref) => {
	const orderIdRef = useRef();
	const [visible, setVisible] = useState(false);
	const [debitNote, setDebitNote] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState([]);

	const selectedAll = useMemo(() => {
		let total = 0;

		if (!debitNote) return !!total;

		const selectedInvoiceLength = selectedInvoice.length;

		total = Object.keys(debitNote)?.reduce((a, b) => {
			return a + debitNote[b].length;
		}, 0);

		return selectedInvoiceLength === total;
	}, [selectedInvoice, debitNote]);

	const totalPayableAmount = useMemo(() => {
		let total = 0;

		if (!debitNote) return total;

		Object.keys(debitNote).forEach((a) => {
			const item = debitNote[a];

			item.forEach((b) => {
				const found = selectedInvoice.some((o) => o === b.id);

				if (found) total += b.invoiceAmount;
			});
		});

		return total;
	}, [debitNote, selectedInvoice]);

	const onHandleGetList = useCallback(async (obj) => {
		let response = null;

		try {
			const payload = { orderId: obj.orderId, renewalId: obj.renewalId };

			response = await api.get.policies.unpaidDebitNote(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			let seq = 1;

			const sanitizedData = {};

			response.reverse().forEach((o) => {
				const month = formatDatePattern(o.invoiceDate, invoiceMonthFormat);

				if (!sanitizedData[month]) sanitizedData[month] = [];

				sanitizedData[month].push({ ...o, seq });

				seq += 1;
			});

			setDebitNote(sanitizedData);
		}
	}, []);

	//prettier-ignore
	const onHandleSelectInvoice = useCallback(({ id }) => {
        let nextInvoices = [...selectedInvoice];
        let ids = [];
        
        const foundSelectedIndex = nextInvoices.findIndex(o => o === id) ;

        if(foundSelectedIndex > -1) {
            ids = nextInvoices.splice(0, foundSelectedIndex);
        } else {
			const getSeqId = () => {
				let seq = 0;

				Object.keys(debitNote).forEach(a => {
					const item = debitNote[a];

					item.forEach(b => {
						if(b.id === id) seq = b.seq;
					});
				});

				return seq;
			}
			
			const seq = getSeqId();

            ids = Object.keys(debitNote).map((a) => {
                const monthIds = [];
                const item = debitNote[a];

                item.forEach((b) => {
                    if (seq >= b.seq) monthIds.push(b.id);
                });
    
                return monthIds;
            }).flat();
        }

		setSelectedInvoice(ids);
    }, [debitNote, selectedInvoice]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		setVisible(true);
		orderIdRef.current = obj.orderId;
		onHandleGetList(obj);
	}, [onHandleGetList]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleSelectAllInvoice = useCallback(() => {
		let ids = [];

		if(!selectedAll) {
			ids = Object.keys(debitNote).map((a) => {
				const item = debitNote[a];
				return item.map((b) => b.id);
			}).flat();
		}

		setSelectedInvoice(ids);
	}, [selectedAll, debitNote]);

	const onHandleMakePayment = useCallback(async () => {
		setSubmitting(true);
		let response = null;

		try {
			const payload = {
				ebInvoiceIds: selectedInvoice,
				orderId: orderIdRef.current,
				redirectUrl: window.location.href,
			};

			response = await api.post.policies.debitNoteInvoicePayment(payload);
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setSubmitting(false);
		}

		if (response) window.location.href = response.paymentUrl;
	}, [selectedInvoice]);

	useEffect(() => {
		const onHandleScrollHeight = () => {
			const element = document.querySelector(".unpaid-debit-note__list");

			if (element) element.style.maxHeight = `${window.innerHeight - 400}px`;
		};

		const debouncedCheck = debounce(onHandleScrollHeight, 50);

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-unpaid-debit-note-modal" }} open={visible} aria-labelledby="unpaid-debit-note" aria-describedby="unpaid-debit-note-modal">
			<div className="unpaid-debit-note">
				<div className="unpaid-debit-note__header">
					<p className="unpaid-debit-note__title">Select Invoice</p>
					<p className="unpaid-debit-note__description">Please select debit invoice to be paid</p>
				</div>

				{!debitNote && (
					<div className="unpaid-debit-note__loading">
						<LoadingLogo />
					</div>
				)}

				{debitNote && (
					<Fragment>
						<div className="unpaid-debit-note__sub-header">
							All
							<AppCheckbox unCheckIcon label="" onClick={onHandleSelectAllInvoice} name="all" value={selectedAll} />
						</div>

						<ul className="unpaid-debit-note__list">
							{Object.keys(debitNote).map((a) => {
								const item = debitNote[a];

								return (
									<Fragment key={a}>
										<p className="unpaid-debit-note__month">{a}</p>

										{item.map((b) => {
											const found = selectedInvoice.findIndex((o) => o === b.id) > -1;

											return (
												<li className="unpaid-debit-note__item" key={b.seq}>
													<div className="unpaid-debit-note__content">
														<p className="unpaid-debit-note__invoice-no">{b.invoiceNo}</p>
														<p className="unpaid-debit-note__date">{formatDatePattern(b.invoiceDate, invoiceDateFormat)}</p>
													</div>

													<div className="unpaid-debit-note__content">
														<p className="unpaid-debit-note__amount">RM {formatCurrency(b.invoiceAmount)}</p>
													</div>

													<AppCheckbox label="" onClick={() => onHandleSelectInvoice(b)} name={b.id.toString()} value={found} />
												</li>
											);
										})}
									</Fragment>
								);
							})}
						</ul>
					</Fragment>
				)}

				<div className="unpaid-debit-note__footer">
					<p className="unpaid-debit-note__premium-amount">Total Payable Amount</p>
					<p className="unpaid-debit-note__premium-amount">RM {formatCurrency(totalPayableAmount)}</p>
				</div>

				<div className="unpaid-debit-note__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Next" onClick={onHandleMakePayment} disabled={submitting} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppUnpaidDebitNoteModal));

AppUnpaidDebitNoteModal.propTypes = {
	ref: PropTypes.object,
};
