import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getCoveragePoliciesListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.coverages.policies();

		const content = res.map((o) => ({ ...o, label: o.policyNo, value: o.orderId }));

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getCoveragePoliciesListing;
