import React, { useMemo, useCallback, useState } from "react";

import AppListCalendar from "components/pages/leave-management/app-list-calendar";
import AppGridCalendar from "components/pages/leave-management/app-grid-calendar";

const LAYOUT = {
	LIST: "LIST",
	CALENDAR: "CALENDAR",
};

const PageLeaves = (props) => {
	const [layout, setLayout] = useState(LAYOUT.LIST);
	const isCalendar = useMemo(() => layout === LAYOUT.CALENDAR, [layout]);

	const onLayoutChange = useCallback((type) => {
		setLayout(type);
	}, []);

	return (
		<div className="page-leaves">
			<div className="leaves">
				{!isCalendar && <AppListCalendar onLayoutChange={onLayoutChange} type={LAYOUT.CALENDAR} />}
				{isCalendar && <AppGridCalendar onLayoutChange={onLayoutChange} type={LAYOUT.LIST} />}
			</div>
		</div>
	);
};

export default PageLeaves;
