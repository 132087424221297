import React, { memo } from "react";

const AppCompanyBenefitsIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="company-benefits-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.29391 8.51953L3.18724 8.51953C2.94792 8.51953 2.75391 8.71354 2.75391 8.95287L2.75391 12.6062C2.75391 12.8455 2.94792 13.0395 3.18724 13.0395L4.29391 13.0395C4.53323 13.0395 4.72724 12.8455 4.72724 12.6062L4.72724 8.95287C4.72724 8.71354 4.53323 8.51953 4.29391 8.51953Z"
				stroke={color}
			/>
			<path d="M4.30078 8.51945L6.14745 8.43945L9.55411 9.67279C9.55411 9.67279 10.4274 9.99279 10.4541 11.0195" stroke={color} />
			<path
				d="M7.44141 10.6797C7.44141 10.6797 8.42141 11.073 8.94807 11.0997C9.47474 11.1264 11.8547 11.0997 11.8547 11.0997C11.8547 11.0997 13.2347 10.9264 13.2481 11.913C13.2481 12.233 12.9081 12.2664 12.6347 12.3997L9.45474 13.373L4.94141 12.1064"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M9.09417 7.78695L10.7008 6.56029L12.3075 7.78695L11.7075 5.76029L13.2142 4.69362L11.3342 4.69362L10.7008 2.62695L10.0608 4.69362L8.1875 4.69362L9.6875 5.76029L9.09417 7.78695Z" stroke={color} strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppCompanyBenefitsIcon);
