import React, { memo } from "react";

const AppInsurancePlanIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="insurance-plan" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.135 3.52965C13.09 3.48965 13.025 3.46465 12.96 3.47465C12.93 3.47465 9.71504 3.78465 8.18504 1.69465C8.10004 1.57965 7.90004 1.57965 7.82004 1.69465C7.03004 2.77965 5.78004 3.20465 4.86504 3.36965C3.86504 3.55465 3.05004 3.47465 3.04004 3.47465C2.97504 3.46965 2.91504 3.48965 2.86504 3.53465C2.82004 3.57965 2.79004 3.63965 2.79004 3.69965V8.47465C2.88004 12.5946 7.87504 14.3746 7.92504 14.3896C7.95004 14.3996 7.97504 14.4046 8.00004 14.4046C8.01504 14.4046 8.06004 14.3996 8.07504 14.3946C8.12504 14.3746 13.12 12.5996 13.21 8.47465V3.69465C13.21 3.63465 13.185 3.56965 13.135 3.52965Z"
				stroke={color}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M8.0001 5.24023L8.9201 7.02523L10.9001 7.35023L9.4901 8.77523L9.7901 10.7602L8.0001 9.85523L6.2101 10.7602L6.5101 8.77523L5.1001 7.35023L7.0801 7.02523L8.0001 5.24023Z" fill={color} />
		</svg>
	);
};

export default memo(AppInsurancePlanIcon);
