import React, { memo } from "react";

const AppAnnouncementIcon = ({ color = "#ffffff" }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.6673 3.41177C13.6673 2.85212 13.2136 2.39844 12.654 2.39844C12.0943 2.39844 11.6406 2.85212 11.6406 3.41177V10.8918C11.6406 11.4514 12.0943 11.9051 12.654 11.9051C13.2136 11.9051 13.6673 11.4514 13.6673 10.8918V3.41177Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M11.4536 3.88281C10.6136 4.32948 8.84698 5.06281 6.11365 5.23615L3.82031 5.30948" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M11.5992 10.4206C10.7925 10.0073 8.9725 9.26062 6.1125 9.15396L3.8125 9.14062" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.51953 8.93271V5.35938" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.73396 9.47656L6.54063 13.3432C6.54063 13.4832 6.42729 13.6032 6.28063 13.6032H5.62729C5.48729 13.6032 5.44729 13.5032 5.36729 13.3432L3.64062 9.47656" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4.05203 5.30469H3.29203C2.7587 5.30469 2.33203 5.73802 2.33203 6.26469V8.17802C2.33203 8.71135 2.76536 9.13802 3.29203 9.13802H4.05203" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppAnnouncementIcon);
