import React, { useCallback, useRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { ResponsivePie } from "@nivo/pie";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { debounce } from "lodash";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { formatDatePattern, isDateBeforeToday } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppTabs from "components/app-tabs";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppAnnouncementIcon from "components/icons/app-announcement-icon";
import AppCreateAnnouncementModal from "components/app-create-announcement-modal";
import AppAnnouncementDescriptionModal from "components/pages/dashboard/app-announcement-description-modal";
import pdfIcon from "assets/images/pdf-icon.svg";
import pinIcon from "assets/images/pin-icon.svg";
import moreIcon from "assets/images/more-icon.svg";
import editIcon from "assets/images/edit-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";
import pinnnedIcon from "assets/images/pinned-icon.svg";
import holidayIcon from "assets/images/holiday-icon.svg";

const PageDashboard = (props) => {
	//prettier-ignore
	const tabsNav = useMemo(() => [
		{ label: "Posted", value: "Posted" },
		{ label: "Upcoming", value: "Upcoming" },
	], []);
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const totalEmloyeeRef = useRef();
	const createAnnouncementRef = useRef();
	const announcementDescriptionRef = useRef();
	const [data, setData] = useState({});
	const [tab, setTab] = useState(tabsNav[0].value);
	const [upcomings, setUpcomings] = useState({});
	const [announcements, setAnnouncements] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const remainingAnnouncements = useMemo(() => {
		const max = 15;
		const totalUpcomings = upcomings.content?.length || 0;
		const totalAnnuncements = announcements.content?.length || 0;

		return max - totalUpcomings - totalAnnuncements;
	}, [announcements, upcomings]);
	const disabledCreateAnnouncement = useMemo(() => remainingAnnouncements === 0, [remainingAnnouncements]);
	const today = useMemo(() => {
		const now = new Date();
		return `${now.toLocaleString("en-us", { weekday: "long" })}, ${formatDatePattern(now)}`;
	}, []);
	const greeting = useMemo(() => {
		const now = new Date();
		const currentHour = now.getHours();
		let text = "";

		if (currentHour < 12) {
			text = "good morning";
		} else if (currentHour < 18) {
			text = "good afternoon";
		} else {
			text = "good evening";
		}

		return `${profile?.fullName || "-"}, ${text}`;
	}, [profile]);

	const formatDate = (date) => {
		const now = new Date().getDate();
		const day = new Date(date).getDate();

		if (now === day) return "Today";

		if (day > 3 && day < 21) return day + "th";

		switch (day % 10) {
			case 1:
				return day + "st";
			case 2:
				return day + "nd";
			case 3:
				return day + "rd";
			default:
				return day + "th";
		}
	};

	const onHandleInitialize = useCallback(async () => {
		let response = null;
		let responseAnnouncements = null;
		let responseUpcomings = null;

		try {
			response = await api.get.dashboard.admin();
			responseAnnouncements = await api.get.announcements.list({ page: 0, size: 15, sort: "pinned,desc&sort=startDate,desc" });
			responseUpcomings = await api.get.announcements.upcoming({ page: 0, size: 15 });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData({ ...response, employeeSummary: response.employeeSummary.map((o, i) => ({ ...o, id: o.contractType, label: o.contractType, value: o.count })).filter((o) => o.label !== "Total") });
		}
		if (responseAnnouncements) setAnnouncements(responseAnnouncements);
		if (responseUpcomings) setUpcomings(responseUpcomings);
	}, []);

	//prettier-ignore
	const onHandleDeleteAnnouncement = useCallback(async (id) => {
		onhandleCloseMenu();

		let response = null;

		try {
			await api.post.announcements.delete(id);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			if (tab === tabsNav[1].value) setUpcomings((prev) => ({ ...prev, content: prev.content.filter((o) => o.id !== id) }));
			if (tab === tabsNav[0].value) setAnnouncements((prev) => ({ ...prev, content: prev.content.filter((o) => o.id !== id) }));
			dispatch(promptAlertMessage({ message: "Announcement has been deleted successfully" }));
		}
	}, [dispatch, tab, tabsNav]);

	//prettier-ignore
	const onHandleUpdateAnnouncementPin = useCallback(async (obj) => {
		let response = null;

		try {
			const payload = {
				"id": obj.id,
				"title": obj.title,
				"description": obj.description,
				"pinned": !obj.pinned,
				"startDate": obj.startDate,
				"endDate": obj.endDate
			}
			await api.post.announcements.update(payload);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			if (tab === tabsNav[1].value) setUpcomings((prev) => ({ ...prev, content: prev.content.map(o => o.id === obj.id ? ({ ...o, pinned: !obj.pinned }): o) }));
			if (tab === tabsNav[0].value) setAnnouncements((prev) => ({ ...prev, content: prev.content.map(o => o.id === obj.id ? ({ ...o, pinned: !obj.pinned }): o) }));
			dispatch(promptAlertMessage({ message: "Announcement has been updated successfully" }));
		}
	}, [dispatch, tab, tabsNav]);

	const onHandleCreateAnnouncement = useCallback((args) => {
		onhandleCloseMenu();
		createAnnouncementRef.current.onHandleShow(args);
	}, []);

	const onHandleReadMoreDescription = useCallback((text) => {
		announcementDescriptionRef.current.onHandleShow(text);
	}, []);

	const onHandleSelectAnnouncement = useCallback((event, original) => {
		setSelectedAnnouncement(original);
		setAnchorEl(event.currentTarget);
	}, []);

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
	};

	//prettier-ignore
	const Tabs = useCallback((obj) => {
		let list = [];

		switch (obj.tab) {
			case "Posted":
				list = obj.announcements;
				break;
			case "Upcoming":
				list = obj.upcomings;
				break;
			default:
				list = [];
				break;
		}

		return (
			<ul className="announcements">
				{list?.map((o, i) => {
					const pinClassName = classNames({
						announcements__button: true,
						"announcements__button--pin": !o.pinned,
						"announcements__button--pinned": o.pinned,
					});


					const status = isDateBeforeToday(new Date(), o.endDate) ? COMMON.STATUS_ID.ACTIVE : COMMON.STATUS_ID.EXPIRED;

					return (
						<li key={o.id} className="announcements__item">
							<div className="announcements__content">
								<p className="announcements__title">{o.title}</p>
								<p className="announcements__description">{o.description} </p>
								<p className="announcements__see-more" onClick={() => obj.onHandleReadMoreDescription(o.description)}>read more</p>
							</div>

							<div className="announcements__status">
								<AppStatus status={status} />
							</div>

							<div className="announcements__actions">
								{o.hrAnnouncementFile && (
									<button type="button" className="announcements__button announcements__button--pdf">
										<a href={o.hrAnnouncementFile.filePath} target="_blank" rel="noreferrer">
											<img src={pdfIcon} alt="attachement" />
										</a>
									</button>
								)}
								<button type="button" className={pinClassName} onClick={() => obj.onHandleUpdateAnnouncementPin(o)}>
									<img src={o.pinned ? pinnnedIcon : pinIcon} alt="pin" />
								</button>
								<button type="button" className="announcements__button announcements__button--more" onClick={(event) => obj.onHandleSelectAnnouncement(event, o)}>
									<img src={moreIcon} alt="more" />
								</button>
							</div>
						</li>
					);
				})}
			</ul>
		);
	}, []);

	useEffect(() => {
		const multiLineOverflows = (element) => {
			if (!element) return false;

			return element.scrollHeight > element.clientHeight + 1;
		};

		const onHandleAnnouncementDescription = () => {
			/* Apply see more button if multiline overflows */
			const elements = document.querySelectorAll(".announcements__description");

			elements.forEach((o) => {
				const isMultiLineOverflows = multiLineOverflows(o);
				const nextElementSibling = o.nextElementSibling;
				const isValidSeeMoreButton = nextElementSibling.className === "announcements__see-more";

				if (!isValidSeeMoreButton) return;

				if (isMultiLineOverflows) {
					nextElementSibling.style.display = "block";
				} else {
					nextElementSibling.style.display = "none";
				}
			});
		};

		const debouncedCheck = debounce(onHandleAnnouncementDescription, 50);

		if (announcements.content) onHandleAnnouncementDescription();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [announcements.content]);

	useEffect(() => {
		onHandleInitialize();
	}, [onHandleInitialize]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.DASHBOARD.ADMIN);
			cancelRequest(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.LIST);
			cancelRequest(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.UPCOMING);
		};
	}, [cancelRequest]);

	return (
		<div className="page-dashboard">
			<div className="dashboard">
				<h1 className="dashboard__headline">{greeting}</h1>
				<p className="dashboard__date">{today}</p>

				<div className="dashboard__section">
					<div className="card">
						<div className="card__header">
							<p className="card__title">Celebration Corner</p>
						</div>

						<ul className="celebrations">
							{data.birthdayEmployees?.map((o, i) => {
								return (
									<li key={i} className="celebrations__item celebrations__item--celebration">
										<div className="celebrations__profile" style={{ backgroundImage: `url(${o.avatar})` }} />
										<p className="celebrations__name">{o.name}</p>
										<p className="celebrations__date">{formatDate(o.date)}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card">
						<div className="card__header">
							<p className="card__title">Employee on Leave</p>

							<Link className="card__link" to={pathnames.leaveManagement.leaveDirectory}>
								<div className="dashboard__icon">
									<p className="dashboard__label">View All</p>
									<AppChevronIcon color="#0245a9" />
								</div>
							</Link>
						</div>

						<ul className="leaves">
							{data.employeesOnLeave?.map((o, i) => {
								return (
									<li key={i} className="leaves__item">
										<div className="leaves__profile" style={{ backgroundImage: `url(${o.avatar})` }} />
										<p className="leaves__name">{o.name}</p>
										<p className="leaves__balance">
											{o.leaveType} ({o.timeOffType})
										</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card">
						<div className="card__header">
							<p className="card__title">Holiday this Month</p>
						</div>

						<ul className="celebrations">
							{data.holidays?.map((o, i) => {
								return (
									<li key={i} className="celebrations__item">
										<img className="celebrations__icon" src={holidayIcon} alt="holiday" />
										<div className="celebrations__content">
											<p className="celebrations__name">{o.title}</p>
											{o.states && <p className="celebrations__state">{o.states}</p>}
										</div>
										<p className="celebrations__date">{formatDate(o.date)}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card card--total-employee">
						<div className="card__header">
							<p className="card__title">Total Employee</p>
							<p className="card__total">{Object.values(data.employeeSummary || [])?.reduce((t, { value }) => t + value, 0) || ""}</p>
						</div>

						<div className="total-employee" ref={totalEmloyeeRef}>
							<div className="total-employee__chart">
								<ResponsivePie
									data={data.employeeSummary || []}
									padAngle={0.7}
									cornerRadius={3}
									innerRadius={0.5}
									activeOuterRadiusOffset={8}
									arcLinkLabelsSkipAngle={10}
									arcLabelsSkipAngle={10}
									arcLinkLabelsThickness={2}
									arcLabelsTextColor="#ffffff"
									arcLinkLabelsTextColor="#333333"
									colors={{ datum: "data.color" }}
									arcLinkLabelsColor={{ from: "color" }}
									margin={{ top: 20, right: 80, bottom: 20, left: 30 }}
								/>
							</div>

							<ul className="total-employee__legends">
								{data.employeeSummary?.map((o) => {
									return (
										<li className="total-employee__legend" key={o.id}>
											<span className="total-employee__badge" style={{ backgroundColor: o.color }} />
											{o.label}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>

				<div className="dashboard__header">
					<div className="dashboard__content">
						<p className="dashboard__title">Announcements</p>
						<p className="dashboard__description">You are allowed to create a maximum of 15 posts, including both posted and upcoming announcements. </p>
					</div>

					<div className="dashboard__wrapper">
						<p className="dashboard__remaining">
							Remaining: <span>{remainingAnnouncements}</span>
						</p>
						<AppButton type="button" label="Create Announcement" icon={<AppAnnouncementIcon color={disabledCreateAnnouncement ? "#828a8f" : undefined} />} onClick={onHandleCreateAnnouncement} disabled={disabledCreateAnnouncement} />
					</div>
				</div>

				<div className="dashboard__section">
					<AppTabs tabs={tabsNav} value={tab} onChange={setTab} />

					{/* prettier-ignore */}
					<Tabs tab={tab} announcements={announcements.content} upcomings={upcomings.content} onHandleReadMoreDescription={onHandleReadMoreDescription} onHandleDeleteAnnouncement={onHandleDeleteAnnouncement} onHandleUpdateAnnouncementPin={onHandleUpdateAnnouncementPin} onHandleSelectAnnouncement={onHandleSelectAnnouncement} />
				</div>
			</div>

			<AppCreateAnnouncementModal ref={createAnnouncementRef} onHandleGetList={onHandleInitialize} />

			<AppAnnouncementDescriptionModal ref={announcementDescriptionRef} />

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={() => onHandleCreateAnnouncement(selectedAnnouncement)}><img className="app-table-menu__icon" src={editIcon} alt="edit" />Edit</MenuItem>
				<MenuItem className="app-table-menu__remove" onClick={() => onHandleDeleteAnnouncement(selectedAnnouncement.id)}><img className="app-table-menu__icon" src={removeIcon} alt="delete" />Delete</MenuItem>
			</Menu>
		</div>
	);
};

export default PageDashboard;
