import React, { useMemo, memo } from "react";
import { CALENDAR_FORMAT, getWeekdays } from "common/calendar";

const AppWeekDays = () => {
	const weekdays = useMemo(() => getWeekdays(CALENDAR_FORMAT.WEEKDAYS_FORMAT), []);

	return (
		<div className="app-weekdays">
			<ul className="weekdays">
				{weekdays.map((o) => (
					<li key={o.day} className="weekdays__item">
						<div className="weekdays__weekday">{o.day}</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default memo(AppWeekDays);
