import React, { useState, useMemo, useRef, useCallback, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { setLogo } from "store/slices/profile";
import getfileName from "common/get-file-name";
import downloadFiles from "common/download-files";
import { getDobAgeInRange } from "common/nric-helpers";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import validateImageDimension from "common/validate-image-dimension";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppFileIcon from "components/icons/app-file-icon";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppInviteEmployeeModal from "components/app-invite-employee-modal";
import AppUserManagementIcon from "components/icons/app-user-management-icon";
import AppInsufficientInsurerModal from "components/pages/setup/app-insufficient-insurer-modal";
import uploadIcon from "assets/images/upload-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";
import editIcon from "assets/images/green-edit-icon.svg";
import exclamationIcon from "assets/images/full-exclamation-icon.svg";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";

const PageSetup = (props) => {
	const ACCORDIONS = useMemo(() => ({ ADMIN: "ADMIN", EMPLOYEES: "EMPLOYEES", DOCUMENTS: "DOCUMENTS" }), []);
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const ageRef = useRef();
	const uploadInputRef = useRef({});
	const inviteEmployeeRef = useRef();
	const companyLogoInputRef = useRef();
	const insufficientInsurerRef = useRef();
	const [uploadProgress, setUploadProgress] = useState({});
	const [documentFields, setDocumentFields] = useState([]);
	const [accordion, setAccordion] = useState(ACCORDIONS.ADMIN);
	const [totalEmployee, setTotalEmployee] = useState({ totalNoOfEmployee: 0 });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	//prettier-ignore
	const formik = useFormik({
        initialValues: { logo: "", purchaser: "", files: [], employees: [], privacyPolicies: false },
		validationSchema: yup.object({
			purchaser: yup.object({ bankAccountNo: yup.string().required(ERRORS.REQUIRED) }).required(ERRORS.REQUIRED),
			employees: yup.array().test("validate employee length", ERRORS.REQUIRED, function(values) {
				let total = totalEmployee.totalNoOfEmployee;

				const employeesLength = values?.length + 1;

				return total <= employeesLength;
			}).required(ERRORS.REQUIRED),
			files: yup.array().test("validate require files", ERRORS.REQUIRED, function(values) {
				const invalid = documentFields.some(o => {
					const isMandatory = o.mandatory === "Y";
					const found = values.findIndex(a => a.code === o.code) > -1;
					return !found && isMandatory;
				});

				return !invalid;
			}).required(ERRORS.REQUIRED),
			privacyPolicies: yup.bool().oneOf([true], ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const employeesCardClassName = useMemo(() => {
		return classNames({ setup__card: true, "setup__card--accordion": true, "setup__card--error": formik.errors.employees && formik.touched.employees, "setup__card--active": accordion === ACCORDIONS.EMPLOYEES });
	}, [formik, accordion, ACCORDIONS]);
	const purchaserrCardClassName = useMemo(() => {
		return classNames({ setup__card: true, "setup__card--accordion": true, "setup__card--error": formik.errors.purchaser && formik.touched.purchaser, "setup__card--active": accordion === ACCORDIONS.ADMIN });
	}, [formik, accordion, ACCORDIONS]);
	const documentsCardClassName = useMemo(() => {
		return classNames({ setup__card: true, "setup__card--accordion": true, "setup__card--error": formik.errors.files && formik.touched.files, "setup__card--active": accordion === ACCORDIONS.DOCUMENTS });
	}, [formik, accordion, ACCORDIONS]);

	const memoSetFieldValues = useMemo(() => formik.setFieldValue, [formik]);

	const totalRemainingEmployeeSlots = useMemo(() => totalEmployee.totalNoOfEmployee - 1 - formik.values.employees.length, [formik, totalEmployee.totalNoOfEmployee]);

	const totalRemainingDocumentSlots = useMemo(() => {
		let total = 0;

		documentFields.forEach((o) => {
			if (o.mandatory === "Y") total += 1;
		});

		total -= formik.values.files.length;

		return total < 0 ? 0 : total;
	}, [documentFields, formik]);

	const onHandleSubmit = (values) => {
		const insurer = [...values.employees, values.purchaser];

		const insufficientInsurer = insurer.filter((o) => {
			const inRange = getDobAgeInRange(o.dateOfBirth, ageRef.current);
			return inRange;
		});

		if (insufficientInsurer.length < totalEmployee.totalNoOfEmployee) {
			formik.setSubmitting(false);

			insufficientInsurerRef.current.onHandleShow();
		} else {
			navigate(pathnames.setup.setupCoverages);
		}
	};

	const onHandleAccordion = (event) => {
		const type = event.currentTarget.getAttribute("data-type");
		setAccordion(type);
	};

	const onHandlePrivacyPolcies = (event) => {
		const name = event.target.name;
		const value = event.target.value;

		formik.setFieldValue(name, !value);
	};

	const onHandleInviteEmployee = useCallback((data) => {
		inviteEmployeeRef.current.onHandleShow(data);
	}, []);

	//prettier-ignore
	const onHandleGetEmployees = useCallback(async (isInitialize) => {
		let response = null;
		let ageResponse = null;
		let documentsReponse = null;
		let purchaserResponse = null;
		let totalEmployeeResponse = null;
		let uploadedDocumentResponse = null;

		try {
			response = await api.get.setup.employees();
			ageResponse = await api.get.setup.ageRequirement();
			documentsReponse = await api.get.setup.documents();
			purchaserResponse = await api.get.setup.purchaser();
			totalEmployeeResponse = await api.get.setup.totalEmployee();
			uploadedDocumentResponse = await api.get.setup.uploadedDocument();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) memoSetFieldValues("employees", response);

		if (documentsReponse) setDocumentFields(documentsReponse);

		if (totalEmployeeResponse) setTotalEmployee(totalEmployeeResponse);

		if (purchaserResponse) memoSetFieldValues("purchaser", { ...purchaserResponse, purchaser: true });

		if (uploadedDocumentResponse) memoSetFieldValues("files", uploadedDocumentResponse);

		if (isInitialize && purchaserResponse && !purchaserResponse?.bankAccountNo) onHandleInviteEmployee({ ...purchaserResponse, purchaser: true });

		if(ageResponse) ageRef.current = { min: ageResponse.minEntryAge,  max: ageResponse.maxEntryAge };
	}, [memoSetFieldValues, onHandleInviteEmployee]);

	//prettier-ignore
	const onHandleUploadCompanyLogo = useCallback(async (file) => {
		let response = null;

		try {
			const formData = new FormData();
			formData.append("file", file);
			response = await api.post.general.uploadCompanyLogo(formData);
		}
		catch(error) {
			serveRequestErrors(error);
		}

		if(response) dispatch(setLogo(response.url));
	}, [dispatch]);

	//prettier-ignore
	const onHandleCompanyLogoChange = useCallback(async (event) => {
		const file = event.target.files[0];
		const validImageDimension = await validateImageDimension({ width: 160, height: 50 }, file);
		const validFileSize = validateFileSize(file);

		if (!validFileSize || !validImageDimension) return serveRequestErrors(ERRORS.PROFILE_IMAGE_SIZE);

		onHandleUploadCompanyLogo(file);
	}, [onHandleUploadCompanyLogo]);

	//prettier-ignore
	const onHandleSelectCompanyLogo = useCallback(() => {
		companyLogoInputRef.current.click();
	}, []);

	//prettier-ignore
	const onHandleDeleteEmployee = useCallback(async (obj) => {
		let response = null;

		try {
			const payload = { email: obj.email };
			await api.post.setup.delete(payload);
			response = true;
		}
		catch(error) {
			serveRequestErrors(error);
		}

		if(response) {
			const employees = [...formik.values.employees].filter(o => o.email !== obj.email);
			formik.setFieldValue("employees", employees);
		}
	}, [formik]);

	const onHandleUploadFile = useCallback((event) => {
		const type = event.currentTarget.getAttribute("data-type");
		uploadInputRef.current[type].click();
	}, []);

	//prettier-ignore
	const onHandleRemoveFile = useCallback((event, currentFiles) => {
		event.stopPropagation();
		const type = event.currentTarget.getAttribute("data-type");
		const nextFiles = currentFiles.filter((o) => o.code !== type);
		formik.setFieldValue("files", nextFiles);
	}, [formik]);

	//prettier-ignore
	const onHandleChange = useCallback(async (event) => {
        const file = event.target.files[0];
        const name = event.target.name;
		const files = [...formik.values.files];

		let response = null;

		const isValidFileSize = validateFileSize(file, COMMON.MAX_FILE_SIZES.MB_10);

		if(!isValidFileSize) return serveRequestErrors(ERRORS.DOCUMENTS_SIZE_10MB);

		const onUploadProgress = (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			setUploadProgress((prev) => ({ ...prev, [name]: percentCompleted }));
		};

		try {
			const formData = new FormData();

			formData.append("file", file);
			formData.append("code", name);

			response = await api.post.setup.upload(formData, onUploadProgress);
		}
		catch(error) {
			serveRequestErrors(error);
		}
		finally {
			uploadInputRef.current[name].value = "";
			setUploadProgress((prev) => ({ ...prev, [name]: undefined }));
		}

		if(response) {
			const nextFiles = files.filter(o => o.code !== name);
			formik.setFieldValue("files", [...nextFiles, response]);
		}
    }, [formik]);

	const PrivacyPoliciesLabel = useCallback(() => {
		return (
			<Fragment>
				I consent to the Data Protection and{" "}
				<a href="https://www.easycover.my/privacy-policy-2/" target="_blank" rel="noopener noreferrer">
					Privacy Policy.
				</a>
			</Fragment>
		);
	}, []);

	const UploadedDocumentItem = useCallback((args) => {
		const fileName = args.item?.fileName;
		const downloadFile = [{ url: args?.item?.link, fileName: fileName }];

		const onSelectRemoveFile = (event) => {
			if (args.disabled) return;
			args.onHandleRemoveFile(event, args.files);
		};

		return (
			<Fragment>
				{!fileName && (
					<button type="button" className="company-card__upload-button" data-type={args.code} disabled={args.disabled || args.progressStatus} onClick={args.onHandleUploadFile}>
						{!fileName && <p className="company-card__upload">{args.progressStatus ? "Uploading..." : "upload"}</p>}
						{!args.progressStatus && !fileName && <img className="company-card__upload-icon" src={uploadIcon} alt="upload" />}
					</button>
				)}

				{fileName && (
					<button type="button" className="company-card__download-uploaded-button" onClick={() => downloadFiles(downloadFile)}>
						{fileName && <p className="company-card__file-name">{fileName}</p>}
						<img className="company-card__upload-icon" src={removeIcon} alt="remove file" data-type={args.code} onClick={onSelectRemoveFile} />
					</button>
				)}
			</Fragment>
		);
	}, []);

	//prettier-ignore
	const Accordions = useCallback((obj) => {
		const isEmployees = obj.accordion === obj.accordions.EMPLOYEES && obj.id === obj.accordions.EMPLOYEES;
		const isDocuments = obj.accordion === obj.accordions.DOCUMENTS && obj.id === obj.accordions.DOCUMENTS;

		if (isEmployees) {
			return (
				<Fragment>
					<div className="company-card__body">
						<div className="company-card__list-header">
							<p className="company-card__label">Employee Name</p>
							<p className="company-card__label">Email Address</p>
							<p className="company-card__label"></p>
						</div>

						{obj.employees?.map((o) => {
							return (
								<div className="company-card__employees" key={o.email}>
									<p className="company-card__text">{o.fullName}</p>
									<p className="company-card__text">{o.email}</p>
									<div className="company-card__actions">
										<button type="button" className="company-card__edit-employee" onClick={() => obj.onHandleInviteEmployee(o)}>
											<img src={editIcon} alt="edit employee" />
										</button>
										<button type="button" className="company-card__remove-employee" onClick={() => obj.onHandleDeleteEmployee(o)}>
											<img src={removeIcon} alt="remove employee" />
										</button>
									</div>
								</div>
							);
						})}
					</div>

					<button className="company-card__add-employee-button" type="button" onClick={() => obj.onHandleInviteEmployee()}>
						Add Employee <AddIcon />
					</button>
				</Fragment>
			);
		}

		if (isDocuments) {
			return (
				<div className="company-card__body">
					<p className="setup__text">Before you upload the documents, we want to ensure that you have all the necessary information ready. Please download the following documents first:</p>

					<div className="company-card__list-header">
						<p className="company-card__label">File Name</p>
						<p className="company-card__label">Upload</p>
						<p className="company-card__label"></p>
					</div>

					{obj.documentFields.map((o) => {
						uploadInputRef.current[o.code] = {};
						const item = obj.files.find((b) => b.code === o.code);
						const progressStatus = obj.uploadProgress[o.code];
						const isMandatory = o.mandatory === "Y";
						const fileName = getfileName(o.link);
						const downloadFile = [{ url: o.link, fileName: fileName }];
						const disabled = Object.values(obj.uploadProgress).some(k => k);

						return (
							<div className="company-card__input" key={o.code}>
								<p className="company-card__text">{o.docName}{isMandatory && <span className="company-card__required">*</span>}</p>

								<UploadedDocumentItem item={item} disabled={disabled} progressStatus={progressStatus} files={obj.files} code={o.code} onHandleUploadFile={obj.onHandleUploadFile} onHandleRemoveFile={obj.onHandleRemoveFile} />

								<p className="company-card__text company-card__download">
									{o.link && (
										<button type="button" className="company-card__download-template-button" onClick={() => downloadFiles(downloadFile)}>
											Download Template
											<img className="company-card__download-icon" src={uploadIcon} alt="upload" />
										</button>
									)}
								</p>

								<input type="file" name={o.code} accept="image/jpeg, image/jpg, image/png, .pdf, .doc, .docx" hidden ref={(ref) => (uploadInputRef.current[o.code] = ref)} onChange={obj.onHandleChange} />

								<div className="company-card__progress-bar" style={{ width: `${progressStatus || 0}%` }} />
							</div>
						);
					})}
				</div>
			);
		}

		return null;
	}, []);

	useEffect(() => {
		onHandleGetEmployees(true);
	}, [onHandleGetEmployees]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.EMPLOYEES);
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.PURCHASER);
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.DOCUMENTS);
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.TOTAL_EMPLOYEE);
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.UPLOADED_DOCUMENT);
		};
	}, [cancelRequest]);

	return (
		<div className="page-setup">
			<div className="setup">
				<form onSubmit={formik.handleSubmit}>
					<div className="setup__header">
						<h1 className="setup__headline">Welcome to HR Portal</h1>
						<p className="setup__note">*Please complete all required sections within 60 days to activate policy coverage.</p>
					</div>

					<div className="setup__body">
						<div className="setup__card">
							<div className="company-card">
								<div className="company-card__company-logo">
									{profile.logo && <img className="company-card__logo" src={profile.logo} alt="company logo" />}
									{!profile.logo && <p className="company-card__logo-placeholder">company logo</p>}
								</div>

								<div className="company-card__company-content">
									<p className="setup__title">Upload Company Logo (Optional)</p>
									<p className="setup__description">Your logo will be displayed on the top left of HR Portal.</p>
									<p className="setup__text">Please ensure that your file is in the correct format (jpg, pdf) with each file size not exceeding 2MB and image size not more than 160 pixels by 50 pixels.</p>
								</div>

								<div className="company-card__company-button">
									<AppButton type="button" label="Upload" outline icon={uploadIcon} onClick={onHandleSelectCompanyLogo} />
									<input type="file" name="file" accept="image/jpeg, image/jpg, image/png" hidden ref={companyLogoInputRef} onChange={onHandleCompanyLogoChange} />
								</div>
							</div>
						</div>

						<div className={purchaserrCardClassName} data-type={ACCORDIONS.EMPLOYEES} onClick={onHandleAccordion}>
							<div className="company-card">
								<div className="company-card__icon">
									<AppUserManagementIcon color="#0245A9" />
								</div>

								<div className="company-card__company-content">
									<p className="setup__title">Secure Coverage for Policy Owner</p>
									<p className="setup__description">Please complete your details and choose insurance plan for coverage</p>
								</div>
							</div>

							<div className="company-card__body">
								<div className="company-card__list-header">
									<p className="company-card__label">Employee Name</p>
									<p className="company-card__label">Email Address</p>
									<p className="company-card__label"></p>
								</div>

								<div className="company-card__employees" key={formik.values.purchaser?.email}>
									<p className="company-card__text">{formik.values.purchaser?.fullName}</p>
									<p className="company-card__text">{formik.values.purchaser?.email}</p>
									<div className="company-card__actions">
										<button type="button" className="company-card__edit-employee" onClick={() => onHandleInviteEmployee(formik.values.purchaser)}>
											<img src={editIcon} alt="edit employee" />
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className={employeesCardClassName} data-type={ACCORDIONS.EMPLOYEES} onClick={onHandleAccordion}>
							<div className="company-card">
								<div className="company-card__icon">
									<AppUserManagementIcon color="#0245A9" />
								</div>

								<div className="company-card__company-content">
									<p className="setup__title">
										Employee Lists<span className="setup__required">*</span>
									</p>
									<p className="setup__description">Please make sure to fill in all employees eligible for coverage initially. You can always add more employees later through the User Management section.</p>
								</div>

								<div className="company-card__total-remaining">
									<p className="setup__total">{totalRemainingEmployeeSlots}</p>
									<p className="setup__text">Left</p>
									<div className="company-card__arrow">
										<AppChevronIcon />
									</div>
								</div>
							</div>

							{/* prettier-ignore */}
							<Accordions accordions={ACCORDIONS} id={ACCORDIONS.EMPLOYEES} accordion={accordion} employees={formik.values.employees} onHandleInviteEmployee={onHandleInviteEmployee} onHandleDeleteEmployee={onHandleDeleteEmployee} />
						</div>

						<div className={documentsCardClassName} data-type={ACCORDIONS.DOCUMENTS} onClick={onHandleAccordion}>
							<div className="company-card">
								<div className="company-card__icon">
									<AppFileIcon />
								</div>

								<div className="company-card__company-content">
									<p className="setup__title">
										Insurance Documents<span className="setup__required">*</span>
									</p>
									<p className="setup__description">Upload the various mandatory insurance documents</p>
								</div>

								<div className="company-card__total-remaining">
									<p className="setup__total">{totalRemainingDocumentSlots}</p>
									<p className="setup__text">Left</p>
									<div className="company-card__arrow">
										<AppChevronIcon />
									</div>
								</div>
							</div>

							{/* prettier-ignore */}
							<Accordions accordions={ACCORDIONS} id={ACCORDIONS.DOCUMENTS} accordion={accordion} documentFields={documentFields} files={formik.values.files} uploadProgress={uploadProgress} onHandleUploadFile={onHandleUploadFile} onHandleChange={onHandleChange} onHandleRemoveFile={onHandleRemoveFile} />
						</div>

						<div className="setup__privacy-policies">
							{/* prettier-ignore */}
							<AppCheckbox onClick={onHandlePrivacyPolcies} label={<PrivacyPoliciesLabel />} name="privacyPolicies" value={formik.values.privacyPolicies} error={formik.errors.privacyPolicies} touched={formik.touched.privacyPolicies} disabled={formik.isSubmitting} />
						</div>

						<div className="setup__disclaimer">
							<p className="setup__disclaimer-title">
								{/* prettier-ignore */}
								<img src={exclamationIcon} alt="disclaimer" />
								Disclaimer for policy inception
							</p>
							<ul className="setup__disclaimer-list">
								<li className="setup__disclaimer-item">• Backdating of policy inception is not allowed.</li>
								<li className="setup__disclaimer-item">• User are require to submit the necessary documents within 14 days.</li>
								<li className="setup__disclaimer-item">• Else, will result in their coverage to be void and any claims from this members will be rejected.</li>
							</ul>
						</div>

						<div className="setup__button-container">
							<AppButton type="submit" label="Next" disabled={formik.isSubmitting} />
						</div>
					</div>
				</form>
			</div>

			<AppInsufficientInsurerModal ref={insufficientInsurerRef} onHandleSetSubmiting={formik.setSubmitting} />

			<AppInviteEmployeeModal ref={inviteEmployeeRef} type={COMMON.EMPLOYEE.SETUP.SETUP} onHandleGetList={onHandleGetEmployees} employees={[...formik.values.employees, formik.values.purchaser]} />
		</div>
	);
};

export default PageSetup;
