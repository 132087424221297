import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";

export const AppInternshipCompletedModal = (props, ref) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [user, setUser] = useState();
	const [status, setStatus] = useState(COMMON.STATUS_ID.APPROVED);
	const [visible, setVisible] = useState(false);
	const [isSubmitting, setSubmitting] = useState();
	//prettier-ignore
	const radioClassName = useCallback((type) => {
		return classNames({
			"internship-completed-modal__radio": true,
			"internship-completed-modal__radio--active": type === status
		});
	}, [status]);

	const onHandleSetStatus = (event) => {
		const type = event.currentTarget.getAttribute("data-type");
		setStatus(type);
	};

	const onHandleShow = useCallback((values) => {
		if (values) setUser(values);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		if (props.onHandleCancelSubmit) props.onHandleCancelSubmit();
		setVisible(false);
	}, [props]);

	const onHandleConfirm = useCallback(async () => {
		if (status === COMMON.STATUS_ID.REJECTED) {
			setSubmitting(true);

			try {
				await api.post.employee.rejectIntern({ email: user.email });

				dispatch(promptAlertMessage({ message: "Status has been updated successfully" }));

				onHandleDismiss();

				if (props.onHandleGetList) props.onHandleGetList();
			} catch (error) {
				serveRequestErrors(error);
			} finally {
				setSubmitting(false);
			}
		} else {
			navigate(pathnames.userManagement.employee + user.email + `?tab=Employee+Info&internship=${COMMON.STATUS_ID.COMPLETED}`);
		}
	}, [navigate, user, props, status, onHandleDismiss, dispatch]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-internship-completed-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="internship-completed-modal" aria-describedby="internship-completed-modal">
			<div className="internship-completed-modal">
				<div className="internship-completed-modal__main">
					<div className="internship-completed-modal__header">
						<p className="internship-completed-modal__title">Internship Completion</p>
					</div>

					<div className="internship-completed-modal__body">
						<p className="internship-completed-modal__description">This employee has successfully concluded the internship period. Would you like to confirm them as a permanent staff member?</p>

						<div className="internship-completed-modal__options">
							<div className={radioClassName(COMMON.STATUS_ID.REJECTED)} data-type={COMMON.STATUS_ID.REJECTED} onClick={onHandleSetStatus}>
								<div className="internship-completed-modal__dot" />
								<p className="internship-completed-modal__label">Reject</p>
							</div>
							<div className={radioClassName(COMMON.STATUS_ID.APPROVED)} data-type={COMMON.STATUS_ID.APPROVED} onClick={onHandleSetStatus}>
								<div className="internship-completed-modal__dot" />
								<p className="internship-completed-modal__label">Approve</p>
							</div>
						</div>

						<div className="internship-completed-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="button" label="Confirm" onClick={onHandleConfirm} disabled={isSubmitting} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInternshipCompletedModal));

AppInternshipCompletedModal.propTypes = {
	ref: PropTypes.object,
};
