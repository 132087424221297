import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import { promptAlertMessage } from "store/slices/alert";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import getGeneralLeaveListing from "services/get-general-leave-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppLeaveModal from "components/pages/leave-management/app-leave-modal";
import AppTable, { AppTableEditIcon, AppTableCell } from "components/app-table";
import AppRejectReasonModal from "components/pages/leave-management/app-reject-reason-modal";
import rejectIcon from "assets/images/components/pages/leave-management/reject-icon.svg";
import checkedIcon from "assets/images/components/pages/leave-management/checked-icon.svg";
import viewIcon from "assets/images/view-icon.svg";
import exclamationIcon from "assets/images/exclamation-icon.svg";

const AppLeavesApproval = (props) => {
	const dispatch = useDispatch();
	const tableRef = useRef();
	const leaveModalRef = useRef();
	const searchInputRef = useRef();
	const rejectReasonRef = useRef();
	const [selectedItem, setSelectedItem] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
		name: searchParams.get("name") || "",
		leaveType: searchParams.get("leaveType") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const initialValues = useMemo(() => ({ name: "", leaveType: "" }), []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const onHandleTableMenu = useCallback((event, original) => {
		const types = {};

		original.companyLeaveApplicationDetails.forEach((o) => {
			if (types[o.leaveType]) {
				types[o.leaveType] = { ...types[o.leaveType], noOfDays: types[o.leaveType].noOfDays + 1 };
			} else {
				types[o.leaveType] = {};
				types[o.leaveType] = { ...o, leaveTypeLabel: o.leaveType === COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE ? "Carry Forward" : original.leaveType };
			}

			return types;
		});

		setSelectedItem(types);
		setAnchorEl(event.currentTarget);
	}, []);

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
	};

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { leaveType: capitalizeCharacter(params.leaveType?.split("_")?.join(" ")), name: params.name };

		return values;
	}, [searchParams]);

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.leaves.pendingApprovalList(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	const onHandleRejectLeave = useCallback(() => {
		const { selectedFlatRows } = tableRef.current.onHandleGetSelectedRows();
		rejectReasonRef.current.onHandleShow(selectedFlatRows.map((o) => o.original.id));
	}, []);

	const onHandleApproveLeave = useCallback(async () => {
		let response = null;
		const { selectedFlatRows } = tableRef.current.onHandleGetSelectedRows();
		try {
			const payload = {
				ids: selectedFlatRows.map((o) => o.original.id),
			};
			await api.post.leaves.approve(payload);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "Leave has been approved successfully" }));
			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	const StartDateCell = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.companyLeaveApplicationDetails?.[0]?.date);
		return <AppTableCell left value={date} />;
	}, []);

	const EndDateCell = useCallback(({ row }) => {
		const index = row.original.companyLeaveApplicationDetails?.length - 1;
		const date = formatDatePattern(row.original.companyLeaveApplicationDetails?.[index]?.date);
		return <AppTableCell left value={date} />;
	}, []);

	const CreateDateCell = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.createdDate);
		return <AppTableCell left value={date} />;
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		const isMixedCarryForward = row.original.companyLeaveApplicationDetails.findIndex(o => o.leaveType === COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE) > -1;
		if(isMixedCarryForward) return <AppTableEditIcon className="table__exclamation" icon={exclamationIcon} onClick={(event) => onHandleTableMenu(event, row.original)} />;
		else return null;
	}, [onHandleTableMenu]);

	const onHandleViews = useCallback((id) => {
		leaveModalRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const MenuViewCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={viewIcon} onClick={() => onHandleViews(row.original.id)} />;
	}, [onHandleViews]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Username",
			accessor: "employeeDetail.personalInfo.name",
			disableSortBy: true,
		},
		{
			Header: "Days",
			accessor: "noOfDays",
			disableSortBy: true,
		},
		{
			Header: "Leave Type",
			accessor: "leaveType",
			disableSortBy: true,
		},
		{
			Header: "Submit Date",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: CreateDateCell,
		},
		{
			Header: "Start Date",
			id: "startDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
			Cell: StartDateCell,
		},
		{
			Header: "End Date",
			id: "endDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
			Cell: EndDateCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
		{
			Header: "",
			accessor: "**",
			disableSortBy: true,
			Cell: MenuViewCell,
		},
	], [MenuCell, MenuViewCell, CreateDateCell, StartDateCell, EndDateCell]);

	//prettier-ignore
	const checkerButtons = useMemo(() => [
		{ label: "Reject Leave", outline: true, icon: rejectIcon, onClick: onHandleRejectLeave },
		{ label: "Approve Leave", icon: checkedIcon, onClick: onHandleApproveLeave },
	], [onHandleRejectLeave, onHandleApproveLeave]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.LEAVES.PENDING_APPROVAL_LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-leaves-approval">
			<div className="leaves-approval">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter employee name" value={formik.values.name} onChange={formik.handleChange} />

										<AppSelectInput name="leaveType" label="Leave Type" placeholder="Please select" loadOptions={getGeneralLeaveListing} value={formik.values.leaveType} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>

						{(paramsRef.current.leaveType || paramsRef.current.name) && (
							<div className="leaves-approval__results">
								<p className="leaves-approval__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable ref={tableRef} checker checkerButtons={checkerButtons} columns={columns} pages={data} onHandlePagination={onHandlePagination} />
					</Fragment>
				)}
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<p className="app-table-menu__title">Leave Deduction:</p>

				{Object.keys(selectedItem).map((o, i) => {
					const item = selectedItem[o];
					return (
						<div className="app-table-menu__item" key={i}>
							<p className="app-table-menu__text">• {item.leaveTypeLabel} Leave</p>
							<p className="app-table-menu__title">{item.noOfDays}</p>
						</div>
					);
				})}
			</Menu>

			<AppLeaveModal ref={leaveModalRef} />
			<AppRejectReasonModal ref={rejectReasonRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default AppLeavesApproval;
