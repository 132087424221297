import React, { useMemo, useContext, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import getBenefitListing from "services/get-benefit-listing";
import getPositionListing from "services/get-position-listing";
import getJobLevelListing from "services/get-job-level-listing";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { CALENDAR_FORMAT, isDateBeforeToday } from "common/calendar";
import getContractTypeListing from "services/get-contract-type-listing";
import getReportingManagerListing from "services/get-reporting-manager-listing";
import getEmploymentEndStatusListing from "services/get-employment-end-status-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppCalendarInput from "components/app-calendar-input";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const AppEmployeeInfoTab = ({ route }) => {
	const [searchParams] = useSearchParams();
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const defaultOptions = useRef({ benefit: undefined, position: undefined, reportingManager: undefined });
	const context = useContext(AxiosContext);
	const internshipCompleted = useMemo(() => searchParams.get("internship") === COMMON.STATUS_ID.COMPLETED, [searchParams]);
	//prettier-ignore
	const initialValues = useMemo(() => ({ employeeId: "", workEmail: "", position: "", level: "", contractType: "", dateJoined: "", probationEndDate: "", lastPromotionDate: "", lastEmploymentDate: "", employmentEndStatus: "", internshipEndDate:"", reportingManager: "", benefit: "", currentEmploymentEndStatus: "" }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			employeeId: yup.string().required(ERRORS.REQUIRED),
			workEmail: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
			position: yup.string().required(ERRORS.REQUIRED),
			level: yup.string().required(ERRORS.REQUIRED),
			contractType: yup.string().required(ERRORS.REQUIRED),
			dateJoined: yup.string().required(ERRORS.REQUIRED),
			benefit: yup.string().required(ERRORS.REQUIRED),
			probationEndDate: yup.string().when(["contractType"], {
				is: (contractType) => contractType !== COMMON.STATUS_ID.INTERN,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			employmentEndStatus: yup.string().when(["lastEmploymentDate"], {
				is: (date) => !!date && isDateBeforeToday(date, new Date()),
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			internshipEndDate: yup.string().when(["contractType"], {
				is: (contractType) => contractType === COMMON.STATUS_ID.INTERN,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const lastEmploymentDate = useMemo(() => formik.values.lastEmploymentDate && isDateBeforeToday(formik.values.lastEmploymentDate, new Date()), [formik.values.lastEmploymentDate]);
	const intershipContractType = useMemo(() => formik.values.contractType === COMMON.STATUS_ID.INTERN, [formik.values.contractType]);
	const disabled = useMemo(() => {
		switch (formik.values.currentEmploymentEndStatus) {
			case COMMON.STATUS_ID.TERMINATION:
			case COMMON.STATUS_ID.DISMISSAL:
			case COMMON.STATUS_ID.RESIGNED:
				return true;
			default:
				return formik.isSubmitting;
		}
	}, [formik]);
	const memoSetValues = useMemo(() => formik.setValues, [formik]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			let payload = {
				email: id,
				level: values.level,
				position: values.position,
				workEmail: values.workEmail,
				dateJoined: values.dateJoined,
				employeeId: values.employeeId,
				benefitPackageId: values.benefit,
				contractType: values.contractType,
				employmentStatus: values.employmentEndStatus,
				reportingManagerId: values.reportingManager,
				probationEndDate: "",
				lastPromotionDate: "",
				lastEmploymentDate: "",
				internshipEndDate: "",
			};

			if (intershipContractType) payload.internshipEndDate = values.internshipEndDate;

			if (values.lastEmploymentDate) {
				payload.employmentStatus = values.employmentEndStatus || values.currentEmploymentEndStatus;
			} else if (!values.lastEmploymentDate && !values.employmentEndStatus) {
				payload.employmentStatus = COMMON.STATUS_ID.PROBATION;
			}

			if (!intershipContractType) {
				payload.probationEndDate = values.probationEndDate;
				payload.lastPromotionDate = values.lastPromotionDate;
				payload.lastEmploymentDate = values.lastEmploymentDate;
			}

			await api.post.employee.updateEmployeeInfo(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			formik.setFieldValue("currentEmploymentEndStatus", values.employmentEndStatus);
			dispatch(promptAlertMessage({ message: "Employee has been update successfully" }));
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	//prettier-ignore
	const onHandleLastEmployementDate = useCallback((name, value) => {
		formik.setFieldValue(name, value);
		formik.setFieldValue("employmentEndStatus", "");
	}, [formik]);

	//prettier-ignore
	const onHandleContractType = useCallback((event) => {
		const value = event.target.value;
		const name = event.target.name;
		formik.setFieldValue(name, value);
		formik.setFieldValue("probationEndDate", "");
		formik.setFieldValue("lastEmploymentDate", "");
		
		if(value === COMMON.STATUS_ID.INTERN) {
			formik.setFieldValue("employmentEndStatus", COMMON.STATUS_ID.INTERN);
		} else {
			formik.setFieldValue("employmentEndStatus", COMMON.STATUS_ID.PROBATION);
		}
	}, [formik]);

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.employee.employeeInfo({ email: id });
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				const payload = {
					employeeId: response.employeeId || "",
					workEmail: response.workEmail || "",
					position: response.position || "",
					level: response.level || "",
					contractType: response.contractType || "",
					dateJoined: response.dateJoined || "",
					probationEndDate: response.probationEndDate || "",
					lastPromotionDate: response.lastPromotionDate || "",
					lastEmploymentDate: response.lastEmploymentDate || "",
					reportingManager: response.reportingManagerId || "",
					benefit: response.benefitPackageId || "",
					internshipEndDate: response.internshipEndDate || "",
					employmentEndStatus: response.employmentStatus || "",
					currentEmploymentEndStatus: response.employmentStatus || "",
				};

				if (internshipCompleted) {
					payload.internshipEndDate = "";
					payload.probationEndDate = response.internshipEndDate;
					payload.employmentEndStatus = COMMON.STATUS_ID.PROBATION;
					payload.contractType = COMMON.STATUS_ID.FULL_TIME_EMPLOYEE;
				}

				memoSetValues(payload);

				if (response.benefitPackageId) {
					defaultOptions.current.benefit = { label: response.benefitPackageName, value: response.benefitPackageId };
				}

				if (response.position) {
					defaultOptions.current.position = { label: response.position, value: response.position };
				}

				if (response.reportingManager) {
					defaultOptions.current.reportingManager = { label: response.reportingManagerName, value: response.reportingManagerId };
				}
			}
		};

		if (id) onHandleGetDetails();
	}, [id, internshipCompleted, memoSetValues]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.EMPLOYEE.EMPLOYEE_INFO);
		};
	}, [context]);

	return (
		<div className="app-employee-info-tab">
			<form className="employee-info" onSubmit={formik.handleSubmit}>
				<div className="employee-info__box">
					<div className="employee-info__form">
						{/* prettier-ignore */}
						<AppInput required type="text" name="employeeId" label="Employee ID" placeholder="Please Enter Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} disabled={disabled} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput required type="text" name="workEmail" label="Work Email" placeholder="Work Email" value={formik.values.workEmail} error={formik.errors.workEmail} touched={formik.touched.workEmail} disabled={disabled} onChange={formik.handleChange} />

						<div className="employee-info__wrapper">
							{/* prettier-ignore */}
							<AppSelectInput pagination required type="text" name="position" label="Position" placeholder="Please Select" defaultOptions={defaultOptions.current.position} loadOptions={getPositionListing} value={formik.values.position} error={formik.errors.position} touched={formik.touched.position} disabled={disabled} onChange={formik.handleChange} />
							<p className="employee-info__link">
								<Link to={pathnames.companyBenefits.positions}>Click here</Link> to add/edit a position
							</p>
						</div>

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="level" label="Level" placeholder="Please Select" loadOptions={getJobLevelListing} value={formik.values.level} error={formik.errors.level} touched={formik.touched.level} disabled={disabled} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="contractType" label="Contract Type" placeholder="Please Select" loadOptions={getContractTypeListing} value={formik.values.contractType} error={formik.errors.contractType} touched={formik.touched.contractType} disabled={disabled} onChange={onHandleContractType} />

						{/* prettier-ignore */}
						<AppCalendarInput required name="dateJoined" label="Join Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.dateJoined} error={formik.errors.dateJoined} touched={formik.touched.dateJoined} disabled={disabled} onChange={formik.setFieldValue} />

						{
							/* prettier-ignore */ !intershipContractType &&<AppCalendarInput required name="probationEndDate" label="Probation End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.probationEndDate} error={formik.errors.probationEndDate} touched={formik.touched.probationEndDate} disabled={disabled} onChange={formik.setFieldValue} />
						}

						{
							/* prettier-ignore */ !intershipContractType && <AppCalendarInput name="lastPromotionDate" label="Last Promotion Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.lastPromotionDate} error={formik.errors.lastPromotionDate} touched={formik.touched.lastPromotionDate} disabled={disabled} onChange={formik.setFieldValue} />
						}

						{
							/* prettier-ignore */ !intershipContractType && <AppCalendarInput name="lastEmploymentDate" label="Last Employment Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.lastEmploymentDate} error={formik.errors.lastEmploymentDate} touched={formik.touched.lastEmploymentDate} disabled={disabled} onChange={onHandleLastEmployementDate} />
						}

						{
							/* prettier-ignore */ intershipContractType && <AppCalendarInput required name="internshipEndDate" label="Internship End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.internshipEndDate} error={formik.errors.internshipEndDate} touched={formik.touched.internshipEndDate} disabled={disabled} onChange={formik.setFieldValue} />
						}

						{
							/* prettier-ignore */ lastEmploymentDate && !intershipContractType && <AppSelectInput required type="text" name="employmentEndStatus" label="Employment End Status" placeholder="Please Select" loadOptions={getEmploymentEndStatusListing} value={formik.values.employmentEndStatus} error={formik.errors.employmentEndStatus} touched={formik.touched.employmentEndStatus} disabled={formik.isSubmitting} onChange={formik.handleChange} />
						}

						{/* prettier-ignore */}
						<AppSelectInput pagination type="text" name="reportingManager" label="Reporting Manager" placeholder="Please Select" defaultOptions={defaultOptions.current.reportingManager} loadOptions={getReportingManagerListing} value={formik.values.reportingManager} error={formik.errors.reportingManager} touched={formik.touched.reportingManager} disabled={disabled} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="employee-info__box">
					<div className="employee-info__header">
						<p className="employee-info__title">Benefits Enrollment</p>
					</div>

					<div className="employee-info__form">
						{/* prettier-ignore */}
						<AppSelectInput pagination required type="text" name="benefit" label="Benefit Plan" placeholder="Please Select" defaultOptions={defaultOptions.current.benefit} loadOptions={getBenefitListing} value={formik.values.benefit} error={formik.errors.benefit} touched={formik.touched.benefit} disabled={disabled} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="employee-info__button-container">
					<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
					<AppButton type="submit" label="Save" disabled={formik.isSubmitting} />
				</div>
			</form>
		</div>
	);
};

export default AppEmployeeInfoTab;
