import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import converReadableFileSize from "common/convert-readable-file-size";
import AppButton from "components/app-button";
import removeIcon from "assets/images/remove-icon.svg";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";

const AppUploadAdditionalClaimDocumentsModal = (props, ref) => {
	const dispatch = useDispatch();
	const uploadInputRef = useRef();
	const [data, setData] = useState(null);
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [uploadProgress, setUploadProgress] = useState({});
	const [documents, setDocuments] = useState([]);
	const [visible, setVisible] = useState(false);
	const maxLength = useMemo(() => documents.length >= 10, [documents]);
	const disabled = useMemo(() => !documents.length || isSubmiting, [isSubmiting, documents]);

	const onHandleShow = useCallback((obj) => {
		setData(obj);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleRemoveDocument = useCallback((obj) => {
		const filtered = documents.filter((o) => o.name !== obj.name);
		setDocuments(filtered);
	}, [documents]);

	const onHandleUploadDocument = useCallback(() => {
		uploadInputRef.current.click();
	}, []);

	//prettier-ignore
	const onHandleUploadDocumentChange = useCallback((event) => {
        const file = event.target.files[0];

        const files = [...documents];

		const isValidFileSize = validateFileSize(file, COMMON.MAX_FILE_SIZES.MB_15);

		if (!isValidFileSize) return serveRequestErrors(ERRORS.DOCUMENTS_SIZE_15MB);

        uploadInputRef.current.value = "";

		const duplicated = files.findIndex((o) => o.name === file.name) > -1;

		if (!duplicated) files.push(file);

		setDocuments(files);
    }, [documents]);

	//prettier-ignore
	const onHandleUploadAdditionalClaimDocument = useCallback(async (index = 0) => {
        setIsSubmiting(true);

        let response = null;

        const file = documents[index];

        const onUploadProgress = (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            setUploadProgress((prev) => ({ ...prev, [file.name]: percentCompleted }));
        };

        try {
            const formData = new FormData();

            const params = data.id;

            formData.append("file", documents[index]);

            await api.post.coverages.uploadAdditionalClaimDocument(params, formData, onUploadProgress);

            response = true;
        } catch (error) {
            setIsSubmiting(false);
            serveRequestErrors(error);
        } finally {
            setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
        }

        const nextIndex = index + 1;

        if (response && documents[nextIndex]) {
            onHandleUploadAdditionalClaimDocument(nextIndex);
        } else {
            setIsSubmiting(false);

            dispatch(promptAlertMessage({ message: "Claim has been submit successfully" }));

            setDocuments([]);

            setVisible(false);

			props.onHandleGetList();
        }
    }, [dispatch, documents, data, props]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-upload-additional-claim-documents-modal" }} open={visible} aria-labelledby="upload-additional-claim-documents" aria-describedby="upload-additional-claim-documents-modal">
			<div className="upload-additional-claim-documents">
				<p className="upload-additional-claim-documents__title">Upload Document</p>
				<p className="upload-additional-claim-documents__description">Please ensure that your document is in the correct format (jpg, pdf) with file size not exceeding 15MB.</p>

				<div className="upload-additional-claim-documents__body">
					<div className="upload-additional-claim-documents__list-header">
						<p className="upload-additional-claim-documents__label">Uploaded</p>
						<p className="upload-additional-claim-documents__label">File Size</p>
						<p className="upload-additional-claim-documents__label"></p>
					</div>
					{documents.map((a, i) => {
						return (
							<div className="upload-additional-claim-documents__input" key={i}>
								<p className="upload-additional-claim-documents__text">{a.name}</p>
								<p className="upload-additional-claim-documents__text">{converReadableFileSize(a.size, true)}</p>
								<button className="upload-additional-claim-documents__delete" onClick={() => onHandleRemoveDocument(a)}>
									<img src={removeIcon} alt="remove" />
								</button>

								<div className="upload-additional-claim-documents__progress-bar" style={{ width: `${uploadProgress[a.name] || 0}%` }} />
							</div>
						);
					})}

					{!maxLength && (
						<div className="upload-additional-claim-documents__input">
							<button type="button" className="upload-additional-claim-documents__upload-button" onClick={onHandleUploadDocument}>
								<p className="upload-additional-claim-documents__upload">Uploaded</p>
								<UploadIcon />
							</button>
						</div>
					)}

					{/* prettier-ignore */}
					<input type="file" name="file" accept="image/jpeg, image/jpg, image/png, .pdf, .doc, .docx" hidden ref={uploadInputRef} onChange={onHandleUploadDocumentChange} />
				</div>

				<div className="upload-additional-claim-documents__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Confirm" onClick={() => onHandleUploadAdditionalClaimDocument()} disabled={disabled} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppUploadAdditionalClaimDocumentsModal));

AppUploadAdditionalClaimDocumentsModal.propTypes = {
	ref: PropTypes.object,
};
