import React, { memo, forwardRef, useImperativeHandle, useState, useMemo, useCallback, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { formatDatePattern } from "common/calendar";
import { getDobAgeInRange } from "common/nric-helpers";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import validateFileSize from "common/validate-file-size";
import converReadableFileSize from "common/convert-readable-file-size";
import AppButton from "components/app-button";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";

const STEPS = { DETAILS: "DETAILS", UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS" };

export const AppAmendmentRequestModal = (props, ref) => {
	const dispatch = useDispatch();
	const uploadInputRef = useRef();
	const [documents, setDocuments] = useState();
	const [visible, setVisible] = useState(false);
	const [step, setStep] = useState(STEPS.DETAILS);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [amendmentValues, setAmendmentValues] = useState({});
	const [ageRequirement, setAgeRequirement] = useState({ min: 0, max: 0 });

	const disabled = useMemo(() => (step === STEPS.UPLOAD_DOCUMENTS && !documents) || isSubmitting, [documents, step, isSubmitting]);

	const isEligibleAge = useMemo(() => {
		const isDetailsStep = step === STEPS.DETAILS;

		if (!amendmentValues?.after?.dateOfBirth || isDetailsStep) return true;

		const inRange = getDobAgeInRange(amendmentValues.after.dateOfBirth, ageRequirement);

		return inRange;
	}, [step, ageRequirement, amendmentValues]);

	const content = useMemo(() => {
		let obj = { title: "", description: "" };

		if (step === STEPS.DETAILS) {
			obj.title = "Amendment Request";
			obj.description = "The requested changes have been made as below. Once you confirm, we will promptly provide the updated information to the insurer.";
		}

		if (step === STEPS.UPLOAD_DOCUMENTS) {
			obj.title = "Upload Document";
			obj.description = "Please ensure that your document is in the correct format (jpg, pdf) with file size not exceeding 15MB.";
		}

		return obj;
	}, [step]);

	const onHandleShow = useCallback(async (obj) => {
		setStep(STEPS.DETAILS);

		let response = null;

		setAmendmentValues(obj);

		setVisible(true);

		try {
			response = await api.get.employee.ageRequirement();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setAgeRequirement({ min: response.minEntryAge, max: response.expiredAge });
	}, []);

	const onHandleDismiss = useCallback(() => {
		setDocuments();
		setVisible(false);
		setIsSubmitting(false);
	}, []);

	const onHandleUploadDocument = useCallback(() => {
		uploadInputRef.current.click();
	}, []);

	const onHandleUploadDocumentChange = useCallback((event) => {
		const file = event.target.files[0];

		const isValidFileSize = validateFileSize(file, COMMON.MAX_FILE_SIZES.MB_15);

		if (isValidFileSize) {
			setDocuments(file);
		} else {
			serveRequestErrors(ERRORS.DOCUMENTS_SIZE_15MB);
		}
	}, []);

	const onHandleConfirm = useCallback(async () => {
		if (step === STEPS.DETAILS) setStep(STEPS.UPLOAD_DOCUMENTS);

		if (step === STEPS.UPLOAD_DOCUMENTS) {
			let response = null;

			let uploadedResponse = null;

			setIsSubmitting(true);

			try {
				const payload = {
					email: amendmentValues.email,
					beforeValues: {
						name: amendmentValues.before.name || undefined,
						nric: amendmentValues.before.nric || undefined,
						passport: amendmentValues.before.passport || undefined,
						dateOfBirth: amendmentValues.before.dateOfBirth || undefined,
						nationality: amendmentValues.before.nationality || undefined,
						identificationType: amendmentValues.before.identificationType || undefined,
					},
					afterValues: {
						name: amendmentValues.after.name || undefined,
						nric: amendmentValues.after.nric || undefined,
						passport: amendmentValues.after.passport || undefined,
						dateOfBirth: amendmentValues.after.dateOfBirth || undefined,
						nationality: amendmentValues.after.nationality || undefined,
						identificationType: amendmentValues.after.identificationType || undefined,
					},
				};
				response = await api.post.amendmentRequest.submit(payload);
			} catch (error) {
				setIsSubmitting(false);
				serveRequestErrors(error);
			}

			if (response) {
				const onUploadProgress = (progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setUploadProgress(percentCompleted);
				};

				try {
					const params = response.id;

					const formData = new FormData();

					formData.append("file", documents);

					await api.post.amendmentRequest.upload(params, formData, onUploadProgress);

					uploadedResponse = true;
				} catch (error) {
					setIsSubmitting(false);
					serveRequestErrors(error);
				} finally {
					setUploadProgress(0);
				}

				if (uploadedResponse) {
					onHandleDismiss();
					dispatch(promptAlertMessage({ message: "Amendment request has been submitted" }));
				}
			}
		}
	}, [step, amendmentValues, documents, dispatch, onHandleDismiss]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	const AmendmentRequestTable = useCallback((obj) => {
		if (obj.step !== STEPS.DETAILS) return null;

		return (
			<div className="amendment-request-modal__table">
				<div className="amendment-request-modal__table-header">
					<div className="amendment-request-modal__table-header-cell">
						<p className="amendment-request-modal__table-title"></p>
					</div>
					<div className="amendment-request-modal__table-header-cell">
						<p className="amendment-request-modal__table-title">Old</p>
					</div>
					<div className="amendment-request-modal__table-header-cell">
						<p className="amendment-request-modal__table-title">New</p>
					</div>
				</div>

				{obj.amendmentValues?.before?.name && (
					<div className="amendment-request-modal__table-body">
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">Full Name</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">{obj.amendmentValues.before.name}</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-value">{obj.amendmentValues.after.name}</p>
						</div>
					</div>
				)}

				{obj.amendmentValues?.before?.identificationType && (
					<div className="amendment-request-modal__table-body">
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">Identification Type.</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">{obj.amendmentValues.before.identificationType}</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-value">{obj.amendmentValues.after.identificationType}</p>
						</div>
					</div>
				)}

				{(obj.amendmentValues?.before?.nric || obj.amendmentValues?.before?.passport) && (
					<div className="amendment-request-modal__table-body">
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">NRIC No./Passport No.</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">{obj.amendmentValues.before.nric || obj.amendmentValues.before.passport}</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-value">{obj.amendmentValues.after.nric || obj.amendmentValues.after.passport}</p>
						</div>
					</div>
				)}

				{obj.amendmentValues?.before?.nationality && (
					<div className="amendment-request-modal__table-body">
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">Nationality</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">{obj.amendmentValues.before.nationality}</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-value">{obj.amendmentValues.after.nationality}</p>
						</div>
					</div>
				)}

				{obj.amendmentValues?.before?.dateOfBirth && (
					<div className="amendment-request-modal__table-body">
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">Date of Birth</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-label">{formatDatePattern(obj.amendmentValues.before.dateOfBirth)}</p>
						</div>
						<div className="amendment-request-modal__table-body-cell">
							<p className="amendment-request-modal__table-value">{formatDatePattern(obj.amendmentValues.after.dateOfBirth)}</p>
						</div>
					</div>
				)}
			</div>
		);
	}, []);

	const DocumentsTable = useCallback((obj) => {
		if (obj.step !== STEPS.UPLOAD_DOCUMENTS) return null;

		return (
			<div className="amendment-request-modal__table">
				<div className="amendment-request-modal__table-header">
					<div className="amendment-request-modal__table-header-cell">
						<p className="amendment-request-modal__table-title">Uploaded</p>
					</div>
					<div className="amendment-request-modal__table-header-cell">
						<p className="amendment-request-modal__table-title"></p>
					</div>
					<div className="amendment-request-modal__table-header-cell">
						<p className="amendment-request-modal__table-file-size">File Size</p>
					</div>
				</div>

				<div className="amendment-request-modal__table-body">
					<div className="amendment-request-modal__table-body-cell">
						<p className="amendment-request-modal__table-label">
							NRIC/Passport<span className="amendment-request-modal__asterisk">*</span>
						</p>
					</div>
					<div className="amendment-request-modal__table-body-cell">
						{/*prettier-ignore */}
						<p className="amendment-request-modal__table-upload" onClick={obj.onHandleUploadDocument}>
							{obj.documents?.name ? (obj.documents.name) : (<Fragment>Upload File<UploadIcon /></Fragment>)}
						</p>
					</div>
					<div className="amendment-request-modal__table-body-cell">
						<p className="amendment-request-modal__table-file-size">{converReadableFileSize(obj.documents?.size, true)}</p>
					</div>
				</div>

				<div className="amendment-request-modal__progress-bar" style={{ width: `${obj.uploadProgress}%` }} />
				{/* prettier-ignore */}
				<input type="file" name="file" accept="image/jpeg, image/jpg, image/png, .pdf, .doc, .docx" hidden ref={uploadInputRef} onChange={obj.onHandleUploadDocumentChange} />
			</div>
		);
	}, []);

	return (
		<Modal classes={{ root: "app-amendment-request-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="amendment-request-modal" aria-describedby="amendment-request-modal">
			<div className="amendment-request-modal">
				<div className="amendment-request-modal__main">
					<div className="amendment-request-modal__header">
						<p className="amendment-request-modal__title">{content.title}</p>
						<p className="amendment-request-modal__description">{content.description}</p>

						{!isEligibleAge && <p className="amendment-request-modal__warnings">Due to age limitations, this user is not eligible for coverage and will be removed from the insurance plan</p>}
					</div>

					<AmendmentRequestTable amendmentValues={amendmentValues} step={step} />

					<DocumentsTable documents={documents} step={step} uploadProgress={uploadProgress} onHandleUploadDocument={onHandleUploadDocument} onHandleUploadDocumentChange={onHandleUploadDocumentChange} />

					<div className="amendment-request-modal__body">
						<div className="amendment-request-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="button" label="Confirm" onClick={onHandleConfirm} disabled={disabled} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAmendmentRequestModal));

AppAmendmentRequestModal.propTypes = {
	ref: PropTypes.object,
};
