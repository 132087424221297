import React, { memo } from "react";

const AppFileIcon = () => {
	return (
		<svg className="app-file-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.62001 3.49902C6.25001 3.49902 5.93001 3.61902 5.68001 3.86902C5.43001 4.11902 5.31001 4.49902 5.31001 4.80902L5.31001 19.189C5.31001 19.559 5.44001 19.879 5.68001 20.129C5.93001 20.379 6.31001 20.499 6.62001 20.499L17.37 20.499C17.74 20.499 18.06 20.379 18.31 20.129C18.56 19.879 18.68 19.499 18.68 19.189L18.68 9.55903C18.68 8.99903 18.43 8.36903 17.99 7.99903L14.18 4.18902C13.74 3.74902 13.18 3.55902 12.62 3.55902L6.62001 3.55902L6.62001 3.49902Z"
				stroke="#0245A9"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M9.28001 9.55957L14.76 9.55957" stroke="#0245A9" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.28001 12.8594L14.76 12.8594" stroke="#0245A9" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.28001 16.1602L14.76 16.1602" stroke="#0245A9" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppFileIcon);
