const COMMON = {
	REDUX_ACTION: {
		LOGOUT: "LOGOUT",
	},
	REDUX_PRESIST_KEY: "root_hr_web_6a46285d-e323-48ae-8a84-e1bc036f0223",
	AUTH_TOKEN: "easy_cover_hr_web_@99e53932-f4d8-46fc-9bba-757fd5e32783_auth_tk",
	REFRESH_TOKEN: "easy_cover_hr_web_@273f71f5-0d2d-45cd-b93b-fee2c84aea7b_refresh_tk",
	ENDPOINT_PATH: {
		GENERAL: {
			LOGO: "setting/logo",
			HOLIDAY: "general/holiday/",
			LIST: "general/lookup/detail",
			PROFILE: "user/profile/whoami",
			COMPANY: "user/profile/company/detail",
			COMPANIES: "user/profile/company/list",
			CITIES: "general/public/address/city/page",
			STATES: "general/public/address/state/page",
			POSTCODE: "general/public/address/postcode/",
			UPLOAD_COMPANY_LOGO: "setting/upload/logo",
			REFRESH_TOKEN: "authentication/public/token/refresh",
			MAINTENANCE: "service/config/config.json",
		},
		AUTHENTICATE: {
			SIGN_IN: "authentication/public/token",
			USER: "user/profile/company/",
		},
		DASHBOARD: {
			ADMIN: "dashboard/admin",
		},
		SETUP: {
			SUBMIT: "setting/setup/submit",
			COMPANIES: "setting/companies",
			DOCUMENTS: "setting/insurance-doc",
			PURCHASER: "setting/setup/purchaser",
			TOTAL_EMPLOYEE: "setting/setup/draft/",
			UPLOAD: "setting/insurance-doc/upload",
			INVITE: "setting/setup/invite/employee",
			AGE_REQUIREMENT: "setting/setup/age-req",
			EMPLOYEES: "setting/setup/draft/employee",
			DELETE: "setting/setup/draft/employee/delete",
			EMPLOYEE: "setting/setup/draft/employee/detail",
			UPLOADED_DOCUMENT: "setting/insurance-doc/uploaded",
			UPDATE_PURCHASER: "setting/setup/purchaser/update",
			COVERAGES: "setting/manage-coverage/page",
			UPDATE_COVERAGE: "setting/manage-coverage/update-coverage",
			INSURANCE_PLANS_LIST: "setting/manage-coverage/insurance-dropdown",
			UTILIZATION_INSURANCE: "setting/manage-coverage/insurance-utilization",
		},
		ANNOUNCEMENTS: {
			CREATE: "announcement/create",
			DELETE: "announcement/delete/",
			UPLOAD: "announcement/upload/",
			UPDATE: "announcement/update",
			LIST: "announcement/posted/page",
			UPCOMING: "announcement/upcoming/page",
		},
		BENEFITS: {
			BENEFIT: "benefit/company-benefit-package/details/",
			LIST: "benefit/company-benefit-package/page",
			CREATE: "benefit/company-benefit-package/create",
			UPDATE: "benefit/company-benefit-package/update",
		},
		LEAVES: {
			CALENDAR: "leave/calendar/",
			CANCEL: "leave/admin-leave/cancel/",
			REJECT: "leave/leave-approval/reject",
			APPROVE: "leave/leave-approval/approve",
			LIST: "leave/admin-leave/employee-leave/page",
			LEAVE: "leave/admin-leave/employee-leave/details/",
			SUMMARY: "leave/admin-leave/employee-leave/summary/",
			REFRESH: "leave/employee-leave-balance/refresh-balance/",
			UPCOMING: "leave/admin-leave/employee-leave/upcoming/page",
			PENDING_APPROVAL_LIST: "leave/admin-leave/my-approvals/page",
		},
		POSITIONS: {
			LIST: "setting/position/page",
			UPDATE: "setting/position/update",
			CREATE: "setting/position/create",
		},
		EMPLOYEE: {
			LIST: "employee/admin/search",
			ORGANISATION: "employee/user-company",
			CREATE: "employee/invite-employee/create",
			INSURANCE_PLANS_LIST: "employee/eb-order/items",
			DIRECTORY_LIST: "employee/admin/directory/page",
			AGE_REQUIREMENT: "employee/invite-employee/age-req",
			EMPLOYEE_INFO: "employee/admin/detail/employee-info",
			PERSONAL_INFO: "employee/admin/detail/personal-info",
			UPDATE_PERSONAL_INFO: "employee/admin/update/personal-info",
			UPDATE_EMPLOYEE_INFO: "employee/admin/update/employee-info",
			REJECT_INTERN: "employee/confirmation/intern/reject",
			APPROVE_PROBATION: "employee/confirmation/probation/approve",
			REJECT_PROBATION: "employee/confirmation/probation/reject",
			REPORTING_MANAGER_LIST: "employee/admin/reporting-manager/list",
			REINVITE_TERMINATED_USER: "employee/admin/resend-invite-terminated",
		},
		ADMIN: {
			LIST: "role/employee/admin/page",
			EMPLOYEE_LIST: "role/employee/staff/list",
			REMOVE_ADMIN: "role/management-role/remove/admin",
			ASSIGN_ADMIN: "role/management-role/assign/admin",
		},
		AMENDMENT_REQUEST: {
			SUBMIT: "employee/amendment/submit",
			UPLOAD: "employee/amendment/upload/",
			LIST: "employee/amendment/page",
			DETAILS: "employee/amendment/details/",
		},
		CLAIMS: {
			LIST: "claim/admin-claim/page/",
			REJECT: "claim/admin-claim/reject",
			CANCEL: "claim/admin-claim/cancel/",
			APPROVE: "claim/admin-claim/approve",
			CLAIM: "claim/admin-claim/details/",
			SUMMARY: "claim/admin-claim/summary/",
			PENDING_APPROVAL_LIST: "claim/admin-claim/my-approvals/page",
		},
		ORDERS: {
			LIST: "setting/order/page",
			ORDER: "setting/order/details/",
			DELETE_ADDITIONAL_DOCUMENT: "setting/insurance-doc/delete",
			ADDITIONAL_DOCUMENTS: "setting/insurance-doc/uploaded/additional",
			SUBMIT_ADDITIONAL_DOCUMENTS: "setting/insurance-doc/submit/additional",
		},
		POLICIES: {
			LIST: "policy/eb-policy/page",
			POLICY: "policy/eb-policy/details",
			DOCUMENTS: "policy/eb-policy/documents",
			DOWNLOAD_DOCUMENTS: "policy/eb-policy/file/download",
			INSURER: "policy/eb-policy/insured",
			DEBIT_NOTE: "policy/eb-invoice/debit/all",
			CREDIT_NOTE: "policy/eb-invoice/credit/all",
			UNPAID_DEBIT_NOTE: "policy/eb-invoice/debit/unpaid",
			UNCLAIMED_CREDIT_AMOUNT: "policy/eb-invoice/credit/unclaimed-amount",
			UNPAID_DEBIT_AMOUNT: "policy/eb-invoice/debit/unpaid-amount",
			REFUND_CREDIT: "policy/eb-invoice/credit/request-refund",
			DEBIT_NOTE_INVOICE_PAYMENT: "policy/eb-invoice-payment/pay",
			INVOICE_FILES: "policy/eb-invoice/files/",
			DOWNLOAD_INVOICES: "policy/eb-invoice/file/download",
			CREDIT_PAYMENT_HISTORY: "policy/payment-history/credit/",
			CREDIT_PAYMENT_HISTORY_RECEIPT: "policy/payment-history/credit/files/",
			DEBIT_PAYMENT_HISTORY: "policy/payment-history/debit/",
			DEBIT_PAYMENT_HISTORY_RECEIPT: "policy/payment-history/debit/files/",
			CREDIT_DOWNLOAD_RECEIPT: "policy/payment-history/credit/files/download",
			DEBIT_DOWNLOAD_RECEIPT: "policy/payment-history/debit/files/download",
			REJECT_POLICY_RENEWAL: "policy/eb-policy-renewal/reject",
			UPLOAD_POLICY_RENEWAL: "policy/eb-policy-renewal/upload-file/",
			RENEWAL_PAYMENT: "policy/eb-policy-renewal-payment/pay",
			QUOTATIONS: "policy/eb-policy-renewal/quotation/list/",
			DOWNLOAD_QUOTATIONS: "policy/eb-policy-renewal/quotation/download",
			REVISE_QUOTATION_EMPLOYEES: "policy/eb-policy-renewal/revise-quotation/employee/list",
			REVISE_QUOTATION: "policy/eb-policy-renewal/revise-quotation/request",
		},
		COVERAGES: {
			CLAIMS: "product/eb-claim/page",
			CLAIM: "product/eb-claim/details/",
			LIST: "policy/manage-coverage/page",
			CLAIMANTS: "product/eb-claim/insured/list/",
			INSURANCE_PLANS_LIST: "policy/manage-coverage/insurance-dropdown",
			UPDATE_INSURER_COVERAGE: "policy/manage-coverage/update-coverage",
			UTILIZATION_INSURANCE: "policy/manage-coverage/insurance-utilization",
			POLICIES: "product/eb-claim/policy-no/list",
			CLAIM_DOCUMENTS: "product/eb-claim/doc/list/",
			POLICY_CLAIM_TYPE: "product/eb-claim/type/list/",
			ALL_CLAIM_TYPE: "product/eb-claim/type/all/list",
			DOWNLOAD_CLAIM_DOCUMENTS: "product/eb-claim/doc/list/download",
			SUBMIT_CLAIM: "product/eb-claim/submit",
			UPLOAD_CLAIM: "product/eb-claim/upload-doc/",
			UPLOAD_ADDITIONAL_CLAIM_DOCUMENT: "product/eb-claim/additional/upload-doc/",
			COMPANIES: "policy/user-company",
		},
	},
	REGEX: {
		SYMBOL: /(\W|_)/,
		NUMERIC: /(?=.*\d)/,
		LOWERCASE: /(?=.*[a-z])/,
		UPPERCASE: /(?=.*[A-Z])/,
		EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
	MASKING: {
		NRIC: "******-**-****",
	},
	RELATIONSHIP: {
		SPOUSE: "SPOUSE",
		MARRIED: "MARRIED",
	},
	IDENTIFICATION_TYPE: {
		NRIC: "NRIC",
		PASSPORT: "PASSPORT",
	},
	NATIONALITY: {
		MALAYSIAN: "MALAYSIAN",
		FOREIGNER: "FOREIGNER",
	},
	DROPDOWNS: {
		GENDER: [
			{ label: "Male", value: "MALE" },
			{ label: "Female", value: "FEMALE" },
		],
		DAYS_CALCULATION: [
			{ label: "Calendar Days", value: "CALENDAR_DAYS" },
			{ label: "Working Days", value: "WORKING_DAYS" },
		],
		LEAVES_STATUS: [
			{ label: "Working", value: "WORKING" },
			{ label: "On Leave", value: "ON_LEAVE" },
		],
		INSURANCE_CLAIM_STATUS: [
			{ label: "Deferment", value: "DEFERMENT" },
			{ label: "Approved", value: "APPROVED" },
			{ label: "Rejected", value: "REJECTED" },
		],
		AMENDMENT_REQUEST_STATUS: [
			{ label: "Pending", value: "PENDING" },
			{ label: "Submitted", value: "SUBMITTED" },
			{ label: "Approved", value: "APPROVED" },
			{ label: "Rejected", value: "REJECTED" },
		],
		REJECT_POLICY_REASON: [
			{ label: "Expensive Premium", value: "Expensive Premium" },
			{ label: "Wish to switch to another provider", value: "Wish to switch to another provider" },
			{ label: "Wish to enhance coverage plan", value: "Wish to enhance coverage plan" },
			{ label: "Other reason", value: "Other reason" },
		],
		PLANS: [
			{ label: "Premier", value: "PREMIER" },
			{ label: "Regular Plus", value: "REGULAR_PLUS" },
			{ label: "Regular", value: "REGULAR" },
			{ label: "Not covered", value: "NOT_COVERED" },
		],
	},
	BENEFITS: {
		DAYS: {
			CALENDAR_DAYS: "CALENDAR_DAYS",
			WORKING_DAYS: "WORKING_DAYS",
		},
		LEAVES: {
			ANNUAL_LEAVE: "ANNUAL_LEAVE",
			MEDICAL_LEAVE: "MEDICAL_LEAVE",
			PATERNITY_LEAVE: "PATERNITY_LEAVE",
			MATERNITY_LEAVE: "MATERNITY_LEAVE",
			HOSPITALISATION_LEAVE: "HOSPITALISATION_LEAVE",
			CARRY_FORWARD_LEAVE: "CARRY_FORWARD_LEAVE",
		},
		CLAIMS: {
			MEDICAL_CLAIM: "MEDICAL_CLAIM",
			BODYCHECK_OPTICAL_DENTAL_CLAIM: "BODYCHECK_OPTICAL_DENTAL_CLAIM",
			OTHER_CLAIM: "OTHER_CLAIM",
		},
	},
	EMPLOYEE: {
		SETUP: {
			SETUP: "SETUP",
			COMPLETED: "COMPLETED",
			INCOMPLETE: "INCOMPLETE",
			ORDER_COMPLETED: "ORDER_COMPLETED",
		},
		CONTRACT_TYPE: {
			FULL_TIME_EMPLOYEE: "FULL_TIME_EMPLOYEE",
		},
	},
	MAX_FILE_SIZES: {
		MB_5: 5000000,
		MB_10: 10000000,
		MB_15: 15000000,
	},
	APP_STATUS: {
		NEW: "new",
	},
	STATUS_ID: {
		ACTIVE: "ACTIVE",
		INACTIVE: "INACTIVE",
		DELETED: "DELETED",
		DISABLED: "DISABLED",
		EXPIRED: "EXPIRED",
		PENDING: "PENDING",
		REJECTED: "REJECTED",
		SUCCEEDED: "SUCCEEDED",
		CANCELLED: "CANCELLED",
		DECLINED: "DECLINED",
		ACCEPTED: "ACCEPTED",
		SUBMITTED: "SUBMITTED",
		FAILED: "FAILED",
		READ: "READ",
		NOT_READ: "NOT_READ",
		WORKING: "WORKING",
		ON_LEAVE: "ON_LEAVE",
		APPROVED: "APPROVED",
		PROBATION: "PROBATION",
		INTERN: "INTERN",
		COMPLETED: "COMPLETED",
		PERMANENT: "PERMANENT",
		TERMINATION: "TERMINATION",
		RESIGNED: "RESIGNED",
		DISMISSAL: "DISMISSAL",
		PENDING_APPROVAL: "PENDING_APPROVAL",
		INVITATION_PENDING: "INVITATION_PENDING",
		CONVERSATION_ENDED: "CONVERSATION_ENDED",
		FULL_TIME_EMPLOYEE: "FULL_TIME_EMPLOYEE",
		FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
		PENDING_COMPANY_SETUP: "PENDING_COMPANY_SETUP",
		ORDER_PROCESSING: "ORDER_PROCESSING",
		DEFERMENT: "DEFERMENT",
		ORDER_REJECTED: "ORDER_REJECTED",
		ORDER_COMPLETED: "ORDER_COMPLETED",
		PAYMENT_FAILED: "PAYMENT_FAILED",
		PAYMENT_EXPIRED: "PAYMENT_EXPIRED",
		ORDER_CANCELLED: "ORDER_CANCELLED",
		PENDING_RELEASE_PAYMENT: "PENDING_RELEASE_PAYMENT",
		PENDING_POLICY_IN_FORCE: "PENDING_POLICY_IN_FORCE",
		CANCEL: "CANCEL",
		MATURED: "MATURED",
		IN_FORCE: "IN_FORCE",
		WITHDRAWN: "WITHDRAWN",
		SUSPENDED: "SUSPENDED",
		SURRENDER: "SURRENDER",
		TERMINATED: "TERMINATED",
		PAYER_WAIVER: "PAYER_WAIVER",
		PREMIUM_HOLIDAY: "PREMIUM_HOLIDAY",
		TERMINATED_DEATH: "TERMINATED_DEATH",
		TERMINATED_MANUAL: "TERMINATED_MANUAL",
		CONDITIONAL_APPROVED: "CONDITIONAL_APPROVED",
		PENDING_ADDITIONAL_DOCS: "PENDING_ADDITIONAL_DOCS",
		PROCESSING: "PROCESSING",
		SUCCESSFUL: "SUCCESSFUL",
		REFUNDED: "REFUNDED",
		UNCLAIMED: "UNCLAIMED",
		REFUND_IN_PROGRESS: "REFUND_IN_PROGRESS",
		UNPAID: "UNPAID",
		PAID: "PAID",
		PENDING_PAYMENT: "PENDING_PAYMENT",
		PENDING_IN_FORCE: "PENDING_IN_FORCE",
		PENDING_POLICY_NO: "PENDING_POLICY_NO",
		PAYMENT_IN_PROGRESS: "PAYMENT_IN_PROGRESS",
		REQUEST_REVISED_QUOTATION: "REQUEST_REVISED_QUOTATION",
		PENDING_REVISED_QUOTATION: "PENDING_REVISED_QUOTATION",
	},
	STATUS_CODE: {
		USER_BLOCKED: "MC_000018",
		INVALID_TOKEN: "MC_000016",
		TOKEN_EXPIRED: "MC_000011",
		REFRESH_TOKEN_EXPIRED: "MC_000017",
		MAXIMUM_PASSWORD_ATTEMPTS: "MC_UAA_00010",
	},
	INSURANCE: {
		PLANS: {
			PREMIER: "PREMIER",
			REGULAR_PLUS: "REGULAR_PLUS",
			REGULAR: "REGULAR",
			NOT_COVERED: "NOT_COVERED",
		},
		PRODUCT_EB_CODE: {
			GHS: "GHS",
			GTL: "GTL",
			GO: "GO",
		},
		COVERAGES: {
			ROOM_AND_BOARD: "ROOM_AND_BOARD",
		},
		CLAIMS: {
			DOCUMENTS: {
				ADDITIONAL_REQUIRED_DOC: "ADDITIONAL_REQUIRED_DOC",
				BASIC_REQUIRED_DOC: "BASIC_REQUIRED_DOC",
			},
		},
	},
	COVERAGES: {
		PAYMENT_TYPE: {
			DEBIT_NOTE: "DEBIT_NOTE",
			CREDIT_NOTE: "CREDIT_NOTE",
		},
	},
	TABLE_REQUEST_STATUS: {
		INSTANCE: "INSTANCE",
	},
};

export default COMMON;
