const getFileName = (url) => {
	if (!url) return null;

	const matches = url.match(/\/([^/?#]+)[^/]*$/);

	if (matches.length > 1) return matches[1];

	return null;
};

export default getFileName;
