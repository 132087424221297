import React, { memo, forwardRef, useImperativeHandle, useMemo, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import converReadableFileSize from "common/convert-readable-file-size";
import { CALENDAR_FORMAT, isDateBeforeToday } from "common/calendar";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCalendarInput from "components/app-calendar-input";
import uploadIcon from "assets/images/upload-icon.svg";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

export const AppCreateAnnouncementModal = (props, ref) => {
	const dispatch = useDispatch();
	const uploadInputRef = useRef();
	const currentYear = useMemo(() => new Date().getFullYear(), []);
	const minDate = useMemo(() => new Date(currentYear, 0, 1), [currentYear]);
	const maxDate = useMemo(() => new Date(currentYear, 11, 31), [currentYear]);
	const [visible, setVisible] = useState(false);
	const [announcement, setAnnouncement] = useState({});
	const initialValues = useMemo(() => {
		const data = { id: "", title: "", description: "", startDate: "", endDate: "", file: "" };

		if (announcement.id) data.id = announcement.id;
		if (announcement.title) data.title = announcement.title;
		if (announcement.description) data.description = announcement.description;
		if (announcement.startDate) data.startDate = announcement.startDate;
		if (announcement.endDate) data.endDate = announcement.endDate;
		if (announcement.hrAnnouncementFile) data.file = { name: announcement.hrAnnouncementFile.fileName, size: announcement.hrAnnouncementFile.fileSize, filePath: announcement.hrAnnouncementFile.filePath };

		return data;
	}, [announcement]);
	//prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: yup.object({
			title: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			startDate: yup.string().required(ERRORS.REQUIRED).test("beforeEndDate", ERRORS.START_DATE, function (value) { return isDateBeforeToday(value, this.parent.endDate); }),
			endDate: yup.string().required(ERRORS.REQUIRED).test("afterStartDate", ERRORS.END_DATE, function (value) { return isDateBeforeToday(this.parent.startDate || new Date(), value); }),
			file: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, (v) => validateFileSize(v)),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});
	const fileSize = useMemo(() => formik.values?.file?.size, [formik.values]);

	const onHandleShow = useCallback((obj) => {
		if (obj.id) setAnnouncement(obj);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		formik.resetForm();
		setAnnouncement({});
		setVisible(false);
	}, [formik]);

	const onHandleUploadFile = useCallback(() => {
		uploadInputRef.current.click();
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		const file = event.target.files[0];
		formik.setFieldValue("file", file);
	}, [formik]);

	//prettier-ignore
	const onHandleUploadDocument = useCallback(async (values, announcementId) => {
		let response = false;

		if (values.file && !values.file.filePath) {
			try {
				const formData = new FormData();
				formData.append("file", values.file);
				await api.post.announcements.upload(announcementId, formData);
				response = true;
			} catch (error) {
				serveRequestErrors(error);
				formik.setSubmitting(false);
			} finally {
				uploadInputRef.current.value = ""
			}
		}
		else {
			response = true;
		}

		if (response) {
			onHandleDismiss();
			dispatch(promptAlertMessage({ message: values.id ? "Announcement has been updated successfully": "Announcement has been created successfully" }));
			props.onHandleGetList();
		}
	}, [dispatch, formik, props, onHandleDismiss]);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let response = false;

		try {
			const payload = {
				title: values.title,
				description: values.description,
				pinned: false,
				startDate: values.startDate,
				endDate: values.endDate,
			};

			if(values.id) {
				payload.id = values.id;
				response = await api.post.announcements.update(payload);
			}
			else {
				response = await api.post.announcements.create(payload);
			}

		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}
		if (response) onHandleUploadDocument(values, response?.id);
	}, [formik, onHandleUploadDocument]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-create-announcement-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="create-announcement-modal" aria-describedby="create-announcement-modal">
			<div className="create-announcement-modal">
				<div className="create-announcement-modal__main">
					<div className="create-announcement-modal__header">
						<p className="create-announcement-modal__title">Create Announcement</p>
					</div>

					<form className="create-announcement-modal__body" onSubmit={formik.handleSubmit}>
						{/* prettier-ignore */}
						<AppInput required type="text" name="title" label="Title" placeholder="Title" value={formik.values.title} error={formik.errors.title} touched={formik.touched.title} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppCalendarInput required name="startDate" label="Start Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} minDate={minDate} maxDate={maxDate} value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />

						{/* prettier-ignore */}
						<AppCalendarInput required name="endDate" label="End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} minDate={minDate} maxDate={maxDate} value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />

						{/* prettier-ignore */}
						<AppInput required multiline multilineRow={4} maxLength={255} type="text" name="description" label="Description" placeholder="Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						<div className="create-announcement-modal__upload-input">
							{/* prettier-ignore */}
							<label className="app-input__label" htmlFor="attachment">Attachment</label>
							<p className="create-announcement-modal__description">Please ensure that your file is in the correct format (jpg, pdf) with each file size not exceeding 5MB.</p>

							<div className="create-announcement-modal__input">
								{formik.values?.file && (
									<button type="button" className="create-announcement-modal__upload-button" onClick={onHandleUploadFile}>
										{formik.values?.file?.name}
										<img className="create-announcement-modal__icon" src={uploadIcon} alt="upload" />
									</button>
								)}

								{!formik.values?.file && (
									<button type="button" className="create-announcement-modal__upload-button" onClick={onHandleUploadFile}>
										Uploaded
										<img className="create-announcement-modal__icon" src={uploadIcon} alt="upload" />
									</button>
								)}

								<p className="create-announcement-modal__label">{converReadableFileSize(fileSize, true) || "-"}</p>

								<input type="file" name="file" accept="image/jpeg, image/jpg, image/png" hidden ref={uploadInputRef} onChange={onHandleChange} />
							</div>

							{formik.touched.file && formik.errors.file && <div className="create-announcement-modal__error">{formik.errors.file}</div>}
						</div>

						<div className="create-announcement-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Create" />
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCreateAnnouncementModal));

AppCreateAnnouncementModal.propTypes = {
	ref: PropTypes.object,
};
