import React, { memo, forwardRef, useMemo, useImperativeHandle, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import classNames from "common/class-names";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

const AppScreenLoading = (props, ref) => {
	const counter = useRef();
	const [loading, setLoading] = useState("");
	const [visible, setVisible] = useState(false);
	const [animation, setAnimation] = useState(false);
	//prettier-ignore
	const className = useMemo(() => classNames({
		"app-screen-loading": true,
        "app-screen-loading--active": animation
	}), [animation]);

	const onHandleShow = useCallback(() => {
		if (counter.current) clearInterval(counter.current);
		setVisible(true);
		setAnimation(true);

		counter.current = setInterval(() => {
			setLoading((prev) => {
				if (prev.length >= 3) {
					return "";
				} else {
					return prev + ".";
				}
			});
		}, 500);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setAnimation(false);

		setTimeout(() => {
			setVisible(false);
			clearInterval(counter.current);
		}, 500);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		return () => {
			if (counter.current) clearInterval(counter.current);
		};
	}, []);

	if (!visible) return null;

	return (
		<div className={className}>
			<div className="screen-loading">
				<div className="screen-loading__icon">
					<LoadingLogo />
					<p className="screen-loading__text">LOADING</p>
					<p className="screen-loading__dot">{loading}</p>
				</div>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppScreenLoading));

AppScreenLoading.propTypes = {
	ref: PropTypes.object,
};
