import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import api from "services/api";
import AppCheckbox from "components/app-checkbox";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

export const AppDownloadClaimDocumentModal = (props, ref) => {
	const location = useLocation();

	const [visible, setVisible] = useState(false);

	const [downloadProgress, setDownloadProgress] = useState(0);

	const [selectedPolicy, setSelectedPolicy] = useState([]);

	const documents = useMemo(() => props.documents, [props.documents]);

	const selectedAll = useMemo(() => {
		return selectedPolicy?.length === (documents?.length || 0);
	}, [selectedPolicy, documents]);

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setSelectedPolicy([]);
		setDownloadProgress(0);
	}, []);

	//prettier-ignore
	const onHandleSelectPolicy = useCallback((event) => {
        const name = event.target.name;
        const isSelectedAllField = name === "all";

        if (isSelectedAllField) {
            let next = [];

            if(!selectedAll) next = documents?.map((a) => documents.map(b => b.id.toString())).flat();
            
            setSelectedPolicy(next);
        } else {
            setSelectedPolicy((prev) => {
                const current = [...prev];
                const found = current.findIndex((o) => o === name) > -1;
                const next = current.filter((o) => o !== name);

                if (!found) next.push(name);

                return next;
            });
        }
    }, [documents, selectedAll]);

	const onHandleDownloadDocuments = useCallback(async () => {
		let response = null;
		let fileName = "";
		try {
			const onDownloadProgress = (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setDownloadProgress(percentCompleted);
			};
			
			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];
	
				if (fileName) return data;
	
				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));
					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			const payload = {
				basicRequiredDocTypeIds: selectedPolicy,
				claimTypeId: location.state.claimType,
				companyEmployeeDetailId: location.state.claimant,
			};

			response = await api.post.coverages.downloadClaimDocuments(payload, { onDownloadProgress, transformResponse });
		} catch (error) {
			setDownloadProgress(0);
			serveRequestErrors(error);
		}
		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();
			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setDownloadProgress(0);
			}, 1000);
		}
	}, [location, selectedPolicy]);

	useEffect(() => {
		const onHandleScrollHeight = () => {
			const element = document.querySelector(".download-claim-document__list");

			if (element) element.style.maxHeight = `${window.innerHeight - 400}px`;
		};

		const debouncedCheck = debounce(onHandleScrollHeight, 50);

		if (documents?.files) onHandleScrollHeight();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [documents]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-download-claim-document-modal" }} open={visible} aria-labelledby="download-claim-document" aria-describedby="download-claim-document-modal">
			<div className="download-claim-document">
				<div className="download-claim-document__header">
					<p className="download-claim-document__title">Download Document</p>
					<p className="download-claim-document__description">Please select document to download</p>
				</div>

				{!documents && (
					<div className="download-claim-document__loading">
						<LoadingLogo />
					</div>
				)}

				{documents && (
					<ul className="download-claim-document__list">
						<div className="download-claim-document__list-header">
							All
							<AppCheckbox label="" onClick={onHandleSelectPolicy} name="all" value={selectedAll} />
						</div>

						{documents.map((a) => {
							const id = a.id.toString();
							const found = selectedPolicy?.findIndex((c) => c === id) > -1;

							return (
								<li className="download-claim-document__item" key={a.id}>
									{a.label}
									<AppCheckbox label="" onClick={onHandleSelectPolicy} name={id} value={found} />
								</li>
							);
						})}
					</ul>
				)}

				<div className="download-claim-document__progress-bar" style={{ width: `${downloadProgress}%` }} />

				<div className="download-claim-document__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Download" onClick={onHandleDownloadDocuments} disabled={!!downloadProgress || !selectedPolicy.length} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDownloadClaimDocumentModal));

AppDownloadClaimDocumentModal.propTypes = {
	ref: PropTypes.object,
};
