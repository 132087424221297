import React, { memo, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import PropTypes from "prop-types";

import COMMON from "common";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import sideNavList from "common/side-nav-list";
import AppLogoutIcon from "components/icons/app-logout-icon";
import AppChevronIcon from "components/icons/app-chevron-icon";
import editIcon from "assets/images/green-edit-icon.svg";

const AppSideNav = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [activeIndex, setActiveIndex] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const profile = useSelector((state) => state.profile);
	const logo = useMemo(() => profile.logo, [profile.logo]);
	const company = useMemo(() => profile.company, [profile]);
	const initialzieSideNav = useMemo(() => sideNavList.map((cloned) => ({ ...cloned })), []);
	const onHandleSetBackTitle = useMemo(() => props.onHandleSetBackTitle, [props.onHandleSetBackTitle]);
	const onHandleSetTitle = useMemo(() => props.onHandleSetTitle, [props.onHandleSetTitle]);
	const isInCompletedSetupCompany = useMemo(() => company?.setupFlag === COMMON.EMPLOYEE.SETUP.INCOMPLETE, [company]);
	const onHandleSelectCompany = useMemo(() => props.onHandleSelectCompany, [props.onHandleSelectCompany]);
	const onHandleUploadCompanyProfile = useMemo(() => props.onHandleUploadCompanyProfile, [props.onHandleUploadCompanyProfile]);

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleOpenMenu = useCallback((event) => {
		setAnchorEl(event.target);
	}, []);

	const onHandleMenuUploadLogo = () => {
		setAnchorEl(null);
		onHandleUploadCompanyProfile();
	};

	const onHandleMenuSwitchCompany = () => {
		setAnchorEl(null);
		onHandleSelectCompany();
	};

	const enrichedSideNav = useMemo(() => {
		let cloneSideNav = [...initialzieSideNav];
		const isCompletedSetupCompany = company?.setupFlag === COMMON.EMPLOYEE.SETUP.COMPLETED;
		const isIncompletedSetupCompany = company?.setupFlag === COMMON.EMPLOYEE.SETUP.INCOMPLETE;
		const isOrderCompletedCompany = company?.orderStatus === COMMON.EMPLOYEE.SETUP.ORDER_COMPLETED;
		const isOrderInCompletedCompany = company?.orderStatus !== COMMON.EMPLOYEE.SETUP.ORDER_COMPLETED;

		let sideNav = [];

		if (!company) return sideNav;

		if (isIncompletedSetupCompany) cloneSideNav = cloneSideNav.filter((o) => o.path === pathnames.setup.setup);

		if (isCompletedSetupCompany && isOrderInCompletedCompany) {
			cloneSideNav = cloneSideNav.filter((o) => o.path === pathnames.orders);
		}

		/* If completed setup remove setup tab */
		if (isCompletedSetupCompany && isOrderCompletedCompany) cloneSideNav.shift();

		//prettier-ignore
		sideNav = cloneSideNav.map((a) => {
			const role = profile?.companyRoles?.[0]?.roleNameEn?.toLowerCase();
			
			let filtered = { ...a };

			const childrens = filtered.childrens?.filter(b => {
				if(b.name) return b.name === role;

				return true;
			});

			filtered.childrens = childrens;

			return filtered;
		}).filter((o) => o);

		return sideNav;
	}, [profile, company, initialzieSideNav]);

	//prettier-ignore
	const itemLabelClassName = useCallback((currentIndex) => {
		return classNames({ "side-nav__label": true, "side-nav__label--active": activeIndex === currentIndex });
	}, [activeIndex]);

	//prettier-ignore
	const itemChildLabelClassName = useCallback((pathname, relatives) => {
		const samePath = location.pathname === pathname;
		const relativesActive = relatives.findIndex(o => location.pathname.includes(o)) > -1;
		return classNames({ "side-nav__label": true, "side-nav__label--child-active": samePath || relativesActive });
	}, [location]);

	//prettier-ignore
	const iconColor = useCallback((currentIndex) => {
		return currentIndex === activeIndex ? "#0245A9" : undefined;
	}, [activeIndex]);

	const onHandleLogout = useCallback(() => {
		localStorage.clear();
		sessionStorage.clear();
		dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
		navigate(pathnames.login);
	}, [dispatch, navigate]);

	const onHandleToggleSideNav = () => {
		const sideNav = document.getElementById("side-nav");
		const appAlert = document.getElementById("app-alert");
		const layoutMenu = document.getElementById("header-menu");
		const layoutContainer = document.getElementById("layout-container");

		if (appAlert) appAlert.classList.toggle("app-alert--shrinked");
		sideNav.classList.toggle("app-side-nav--shrinked");
		layoutMenu.classList.toggle("header__menu--shrinked");
		layoutContainer.classList.toggle("main__container--shrinked");
	};

	//prettier-ignore
	const onHandleHighlight = useCallback((index) => {
		setActiveIndex(index);

		setTimeout(() => {
			const items = document.querySelectorAll(".side-nav__item");
			const sideNavTarget = document.getElementById("side-nav-target");

			if (sideNavTarget && items[index]) {
				const sideNavWrapper = items[index].querySelector(".side-nav__wrapper");
				
				sideNavTarget.style.height = `${sideNavWrapper.clientHeight}px`;
				
				return sideNavTarget.style.top = `${items[index]?.offsetTop}px`;
			} else if (sideNavTarget) {
				return sideNavTarget.style.top = `-300px`;
			}
		});
	}, []);

	//prettier-ignore
	const onHandleItemChange = useCallback((obj, index) => {
		onHandleHighlight(index);

		if (!obj.childrens.length) {
			onHandleSetTitle(enrichedSideNav[index].label);
		}
		else {
			onHandleSetTitle(enrichedSideNav[index].childrens[0].label);
		}

		if (obj.childrens.length) {
			navigate(obj.childrens[0].path);
		}
		else {
			navigate(obj.path);
		}
	}, [enrichedSideNav, navigate, onHandleHighlight, onHandleSetTitle]);

	//prettier-ignore
	const onHandleChildChange = useCallback((event, obj) => {		
		const isMobile = window.innerWidth <= 768;

		event.stopPropagation();
		onHandleSetTitle(obj.label);
		navigate(obj.path);

		if (isMobile) onHandleToggleSideNav();

	}, [navigate, onHandleSetTitle]);

	//prettier-ignore
	const SideNavChildrens = useCallback((obj) => {
		if (!obj.active) return null;

		const onHandleMobileSideNav = (event) => {
			const isMobile = window.innerWidth <= 768;
			event.stopPropagation();
			if (isMobile) onHandleToggleSideNav();
		};

		return obj.childrens.map((j) => {
			const isSame = obj.location.pathname === j.path;
			let onClick = () => {};

			if(!obj.isInCompletedSetupCompany) {
				if(isSame) onClick = onHandleMobileSideNav;
				else onClick = (event) => obj.onHandleChildChange(event, j);
			}

			return (
				<div className="side-nav__children" key={j.label} onClick={onClick}>
					<p className={obj.itemChildLabelClassName(j.path, j.relatives)}>{j.label}</p>
				</div>
			);
		});
	}, []);

	const SideNavMenu = useCallback((obj) => {
		if (obj.companies?.length > 1) {
			return (
				<button className="side-nav__menu-button" onClick={obj.onHandleOpenMenu}>
					<AppChevronIcon color="#ffffff" />
				</button>
			);
		} else if (obj.companies?.length === 1) {
			return (
				<button className="side-nav__edit-button" onClick={() => obj.onHandleUploadCompanyProfile()}>
					<img src={editIcon} alt="edit" />
				</button>
			);
		} else {
			return null;
		}
	}, []);

	useEffect(() => {
		/* Set Nav Background To Specify Position */
		let sideNavIndex = null;

		const childrensIndex = enrichedSideNav.findIndex((o) => {
			/* Find matching sideNav childrens path */
			return (
				o.childrens?.findIndex((j) => {
					const isChildrenPath = location.pathname === j.path;

					if (!isChildrenPath) {
						const relativeIndex = j.relatives.findIndex((i) => location.pathname.includes(i));

						onHandleSetTitle(j.label);

						return relativeIndex > -1;
					} else {
						onHandleSetTitle(j.label);

						return true;
					}
				}) > -1
			);
		});

		if (childrensIndex > -1) {
			sideNavIndex = childrensIndex;
		} else {
			/* Find matching sideNav main path */
			const mainIndex = enrichedSideNav.findIndex((o) => o.path === location.pathname);

			if (mainIndex > -1) {
				sideNavIndex = mainIndex;
				onHandleSetTitle(enrichedSideNav[mainIndex].label);
			} else {
				/* Find matching sideNav main relative path */
				const sideNavRelativeIndex = enrichedSideNav.findIndex((a) => {
					const index = a.relatives.findIndex((b) => location.pathname.includes(b.path));
					return index > -1;
				});

				if (sideNavRelativeIndex > -1) {
					sideNavIndex = sideNavRelativeIndex;
					const relativeItem = enrichedSideNav[sideNavIndex].relatives.find((b) => location.pathname.includes(b.path));

					onHandleSetBackTitle(relativeItem.title);
				} else {
					onHandleSetBackTitle("");
				}

				onHandleSetTitle("");
			}
		}

		if (id) onHandleSetTitle("");

		onHandleHighlight(sideNavIndex);
	}, [id, enrichedSideNav, onHandleSetTitle, location, onHandleHighlight, onHandleSetBackTitle]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleToggleSideNav: onHandleToggleSideNav,
	}));

	return (
		<div id="side-nav" className="app-side-nav">
			<div className="side-nav">
				<div className="side-nav__header">
					{!logo && company?.name && (
						<p className="side-nav__name" onClick={onHandleToggleSideNav}>
							{company.name}
						</p>
					)}

					{logo && <div className="side-nav__logo" onClick={onHandleToggleSideNav} style={{ backgroundImage: `url(${logo})` }} />}

					<SideNavMenu companies={profile.companies} onHandleUploadCompanyProfile={onHandleUploadCompanyProfile} onHandleOpenMenu={onHandleOpenMenu} />
				</div>
				<h1 className="side-nav__headline">HR Platform</h1>

				<nav className="side-nav__nav">
					<span id="side-nav-target" className="side-nav__background" />

					<ul className="side-nav__list">
						{enrichedSideNav.map((o, i) => {
							const Icon = o.icon;
							const activeItem = activeIndex === i;

							return (
								<li className="side-nav__item" key={i}>
									<div className="side-nav__wrapper" onClick={() => (!isInCompletedSetupCompany ? onHandleItemChange(o, i) : () => {})}>
										<div className="side-nav__content">
											<div className="side-nav__icon">
												<Icon color={iconColor(i)} />
											</div>
											<p className={itemLabelClassName(i)}>{o.label}</p>
										</div>
									</div>

									{/* prettier-ignore */}
									<SideNavChildrens childrens={o.childrens} active={activeItem} onHandleChildChange={onHandleChildChange} itemChildLabelClassName={itemChildLabelClassName} isInCompletedSetupCompany={isInCompletedSetupCompany} location={location} />
								</li>
							);
						})}
					</ul>
					<div className="side-nav__item side-nav__item--logout" onClick={onHandleLogout}>
						<div className="side-nav__wrapper">
							<div className="side-nav__icon">
								<AppLogoutIcon />
							</div>
							<p className="side-nav__label">Logout</p>
						</div>
					</div>
				</nav>
			</div>

			<Menu classes={{ root: "app-header-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleMenuUploadLogo}>
					<p className="app-header-menu__text">Edit Logo</p>
				</MenuItem>

				<div className="app-header-menu__divider" />

				<MenuItem onClick={onHandleMenuSwitchCompany}>
					<p className="app-header-menu__text">Switch Company</p>
				</MenuItem>
			</Menu>
		</div>
	);
};

export default memo(forwardRef(AppSideNav));

AppSideNav.propTypes = {
	onHandleSetTitle: PropTypes.func.isRequired,
	onHandleSetBackTitle: PropTypes.func.isRequired,
};
