import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import { formatCurrency } from "common/format-currency-pattern";
import serveRequestErrors from "common/serve-request-errors";
import AppStatus from "components/app-status";
import pdfIcon from "assets/images/pdf-icon.svg";
import AppCloseIcon from "components/icons/app-close-icon";

export const AppClaimModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);

	const productInfo = useMemo(() => {
		const approvalAmount = data?.status === COMMON.STATUS_ID.APPROVED ? { label: "Approved Amount (RM)", value: data?.approvedClaimValue ? formatCurrency(data.approvedClaimValue) : "-" } : {};
		const rejectReason = data?.status === COMMON.STATUS_ID.REJECTED ? { label: "Reject Reason", value: data?.rejectReason || "-" } : {};

		const info = [
			{ label: "User Name", value: data.name || "-" },
			{ label: "Employee ID", value: data.employeeId || "-" },
			{ label: "Submit Date", value: formatDatePattern(data.createdDate) || "-" },
			{ label: "Visit Date", value: formatDatePattern(data.actualDate) || "-" },
			{ label: "Bill Amount (RM)", value: data.claimValue ? formatCurrency(data.claimValue) : "-" },
			approvalAmount && { ...approvalAmount },
			{ label: "Attachment", value: data?.companyClaimApplicationFiles?.[0] || "-" },
			{ label: "Description", value: data.description || "-" },
			rejectReason && { ...rejectReason },
		].filter((o) => o.label);

		return info;
	}, [data]);

	const onHandleShow = useCallback((id) => {
		setData((prev) => ({ ...prev, id }));
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				response = await api.get.claims.claim(data.id);
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Modal classes={{ root: "app-claim-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="claim-modal" aria-describedby="claim-modal">
			<div className="claim-modal">
				<div className="claim-modal__main">
					<button className="claim-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="claim-modal__header">
						<div className="claim-modal__profile">
							<p className="claim-modal__title">Claim Details</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="claim-modal__text">Claim Type</p>
							<p className="claim-modal__title">{data.claimTypeMapped}</p>
						</div>
						<div className="info__box">
							<p className="claim-modal__text">Status</p>
							<div className="claim-modal__title">
								<AppStatus status={data?.status || "-"} />
							</div>
						</div>
					</div>

					<div className="claim-modal__body">
						<div className="claim-modal__container">
							<div className="claim-modal__wrapper">
								{productInfo.map((o, i) => {
									const fileName = o.value?.fileName;

									return (
										<div className="claim-modal__info" key={i}>
											<p className="claim-modal__label">{o.label}</p>
											{!fileName && <p className="claim-modal__value">{o.value}</p>}
											{fileName && (
												<p className="claim-modal__value">
													<a href={o.value.fileURL} className="claim-modal__link" target="_blank" rel="noopener noreferrer">
														<img src={pdfIcon} alt="attachment" /> {fileName}
													</a>
												</p>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppClaimModal));

AppClaimModal.propTypes = {
	ref: PropTypes.object,
};
