import COMMON from "common";
import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getRelationshipListing = async (filterBy) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { lookupHdrId: "RELATIONSHIP", sort: "seq,asc" };
		const res = await api.get.general.list(params);
		let content = res.map(({ lookupTitle, lookupValue, ...o }) => ({ label: lookupTitle, value: lookupValue, ...o }));

		if (filterBy !== COMMON.RELATIONSHIP.MARRIED) {
			content = content.filter((o) => o.value !== COMMON.RELATIONSHIP.SPOUSE);
		}

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getRelationshipListing;
