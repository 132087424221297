import { useEffect, useRef, useState } from "react";
import { useRouteError } from "react-router-dom";

const Page404 = () => {
	const error = useRouteError();
	const timer = useRef();
	const [counter, setCounter] = useState(0);

	useEffect(() => {
		timer.current = setInterval(() => {
			setCounter((prev) => {
				const code = error?.status ? error.status : 404;
				if (prev === code) {
					clearInterval(timer.current);
					return prev;
				} else {
					return (prev += 1);
				}
			});
		}, 15);

		return () => clearInterval(timer.current);
	}, [error]);

	return (
		<div className="page-404">
			<h1 className="page-404__title">{counter}</h1>
			{error?.message && <p className="page-404__description">{error.message}</p>}
			{error?.stack && <p className="page-404__description">{error.stack}</p>}
		</div>
	);
};

export default Page404;
