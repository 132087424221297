const pathnames = {
	login: "/",
	accountSuspended: "/account-suspended",
	dashboard: "/dashboard",

	orders: "/orders",

	maintenance: "/maintenance",

	userManagement: {
		admins: "/user-management/admins",
		employee: "/user-management/employee/",
		employees: "/user-management/employees",
		amendmentStatus: "/user-management/amendment-status",
	},

	companyBenefits: {
		benefits: "/company-benefits/benefits",
		benefit: "/company-benefits/benefit/",
		positions: "/company-benefits/positions",
	},

	setup: {
		setup: "/setup/setup",
		setupCoverages: "/setup/coverages",
	},

	leaveManagement: {
		leaves: "/leave-management/leaves",
		leave: "/leave-management/leave/",
		leaveDirectory: "/leave-management/leave-directory",
		leavesApproval: "/leave-management/leaves-approval",
	},

	claimManagement: {
		claim: "/claim-management/claim/",
		claimDirectory: "/claim-management/claim-directory",
		claimApproval: "/claim-management/claim-approval",
	},

	insuranceCoverage: {
		claim: "/insurance-coverage/claim",
		selectClaimType: "/insurance-coverage/select-claim-type",
		uploadClaimDocuments: "/insurance-coverage/upload-claim-documents",
		policy: "/insurance-coverage/policy",
		coverages: "/insurance-coverage/coverages",
	},
};

export default pathnames;
