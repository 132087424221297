import React, { useRef, useCallback, useMemo, useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import AppConfirmDeleteModal from "components/pages/leave-management/app-confirm-delete-leave";
import removeIcon from "assets/images/remove-icon.svg";

const AppLeaveUpcoming = () => {
	const { id } = useParams();
	const confirmDeleteRef = useRef();
	const context = useContext(AxiosContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", tab: "Upcoming Leave" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.leaves.upcoming(sanitizeObject({ ...payload, id }));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, [id]);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleRemove = useCallback((id) => {
		confirmDeleteRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon onClick={(event) => onHandleRemove(row.original.id)} icon={removeIcon} />;
	}, [onHandleRemove]);

	const StartDateCell = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.companyLeaveApplicationDetails?.[0]?.date);
		return <AppTableCell left value={date} />;
	}, []);

	const EndDateCell = useCallback(({ row }) => {
		const index = row.original.companyLeaveApplicationDetails?.length - 1;
		const date = formatDatePattern(row.original.companyLeaveApplicationDetails?.[index]?.date);
		return <AppTableCell left value={date} />;
	}, []);

	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.values.status} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Leave Type",
			accessor: "leaveType",
			disableSortBy: true,
		},
		{
			Header: "Days",
			accessor: "noOfDays",
			disableSortBy: true,
		},
		{
			Header: "Start Date",
			accessor: "startDate",
			disableSortBy: true,
			Cell: StartDateCell
		},
		{
			Header: "End Date",
			accessor: "endDate",
			disableSortBy: true,
			Cell: EndDateCell
		},
		{
			Header: "Time Off",
			accessor: "timeOff.label",
			disableSortBy: true,
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [StartDateCell, EndDateCell, StatusCell, MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.LEAVES.UPCOMING);
		};
	}, [context]);

	return (
		<div className="app-leave-summary">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}
			{!isEmptyState && <AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />}

			<AppConfirmDeleteModal ref={confirmDeleteRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default AppLeaveUpcoming;
