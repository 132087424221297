import React, { memo, forwardRef, useImperativeHandle, useState, useRef, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import converReadableFileSize from "common/convert-readable-file-size";
import AppButton from "components/app-button";
import removeIcon from "assets/images/remove-icon.svg";
import uploadIcon from "assets/images/upload-teal-icon.svg";

export const AppAdditionalDocumentsModal = (props, ref) => {
	const dispatch = useDispatch();
	const uploadInputRef = useRef();
	const [visible, setVisible] = useState(false);
	const [uploadProgress, setUploadProgress] = useState({});
	const initialValues = useMemo(() => ({ files: [] }), []);
	//prettier-ignore
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			files: yup.array().min(1, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const maxFileLength = useMemo(() => formik.values.files?.length === 5, [formik.values]);

	const onHandleShow = useCallback(async () => {
		try {
			const response = await api.get.orders.additionalDocuments();
			formik.setFieldValue("files", response);
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setVisible(true);
		}
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback(async (event) => {
        formik.setSubmitting(true);

        const file = event.target.files[0];
        /* add local file to files in order to display file in column */
        const files = [...formik.values.files, file];

        let response = null;

        const onUploadProgress = (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			setUploadProgress((prev) => ({ ...prev, [file.name]: percentCompleted }));
		};

		const validFileSize = validateFileSize([file], COMMON.MAX_FILE_SIZES.MB_10);

		if(!validFileSize) {
			formik.setSubmitting(false);
			return serveRequestErrors(ERRORS.DOCUMENTS_SIZE_10MB);
		}
		
		uploadInputRef.current.value = "";
		
        formik.setFieldValue("files", files);

		try {
			const formData = new FormData();

			formData.append("file", file);

			formData.append("code", "additional");

			response = await api.post.setup.upload(formData, onUploadProgress);

		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);

			setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
		}

        if(response) {
            /* remove local file from files, and add in response file */
            files.pop();

            formik.setFieldValue("files", [...files, response]);
        }
    }, [formik]);

	//prettier-ignore
	const onHandleRemove = useCallback(async (obj, index) => {

        if(obj.fileKey) {
            try {
                await api.post.orders.removeAdditionalDocument({ fileKey: obj.fileKey });
            }
            catch(error) {
                serveRequestErrors(error);
            }
        }

        const files = [...formik.values.files];
        files.splice(index, 1);

		formik.setFieldValue("files", files);
	}, [formik]);

	const onHandleUploadFile = useCallback(() => {
		uploadInputRef.current?.click();
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback(async () => {
		let response = null;

		try {
			await api.post.orders.submitAdditionalDocument();
            response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
            dispatch(promptAlertMessage({ message: "Documents has been uploaded successfully" }))
            props.onHandleDismiss();
			onHandleDismiss();
		}
	}, [props, formik, dispatch, onHandleDismiss]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-additional-documents-modal" }} open={visible} aria-labelledby="additional-documents-modal" aria-describedby="additional-documents-modal">
			<form className="additional-documents-modal" onSubmit={formik.handleSubmit}>
				<div className="additional-documents-modal__main">
					<div className="additional-documents-modal__header">
						<p className="additional-documents-modal__title">Upload Document</p>
						<p className="additional-documents-modal__description">Please ensure that your document is in the correct format (jpg, pdf) with file size not exceeding 10MB.</p>
					</div>

					<div className="additional-documents-modal__section">
						<p className="additional-documents-modal__text">Uploaded</p>
						<p className="additional-documents-modal__label-size">File Size</p>
					</div>

					{formik.values.files.map((o, i) => {
						const progressStatus = uploadProgress[o?.name];
						const name = o.fileName || o?.name;

						return (
							<div className="additional-documents-modal__upload-section" key={i}>
								<button type="button" className="additional-documents-modal__name" disabled={formik.isSubmitting} onClick={() => onHandleRemove(o, i)}>
									<a href={o.link} className="additional-documents-modal__link" target="_blank" rel="noopener noreferrer" onClick={(event) => event.stopPropagation()}>
										{name}
									</a>
									<img className="additional-documents-modal__icon" src={removeIcon} alt="upload" />
								</button>
								<p className="additional-documents-modal__size">{converReadableFileSize(o?.size || o.docSize, true) || "-"}</p>

								{progressStatus > 0 && <div className="additional-documents-modal__progress-bar" style={{ width: `${progressStatus || 0}%` }} />}
							</div>
						);
					})}

					{!maxFileLength && (
						<div className="additional-documents-modal__upload-section">
							<button type="button" className="additional-documents-modal__upload" disabled={formik.isSubmitting} onClick={onHandleUploadFile}>
								Upload File
								<img className="additional-documents-modal__icon" src={uploadIcon} alt="upload" />
							</button>
							<p className="additional-documents-modal__size">-</p>

							<input type="file" name="file" accept="image/jpeg, image/jpg, image/png, application/pdf" hidden ref={uploadInputRef} onChange={onHandleChange} />
						</div>
					)}

					{formik.touched.files && formik.errors.files && <div className="additional-documents-modal__footer">{formik.errors.files}</div>}

					<div className="additional-documents-modal__button-container">
						<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} disabled={formik.isSubmitting} />
						<AppButton type="submit" label="Submit" disabled={formik.isSubmitting} />
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default memo(forwardRef(AppAdditionalDocumentsModal));

AppAdditionalDocumentsModal.propTypes = {
	ref: PropTypes.object,
};
