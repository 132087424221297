import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import COMMON from "common";
import api from "services/api";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppSelectInput from "components/app-select-input";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

export const AppReviseQuotationEmployeesModal = (props, ref) => {
	const dispatch = useDispatch();
	const [data, setData] = useState();
	const [visible, setVisible] = useState(false);
	const [employees, setEmployees] = useState([]);
	const disabled = useMemo(() => !employees.filter((o) => o.checked).length, [employees]);

	//prettier-ignore
	const onHandleGetEmployees = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.policies.reviseQuotationEmployees();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
            const savedEmployees = response.map(o => ({ ...o, checked: o.ebInsurancePlan && COMMON.INSURANCE.PLANS.NOT_COVERED !== o.ebInsurancePlan }));

			setEmployees(savedEmployees);
		}
	}, []);

	const onHandleSubmit = useCallback(async () => {
		let response = null;

		try {
			const payload = {
				ebPolicyRenewalId: data.renewal.id,
				memberList: employees.map((o) => ({ companyEmployeeDetailId: o.id, ebInsurancePlan: o.ebInsurancePlan })),
			};

			await api.post.policies.reviseQuotation(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setVisible(false);

			dispatch(promptAlertMessage({ message: "Revision request has been submitted successfully. Please allow up to 14 business days for initial review and processing." }));

			props.onHandleDimissPolicyModal();
		}
	}, [dispatch, data, employees, props]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		setVisible(true);

        if(obj?.renewal?.id) {
            setData(obj);
            onHandleGetEmployees();
        }
	}, [onHandleGetEmployees]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setEmployees([]);
	}, []);

	const onHandleChangePlan = useCallback((event, id) => {
		const value = event.target.value;

		setEmployees((prev) => {
			return prev.map((o) => {
				if (o.id === id) {
					return { ...o, ebInsurancePlan: value, checked: value !== COMMON.INSURANCE.PLANS.NOT_COVERED };
				} else {
					return o;
				}
			});
		});
	}, []);

	useEffect(() => {
		const onHandleScrollHeight = () => {
			const element = document.querySelector(".revise-quotation-employees__list");

			if (element) element.style.maxHeight = `${window.innerHeight - 400}px`;
		};

		const debouncedCheck = debounce(onHandleScrollHeight, 50);

		if (employees.length) onHandleScrollHeight();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [employees]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-revise-quotation-employees-modal" }} open={visible} aria-labelledby="revise-quotation-employees" aria-describedby="revise-quotation-employees-modal">
			<div className="revise-quotation-employees">
				<div className="revise-quotation-employees__header">
					<p className="revise-quotation-employees__title">Employee List</p>
					<p className="revise-quotation-employees__description">Revise to ensure it aligns with your needs and preferences</p>
				</div>

				{!employees.length && (
					<div className="revise-quotation-employees__loading">
						<LoadingLogo />
					</div>
				)}

				{Boolean(employees.length) && (
					<ul className="revise-quotation-employees__list">
						{employees?.map((a) => {
							const id = a.id.toString();
							const value = a.ebInsurancePlan ?? COMMON.INSURANCE.PLANS.NOT_COVERED;

							return (
								<li className="revise-quotation-employees__item" key={id}>
									<AppCheckbox label="" onClick={() => {}} name={id} value={a.checked} />
									{a.name}

									<AppSelectInput searchable={false} type="text" name="plan" label="" placeholder="Please Select" options={COMMON.DROPDOWNS.PLANS} value={value} onChange={(event) => onHandleChangePlan(event, a.id)} />
								</li>
							);
						})}
					</ul>
				)}

				<div className="revise-quotation-employees__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Save" disabled={disabled} onClick={onHandleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppReviseQuotationEmployeesModal));

AppReviseQuotationEmployeesModal.propTypes = {
	ref: PropTypes.object,
};
