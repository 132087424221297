import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import sanitizeObject from "common/sanitize-object";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import queryParamsEntries from "common/query-params-entries";
import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { indexing, AppTableMoreIcon } from "components/app-table";
import AppAddAdminModal from "components/pages/user-management/app-add-admin-modal";
import addIcon from "assets/images/add-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";

const PageAdmins = (props) => {
	const dispatch = useDispatch();
	const tableRef = useRef();
	const searchInputRef = useRef();
	const addAdminModalRef = useRef();
	const removeAdmimRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	//prettier-ignore
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", name: searchParams.get("name") || "", email: searchParams.get("email") || "" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const memoSetSearchParams = useRef(setSearchParams);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.name && !paramsRef.current.email, [data.status]);
	const initialValues = useMemo(() => ({ email: "", name: "" }), []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { email: params.email, name: params.name };

		return values;
	}, [searchParams]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.admin.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleRemoveAdmin = async () => {
		setAnchorEl(null);

		let response = null;

		try {
			await api.post.admin.removeRole({ email: removeAdmimRef.current.email });

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: `${removeAdmimRef.current.name} has been removed successfully` }));

			onHandleGetList();
		}
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		switch (id) {
			case "name":
				paramsRef.current.sort = order ? "pi.name," + order : "";
				break;
			default:
				paramsRef.current.sort = order ? id + "," + order : "";
				break;
		}

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandleTableMenu = (event, { row }) => {
		setAnchorEl(event.currentTarget);
		removeAdmimRef.current = row.original;
	};

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
	};

	const MenuCell = useCallback((obj) => {
		return <AppTableMoreIcon onClick={(event) => onHandleTableMenu(event, obj)} />;
	}, []);

	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
        {
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "User Name",
			accessor: "name",
			disableSortBy: false,
		},
		{
			Header: "Email Address",
			accessor: "email",
			disableSortBy: false,
		},
		{
			Header: "Role",
			accessor: "role",
			disableSortBy: true,
            Cell: "Admin"
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, StatusCell]);

	const onHandleAssignEmployee = useCallback(() => {
		addAdminModalRef.current.onHandleShow();
	}, []);

	const headerButtons = useMemo(() => {
		const buttons = [{ label: "Add New", icon: addIcon, outline: false, onClick: onHandleAssignEmployee }];
		return buttons;
	}, [onHandleAssignEmployee]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.ADMIN.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-admins">
			<div className="admins">
				{isEmptyState && (
					<div className="admins__empty-state">
						<AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />
						<div className="admins__button-container">
							<AppButton type="button" label="Add New" onClick={onHandleAssignEmployee} />
						</div>
					</div>
				)}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttons={headerButtons}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="email" label="Email Address" placeholder="Enter Email Address" value={formik.values.email} onChange={formik.handleChange} />

										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter Employee Name" value={formik.values.name} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>

						{(paramsRef.current.name || paramsRef.current.email) && (
							<div className="admins__results">
								<p className="admins__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}

				{/* prettier-ignore */}
				<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<MenuItem onClick={onHandleRemoveAdmin} className="app-table-menu__remove"><img className="app-table-menu__icon" src={removeIcon} alt="view" />Remove</MenuItem>
				</Menu>
			</div>

			<AppAddAdminModal ref={addAdminModalRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default PageAdmins;
