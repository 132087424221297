import pathnames from "routes/pathnames";
import AppDashboardIcon from "components/icons/app-dashboard-icon";
import AppSetupIcon from "components/icons/app-setup-icon";
import AppOrdersIcon from "components/icons/app-orders-icon";
import AppInsurancenIcon from "components/icons/app-insurance-icon";
import AppUserManagementIcon from "components/icons/app-user-management-icon";
import AppCompanyBenefitsIcon from "components/icons/app-company-benefits-icon";
import AppLeaveManagementIcon from "components/icons/app-leave-management-icon";
import AppClaimManagementIcon from "components/icons/app-claim-management-icon";
import ROLES from "./roles";

const sideNavList = [
	{
		label: "Setup",
		path: pathnames.setup.setup,
		icon: AppSetupIcon,
		names: null,
		childrens: [],
		relatives: [{ title: "Back to setup", path: pathnames.setup.setupCoverages }],
	},
	{
		label: "Dashboard",
		path: pathnames.dashboard,
		icon: AppDashboardIcon,
		names: null,
		childrens: [],
		relatives: [],
	},
	{
		label: "User Management",
		path: pathnames.userManagement.employees,
		icon: AppUserManagementIcon,
		names: null,
		childrens: [
			{
				label: "Employee",
				path: pathnames.userManagement.employees,
				name: "",
				relatives: [pathnames.userManagement.employee],
			},
			{
				label: "Amendment Status",
				path: pathnames.userManagement.amendmentStatus,
				name: "",
				relatives: [],
			},
			{
				label: "Admin List",
				path: pathnames.userManagement.admins,
				name: ROLES.OWNER,
				relatives: [],
			},
		],
		relatives: [],
	},
	{
		label: "Company Benefits",
		path: pathnames.companyBenefits.positions,
		icon: AppCompanyBenefitsIcon,
		names: null,
		childrens: [
			{
				label: "Job Position",
				path: pathnames.companyBenefits.positions,
				name: "",
				relatives: [],
			},
			{
				label: "Benefit Package",
				path: pathnames.companyBenefits.benefits,
				name: "",
				relatives: [pathnames.companyBenefits.benefit],
			},
		],
		relatives: [],
	},
	{
		label: "Leave Management",
		path: pathnames.leaveManagement.leaves,
		icon: AppLeaveManagementIcon,
		names: null,
		relatives: [],
		childrens: [
			{
				label: "Leave Calendar",
				path: pathnames.leaveManagement.leaves,
				name: "",
				relatives: [],
			},
			{
				label: "Leave Directory",
				path: pathnames.leaveManagement.leaveDirectory,
				name: "",
				relatives: [pathnames.leaveManagement.leave],
			},
			{
				label: "Pending Approval",
				path: pathnames.leaveManagement.leavesApproval,
				name: "",
				relatives: [],
			},
		],
	},
	{
		label: "Allowance Management",
		path: pathnames.claimManagement.claimDirectory,
		icon: AppClaimManagementIcon,
		names: null,
		relatives: [],
		childrens: [
			{
				label: "Allowance Directory",
				path: pathnames.claimManagement.claimDirectory,
				name: "",
				relatives: [pathnames.claimManagement.claim],
			},
			{
				label: "Pending Approval",
				path: pathnames.claimManagement.claimApproval,
				name: "",
				relatives: [],
			},
		],
	},
	{
		label: "Insurance Application",
		path: pathnames.orders,
		icon: AppOrdersIcon,
		names: null,
		relatives: [],
		childrens: [],
	},
	{
		label: "Insurance Coverage",
		path: pathnames.insuranceCoverage.policy,
		icon: AppInsurancenIcon,
		names: null,
		relatives: [],
		childrens: [
			{
				label: "Policy",
				path: pathnames.insuranceCoverage.policy,
				name: "",
				relatives: [],
			},
			{
				label: "Insurance Claim",
				path: pathnames.insuranceCoverage.claim,
				name: "",
				relatives: [pathnames.insuranceCoverage.selectClaimType, pathnames.insuranceCoverage.uploadClaimDocuments],
			},
			{
				label: "Manage Coverage",
				path: pathnames.insuranceCoverage.coverages,
				name: "",
				relatives: [],
			},
		],
	},
];

export default sideNavList;
