import React, { memo, forwardRef, useImperativeHandle, useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import COMMON from "common";

export const AppPositionModal = (props, ref) => {
	const dispatch = useDispatch();
	const [position, setPosition] = useState();
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ name: position?.label || "", description: position?.description || "" }), [position]);
	const buttonLabel = useMemo(() => (position ? "Save" : "Create"), [position]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});

	const onHandleShow = useCallback((data) => {
		setPosition(data);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		formik.setValues({ name: "", description: "" });
		setVisible(false);
	}, [formik]);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				label: values.name,
				description: values.description,
				status: COMMON.STATUS_ID.ACTIVE,
			};

			if (position) {
				payload.id = position.id;
				await api.post.positions.update(payload);
			} else {
				await api.post.positions.create(payload);
			}

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();
			dispatch(promptAlertMessage({ message: position ? "Position has been updated successfull" : "Position has been created successfull" }));
			props.onHandleGetList()
		}
	}, [dispatch, onHandleDismiss, position, formik, props]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-position-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="position-modal" aria-describedby="position-modal">
			<div className="position-modal">
				<div className="position-modal__main">
					<div className="position-modal__header">
						<p className="position-modal__title">Create Position</p>
					</div>

					<form className="position-modal__body" onSubmit={formik.handleSubmit}>
						{/* prettier-ignore */}
						<AppInput required type="text" name="name" label="Position Name" placeholder="Please enter Position Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput multiline multilineRow={4} maxLength={120} type="text" name="description" label="Description" placeholder="Please enter a description of the position" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						<div className="position-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label={buttonLabel} />
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPositionModal));

AppPositionModal.propTypes = {
	ref: PropTypes.object,
};
