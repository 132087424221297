import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import AppButton from "components/app-button";
import hero from "assets/images/components/pages/login/hero.png";

export const AppForgotPasswordModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-forgot-password" }} open={visible} aria-labelledby="forgot-password" aria-describedby="forgot-password-modal">
			<div className="forgot-password">
				<img className="forgot-password__hero" src={hero} alt="hero" />

				<div className="forgot-password__header">
					<p className="forgot-password__title">Reset Password</p>
					<p className="forgot-password__description">To enhance the security of your account, we recommend resetting your password using Easycover mobile app.</p>
				</div>

				<div className="forgot-password__button-container">
					<AppButton type="button" label="Okay" onClick={onHandleDismiss} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppForgotPasswordModal));

AppForgotPasswordModal.propTypes = {
	ref: PropTypes.object,
};
