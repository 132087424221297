import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getPositionListing = async (payload) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { page: payload?.page || 0, size: 10, position: payload?.keyword || "" };
		const res = await api.get.positions.list(params);
		const content = res.content.map(({ label, ...o }) => ({ label, value: label, ...o }));
		response = { ...res, content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getPositionListing;
