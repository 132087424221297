import COMMON from "common";
import services from "services";
import encodeQueryParams from "common/encode-query-params";

const ignoreAuthHeader = { token: false };
const multipart = { headers: { "Content-Type": "multipart/form-data" } };

const api = {
	get: {
		general: {
			logo: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.LOGO),
			company: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.COMPANY),
			companies: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.COMPANIES + encodeQueryParams(params)),
			profile: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.PROFILE, { cancelId: COMMON.ENDPOINT_PATH.GENERAL.PROFILE }),
			holiday: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.HOLIDAY + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.GENERAL.HOLIDAY }),
			states: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.STATES + encodeQueryParams(params)),
			cities: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.CITIES + encodeQueryParams(params)),
			postcode: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.POSTCODE + encodeQueryParams(params)),
			list: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.LIST + encodeQueryParams(params)),
			maintenance: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.MAINTENANCE, { baseURL: process.env.REACT_APP_URL, cancelId: COMMON.ENDPOINT_PATH.GENERAL.MAINTENANCE }),
		},
		dashboard: {
			admin: () => services.get(COMMON.ENDPOINT_PATH.DASHBOARD.ADMIN, { cancelId: COMMON.ENDPOINT_PATH.DASHBOARD.ADMIN }),
		},
		setup: {
			companies: () => services.get(COMMON.ENDPOINT_PATH.SETUP.COMPANIES, { cancelId: COMMON.ENDPOINT_PATH.SETUP.COMPANIES }),
			ageRequirement: () => services.get(COMMON.ENDPOINT_PATH.SETUP.AGE_REQUIREMENT),
			uploadedDocument: () => services.get(COMMON.ENDPOINT_PATH.SETUP.UPLOADED_DOCUMENT),
			purchaser: () => services.get(COMMON.ENDPOINT_PATH.SETUP.PURCHASER, { cancelId: COMMON.ENDPOINT_PATH.SETUP.PURCHASER }),
			employees: () => services.get(COMMON.ENDPOINT_PATH.SETUP.EMPLOYEES, { cancelId: COMMON.ENDPOINT_PATH.SETUP.EMPLOYEES }),
			documents: () => services.get(COMMON.ENDPOINT_PATH.SETUP.DOCUMENTS, { cancelId: COMMON.ENDPOINT_PATH.SETUP.DOCUMENTS }),
			totalEmployee: () => services.get(COMMON.ENDPOINT_PATH.SETUP.TOTAL_EMPLOYEE, { cancelId: COMMON.ENDPOINT_PATH.SETUP.TOTAL_EMPLOYEE }),
			employee: (params) => services.get(COMMON.ENDPOINT_PATH.SETUP.EMPLOYEE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.SETUP.EMPLOYEE }),
			coverages: (params) => services.get(COMMON.ENDPOINT_PATH.SETUP.COVERAGES + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.SETUP.COVERAGES }),
			utilizationInsurance: () => services.get(COMMON.ENDPOINT_PATH.SETUP.UTILIZATION_INSURANCE, { cancelId: COMMON.ENDPOINT_PATH.SETUP.UTILIZATION_INSURANCE }),
			insurancePlansList: () => services.get(COMMON.ENDPOINT_PATH.SETUP.INSURANCE_PLANS_LIST, { cancelId: COMMON.ENDPOINT_PATH.SETUP.INSURANCE_PLANS_LIST }),
		},
		admin: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.ADMIN.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ADMIN.LIST }),
			employeeList: () => services.get(COMMON.ENDPOINT_PATH.ADMIN.EMPLOYEE_LIST, { cancelId: COMMON.ENDPOINT_PATH.ADMIN.EMPLOYEE_LIST }),
		},
		benefits: {
			benefit: (params) => services.get(COMMON.ENDPOINT_PATH.BENEFITS.BENEFIT + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.BENEFITS.BENEFIT }),
			list: (params) => services.get(COMMON.ENDPOINT_PATH.BENEFITS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.BENEFITS.LIST }),
		},
		positions: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.POSITIONS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.POSITIONS.LIST }),
		},
		announcements: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.LIST }),
			upcoming: (params) => services.get(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.UPCOMING + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.UPCOMING }),
		},
		leaves: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.LIST }),
			leave: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.LEAVE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.LEAVE }),
			calendar: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.CALENDAR + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.CALENDAR }),
			upcoming: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.UPCOMING + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.UPCOMING }),
			summary: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.SUMMARY + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.SUMMARY }),
			pendingApprovalList: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.PENDING_APPROVAL_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.PENDING_APPROVAL_LIST }),
		},
		claims: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.LIST }),
			claim: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.CLAIM + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.CLAIM }),
			summary: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.SUMMARY + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.SUMMARY }),
			pendingApprovalList: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.PENDING_APPROVAL_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.PENDING_APPROVAL_LIST }),
		},
		employee: {
			organisation: () => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.ORGANISATION),
			ageRequirement: () => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.AGE_REQUIREMENT),
			insurancePlansList: () => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.INSURANCE_PLANS_LIST),
			reportingManagerList: (params) => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.REPORTING_MANAGER_LIST + encodeQueryParams(params)),
			list: (params) => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.EMPLOYEE.LIST }),
			personalInfo: (params) => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.PERSONAL_INFO + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.EMPLOYEE.PERSONAL_INFO }),
			employeeInfo: (params) => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.EMPLOYEE_INFO + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.EMPLOYEE.EMPLOYEE_INFO }),
			directoryList: (params) => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.DIRECTORY_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.EMPLOYEE.DIRECTORY_LIST }),
		},
		orders: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.ORDERS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ORDERS.LIST }),
			order: (params) => services.get(COMMON.ENDPOINT_PATH.ORDERS.ORDER + encodeQueryParams(params)),
			additionalDocuments: () => services.get(COMMON.ENDPOINT_PATH.ORDERS.ADDITIONAL_DOCUMENTS),
		},
		policies: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.POLICIES.LIST }),
			policy: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.POLICY + encodeQueryParams(params)),
			documents: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DOCUMENTS + encodeQueryParams(params)),
			insurer: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.INSURER + encodeQueryParams(params)),
			creditNote: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_NOTE + encodeQueryParams(params)),
			debitNote: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_NOTE + encodeQueryParams(params)),
			unpaidDebitNote: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.UNPAID_DEBIT_NOTE + encodeQueryParams(params)),
			unclaimedCreditAmount: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.UNCLAIMED_CREDIT_AMOUNT + encodeQueryParams(params)),
			unpaidDebitAmount: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.UNPAID_DEBIT_AMOUNT + encodeQueryParams(params)),
			refundCredit: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.REFUND_CREDIT + encodeQueryParams(params)),
			invoiceFiles: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.INVOICE_FILES + encodeQueryParams(params)),
			creditPaymentHistory: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_PAYMENT_HISTORY + encodeQueryParams(params)),
			creditPaymentReceipt: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_PAYMENT_HISTORY_RECEIPT + encodeQueryParams(params)),
			debitPaymentHistory: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_PAYMENT_HISTORY + encodeQueryParams(params)),
			debitPaymentReceipt: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_PAYMENT_HISTORY_RECEIPT + encodeQueryParams(params)),
			quotations: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.QUOTATIONS + encodeQueryParams(params)),
			reviseQuotationEmployees: () => services.get(COMMON.ENDPOINT_PATH.POLICIES.REVISE_QUOTATION_EMPLOYEES),
		},
		coverages: {
			policies: () => services.get(COMMON.ENDPOINT_PATH.COVERAGES.POLICIES),
			companies: () => services.get(COMMON.ENDPOINT_PATH.COVERAGES.COMPANIES, { cancelId: COMMON.ENDPOINT_PATH.COVERAGES.COMPANIES }),
			allClaimType: () => services.get(COMMON.ENDPOINT_PATH.COVERAGES.ALL_CLAIM_TYPE),
			claim: (params) => services.get(COMMON.ENDPOINT_PATH.COVERAGES.CLAIM + encodeQueryParams(params)),
			claimants: (params) => services.get(COMMON.ENDPOINT_PATH.COVERAGES.CLAIMANTS + encodeQueryParams(params)),
			claimDocuments: (params) => services.get(COMMON.ENDPOINT_PATH.COVERAGES.CLAIM_DOCUMENTS + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.COVERAGES.CLAIM_DOCUMENTS }),
			policyClaimType: (params) => services.get(COMMON.ENDPOINT_PATH.COVERAGES.POLICY_CLAIM_TYPE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.COVERAGES.POLICY_CLAIM_TYPE }),
			list: (params) => services.get(COMMON.ENDPOINT_PATH.COVERAGES.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.COVERAGES.LIST }),
			claims: (params) => services.get(COMMON.ENDPOINT_PATH.COVERAGES.CLAIMS + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.COVERAGES.CLAIMS }),
			insurancePlansList: () => services.get(COMMON.ENDPOINT_PATH.COVERAGES.INSURANCE_PLANS_LIST, { cancelId: COMMON.ENDPOINT_PATH.COVERAGES.INSURANCE_PLANS_LIST }),
			utilizationInsurance: () => services.get(COMMON.ENDPOINT_PATH.COVERAGES.UTILIZATION_INSURANCE, { cancelId: COMMON.ENDPOINT_PATH.COVERAGES.UTILIZATION_INSURANCE }),
		},
		amendmentRequest: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.LIST }),
			details: (params) => services.get(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.DETAILS + encodeQueryParams(params)),
		},
	},
	post: {
		general: {
			uploadCompanyLogo: (payload) => services.post(COMMON.ENDPOINT_PATH.GENERAL.UPLOAD_COMPANY_LOGO, payload, { ...multipart }),
			refreshToken: (payload) => services.post(COMMON.ENDPOINT_PATH.GENERAL.REFRESH_TOKEN, payload, { ...ignoreAuthHeader }),
		},
		authenticate: {
			signIn: (payload) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.SIGN_IN, payload),
			user: (params) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.USER + encodeQueryParams(params)),
		},
		setup: {
			submit: () => services.post(COMMON.ENDPOINT_PATH.SETUP.SUBMIT),
			invite: (payload) => services.post(COMMON.ENDPOINT_PATH.SETUP.INVITE, payload),
			delete: (payload) => services.post(COMMON.ENDPOINT_PATH.SETUP.DELETE, payload),
			updateCoverage: (payload) => services.post(COMMON.ENDPOINT_PATH.SETUP.UPDATE_COVERAGE, payload),
			upload: (payload, onUploadProgress) => services.post(COMMON.ENDPOINT_PATH.SETUP.UPLOAD, payload, { ...multipart, onUploadProgress }),
			updatePurchaser: (payload) => services.post(COMMON.ENDPOINT_PATH.SETUP.UPDATE_PURCHASER, payload),
		},
		admin: {
			removeRole: (payload) => services.post(COMMON.ENDPOINT_PATH.ADMIN.REMOVE_ADMIN, payload),
			assignRole: (payload) => services.post(COMMON.ENDPOINT_PATH.ADMIN.ASSIGN_ADMIN, payload),
		},
		benefits: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.BENEFITS.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.BENEFITS.UPDATE, payload),
		},
		positions: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.POSITIONS.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.POSITIONS.UPDATE, payload),
		},
		announcements: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.UPDATE, payload),
			delete: (params) => services.post(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.DELETE + encodeQueryParams(params)),
			upload: (params, payload, onUploadProgress) => services.post(COMMON.ENDPOINT_PATH.ANNOUNCEMENTS.UPLOAD + encodeQueryParams(params), payload, { ...multipart, onUploadProgress }),
		},
		employee: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.CREATE, payload),
			updatePersonalInfo: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.UPDATE_PERSONAL_INFO, payload),
			updateEmployeeInfo: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.UPDATE_EMPLOYEE_INFO, payload),
			rejectIntern: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.REJECT_INTERN, payload),
			rejectProbation: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.REJECT_PROBATION, payload),
			approveProbation: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.APPROVE_PROBATION, payload),
			reinviteTerminatedUser: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.REINVITE_TERMINATED_USER, payload),
		},
		leaves: {
			approve: (payload) => services.post(COMMON.ENDPOINT_PATH.LEAVES.APPROVE, payload),
			reject: (payload) => services.post(COMMON.ENDPOINT_PATH.LEAVES.REJECT, payload),
			cancel: (params) => services.post(COMMON.ENDPOINT_PATH.LEAVES.CANCEL + encodeQueryParams(params)),
			refresh: (params) => services.post(COMMON.ENDPOINT_PATH.LEAVES.REFRESH + encodeQueryParams(params)),
		},
		claims: {
			reject: (payload) => services.post(COMMON.ENDPOINT_PATH.CLAIMS.REJECT, payload),
			approve: (payload) => services.post(COMMON.ENDPOINT_PATH.CLAIMS.APPROVE, payload),
			cancel: (params) => services.post(COMMON.ENDPOINT_PATH.CLAIMS.CANCEL + encodeQueryParams(params)),
		},
		orders: {
			removeAdditionalDocument: (payload) => services.post(COMMON.ENDPOINT_PATH.ORDERS.DELETE_ADDITIONAL_DOCUMENT, payload),
			submitAdditionalDocument: () => services.post(COMMON.ENDPOINT_PATH.ORDERS.SUBMIT_ADDITIONAL_DOCUMENTS),
		},
		policies: {
			downloadQuotations: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DOWNLOAD_QUOTATIONS, payload, { responseType: "arraybuffer", ...headers }),
			downloadDocuments: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DOWNLOAD_DOCUMENTS, payload, { responseType: "arraybuffer", ...headers }),
			downloadInvoices: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DOWNLOAD_INVOICES, payload, { responseType: "arraybuffer", ...headers }),
			downloadDebitReceipt: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_DOWNLOAD_RECEIPT, payload, { responseType: "arraybuffer", ...headers }),
			downloadCreditReceipt: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_DOWNLOAD_RECEIPT, payload, { responseType: "arraybuffer", ...headers }),
			debitNoteInvoicePayment: (payload) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_NOTE_INVOICE_PAYMENT, payload),
			rejectPolicyRenewal: (payload) => services.post(COMMON.ENDPOINT_PATH.POLICIES.REJECT_POLICY_RENEWAL, payload),
			uploadPolicyRenewal: (params, payload, onUploadProgress) => services.post(COMMON.ENDPOINT_PATH.POLICIES.UPLOAD_POLICY_RENEWAL + encodeQueryParams(params), payload, { ...multipart, onUploadProgress }),
			renewalPayment: (payload) => services.post(COMMON.ENDPOINT_PATH.POLICIES.RENEWAL_PAYMENT, payload),
			reviseQuotation: (payload) => services.post(COMMON.ENDPOINT_PATH.POLICIES.REVISE_QUOTATION, payload),
		},
		coverages: {
			submitClaim: (payload) => services.post(COMMON.ENDPOINT_PATH.COVERAGES.SUBMIT_CLAIM, payload),
			updateInsurerCoverage: (payload) => services.post(COMMON.ENDPOINT_PATH.COVERAGES.UPDATE_INSURER_COVERAGE, payload),
			uploadClaim: (params, payload, onUploadProgress) => services.post(COMMON.ENDPOINT_PATH.COVERAGES.UPLOAD_CLAIM + encodeQueryParams(params), payload, { ...multipart, onUploadProgress }),
			uploadAdditionalClaimDocument: (params, payload, onUploadProgress) => services.post(COMMON.ENDPOINT_PATH.COVERAGES.UPLOAD_ADDITIONAL_CLAIM_DOCUMENT + encodeQueryParams(params), payload, { ...multipart, onUploadProgress }),
			downloadClaimDocuments: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.COVERAGES.DOWNLOAD_CLAIM_DOCUMENTS, payload, { responseType: "arraybuffer", ...headers }),
		},
		amendmentRequest: {
			submit: (payload) => services.post(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.SUBMIT, payload),
			upload: (params, payload, onUploadProgress) => services.post(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.UPLOAD + encodeQueryParams(params), payload, { ...multipart, onUploadProgress }),
		},
	},
	patch: {},
	put: {},
};

export default api;
