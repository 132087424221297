const sanitizeError = (error = "") => {
	const response = error?.response?.data;
	if (response?.exceptionMessage) {
		return response.exceptionMessage;
	} else if (error?.message) {
		const isCancelled = error.message === "canceled";

		if (isCancelled) return "";

		return error.message;
	} else {
		return error;
	}
};

export default sanitizeError;
