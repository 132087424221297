import React, { useRef, useCallback, useMemo, useEffect, useState, useContext, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";
import queryParamsEntries from "common/query-params-entries";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import viewIcon from "assets/images/view-icon.svg";

const PageClaimDirectory = () => {
	const navigate = useNavigate();
	const searchInputRef = useRef();
	const context = useContext(AxiosContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
		name: searchParams.get("name") || "",
		employeeId: searchParams.get("employeeId") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);
	const initialValues = useMemo(() => ({ employeeId: "", name: "" }), []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { name: params.name, employeeId: params.employeeId };

		return values;
	}, [searchParams]);

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.employee.directoryList(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEdit = useCallback((original) => {
		navigate(pathnames.claimManagement.claim + original.userId, { state: original });
	}, [navigate]);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={viewIcon} onClick={() => onHandleEdit(row.original)} />;
	}, [onHandleEdit]);

	const EmployeeNameCell = useCallback(({ row }) => {
		return <AppTableCell left value={row.original.name || "-"} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Employee ID",
			accessor: "employeeId",
			disableSortBy: false,
		},
		{
			Header: "User  Name",
			accessor: "p.name",
			disableSortBy: false,
			Cell: EmployeeNameCell
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [EmployeeNameCell, MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.EMPLOYEE.DIRECTORY_LIST);
		};
	}, [context]);

	return (
		<div className="page-claim-directory">
			<div className="claim-directory">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="employeeId" label="Employee ID" placeholder="Enter employee ID" value={formik.values.employeeId} onChange={formik.handleChange} />

										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter employee name" value={formik.values.name} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>

						{(paramsRef.current.employeeId || paramsRef.current.name) && (
							<div className="claim-directory__results">
								<p className="claim-directory__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default PageClaimDirectory;
