import { redirect } from "react-router-dom";

import COMMON from "common";
import store from "store/store";
import ROLES from "common/roles";
import pathnames from "routes/pathnames";

export const protectedRoutes = ({ request, params }) => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

	const direction = onHandlePermissionRoutes(request, params);

	if (direction) {
		return redirect(direction);
	} else if (token) {
		return true;
	} else {
		return redirect(pathnames.login);
	}
};

export const publicRoutes = () => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

	if (token) {
		return redirect(pathnames.dashboard);
	} else {
		const profile = store.getState()?.profile;
		if (profile.keycloakId) store.dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
		return true;
	}
};

const onHandlePermissionRoutes = (request, params) => {
	const profile = store.getState()?.profile;
	const isIncompletedSetupCompany = profile.company?.setupFlag === COMMON.EMPLOYEE.SETUP.INCOMPLETE;
	const isCompletedSetupCompany = profile.company?.setupFlag === COMMON.EMPLOYEE.SETUP.COMPLETED;
	const isOrderInCompletedCompany = profile.company?.orderStatus !== COMMON.EMPLOYEE.SETUP.ORDER_COMPLETED;

	const id = params?.id;
	const url = new URL(request.url);
	let sanitizedUrl = new URL(url);

	if (id !== undefined && sanitizedUrl) {
		sanitizedUrl = sanitizedUrl.pathname.split("/");
		sanitizedUrl.pop();
		sanitizedUrl = sanitizedUrl.join("/");
	} else {
		sanitizedUrl = sanitizedUrl.pathname;
	}

	const getSystemPermissionAccess = (type) => {
		const role = profile?.companyRoles?.[0]?.roleNameEn?.toLowerCase();
		return role === type;
	};

	if (isIncompletedSetupCompany) {
		/* If incomplete setup redirect to setup */
		switch (sanitizedUrl) {
			case pathnames.setup.setup:
			case pathnames.setup.setupCoverages:
				return false;
			default:
				return pathnames.setup.setup;
		}
	} else if (isOrderInCompletedCompany && isCompletedSetupCompany && sanitizedUrl !== pathnames.orders) {
		/* If completed setup & order not completed redirect to orders */
		return pathnames.orders;
	} else if (isCompletedSetupCompany && sanitizedUrl === pathnames.setup.setup) {
		/* If completed setup redirect to dashboard */
		return pathnames.dashboard;
	} else {
		let found = false;

		switch (sanitizedUrl) {
			case pathnames.userManagement.admins:
				found = getSystemPermissionAccess(ROLES.OWNER);
				break;
			default:
				return found;
		}
		
		if (!found) throw new Response("Not Found", { status: 403 });
	}
};
