import React, { memo, forwardRef, useImperativeHandle, useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";

export const AppRejectReasonModal = (props, ref) => {
	const dispatch = useDispatch();
	const [ids, setIds] = useState();
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ rejectReason: "" }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			rejectReason: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});

	const onHandleShow = useCallback((data) => {
		setIds(data);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				ids: ids,
				rejectReason: values.rejectReason,
			};

			await api.post.claims.reject(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();
			dispatch(promptAlertMessage({ message: "Claim has been rejected successfully" }));
			props.onHandleGetList()
		}
	}, [dispatch, onHandleDismiss, ids, formik, props]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-claim-reject-reason-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="reject-reason-modal" aria-describedby="reject-reason-modal">
			<div className="reject-reason-modal">
				<div className="reject-reason-modal__main">
					<div className="reject-reason-modal__header">
						<p className="reject-reason-modal__title">Reason for Leave Rejection</p>
						<p className="reject-reason-modal__description">Reason for Leave Rejection</p>
					</div>

					<form className="reject-reason-modal__body" onSubmit={formik.handleSubmit}>
						{/* prettier-ignore */}
						<AppInput required multiline multilineRow={4} maxLength={255} type="text" name="rejectReason" label="Reject Reason" placeholder="Enter reject reason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						<div className="reject-reason-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Confirm" />
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppRejectReasonModal));

AppRejectReasonModal.propTypes = {
	ref: PropTypes.object,
};
