import { createSlice } from "@reduxjs/toolkit";
import sanitizeError from "common/sanitize-error";

const initialState = {
	messages: "",
	code: "",
	error: false,
};

export const alertSlice = createSlice({
	name: "alert",
	initialState,
	reducers: {
		promptAlertMessage: (state, action) => {
			const payload = action.payload?.message || "";
			const message = sanitizeError(payload);

			return { message, error: !!action.payload?.error, code: action.payload?.code };
		},
		dismissAlertMessage: () => initialState,
	},
});

export const { promptAlertMessage, dismissAlertMessage } = alertSlice.actions;

export default alertSlice.reducer;
