import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getBenefitListing = async (payload) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { page: payload?.page || 0, size: 10, sort: "name,asc", status: 1, name: payload?.keyword || "" };
		const res = await api.get.benefits.list(params);
		const content = res.content.map(({ name, id, ...o }) => ({ label: name, value: id.toString(), ...o }));
		response = { ...res, content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getBenefitListing;
