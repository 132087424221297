import React, { useRef, useCallback, useEffect, useState, useMemo, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import converReadableFileSize from "common/convert-readable-file-size";
import AppButton from "components/app-button";
import AppDownloadClaimDocumentModal from "components/pages/insurance-coverage/app-download-claim-document-modal";
import removeIcon from "assets/images/remove-icon.svg";
import downloadIcon from "assets/images/download-icon.svg";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";

const PageUploadClaimDocuments = (props) => {
	const downloadClaimDocumentModalRef = useRef();
	const uploadInputRef = useRef({});
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [uploadProgress, setUploadProgress] = useState({});
	const [claimDocuments, setClaimDocuments] = useState({});
	const [uploadedDocuments, setUploadedDocuments] = useState([]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const missingMandatoryDocument = useMemo(() => {
		const mandatoryIds = [];

		Object.keys(claimDocuments).forEach((a) => {
			claimDocuments[a].forEach((b) => {
				const isMandatory = b.mandatory === "Y";

				if (isMandatory) mandatoryIds.push(b.id);
			});
		});

		const mandatoryFields = mandatoryIds.filter((a) => uploadedDocuments.findIndex((b) => a.toString() === b.id) < 0);

		return mandatoryFields.length > 0;
	}, [uploadedDocuments, claimDocuments]);

	const disabled = useMemo(() => missingMandatoryDocument || !uploadedDocuments.length || isSubmiting, [missingMandatoryDocument, uploadedDocuments, isSubmiting]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.insuranceCoverage.claim);
	}, [navigate]);

	const onHandleDownloadClaimDocument = () => {
		downloadClaimDocumentModalRef.current.onHandleShow(claimDocuments);
	};

	const onHandleUploadDocument = (event) => {
		const type = event.currentTarget.getAttribute("data-type");
		uploadInputRef.current[type].click();
	};

	const onHandleRemoveDocument = (obj) => {
		setUploadedDocuments((prev) => {
			//prettier-ignore
			const filtered = prev.map((o) => {
				if (obj.name !== o.name && o.id !== obj.id) return o;
				else if (obj.name === o.name && o.id !== obj.id) return o;
				else if (obj.name !== o.name && o.id === obj.id) return o;
				else return null;
			}).filter(Boolean);

			return filtered;
		});
	};

	const onHandleUploadDocumentChange = (event) => {
		const file = event.target.files[0];
		const id = event.target.name;
		const files = [...uploadedDocuments];

		const isValidFileSize = validateFileSize(file, COMMON.MAX_FILE_SIZES.MB_15);

		if (!isValidFileSize) return serveRequestErrors(ERRORS.DOCUMENTS_SIZE_15MB);

		uploadInputRef.current[id].value = "";

		file.id = id;

		const duplicated = files.findIndex((o) => o.id === id && o.name === file.name) > -1;

		if (!duplicated) files.push(file);

		setUploadedDocuments(files);
	};

	const onHandleGetClaimDocuments = useCallback(async (state) => {
		let response = null;

		try {
			const payload = state.claimType + "/" + state.claimant;
			response = await api.get.coverages.claimDocuments(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			let documents = {};

			response.forEach((o) => {
				if (!documents[o.category]) documents[o.category] = [];
				documents[o.category].push(o);
			});

			setClaimDocuments(documents);
		}
	}, []);

	//prettier-ignore
	const onHandleUploadClaimDocuments = useCallback(async (obj, index = 0) => {
		const uploadProgressKey = `${uploadedDocuments[index].id}_${uploadedDocuments[index].name}`;

		let response = null;

		const onUploadProgress = (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

			setUploadProgress((prev) => ({ ...prev, [uploadProgressKey]: percentCompleted }));
		};

		try {
			const formData = new FormData();
			
			const params = obj.id + "/" + uploadedDocuments[index].id;

			formData.append("file", uploadedDocuments[index]);

			await api.post.coverages.uploadClaim(params, formData, onUploadProgress);

			response = true;
		} catch (error) {
			setIsSubmiting(false);
			serveRequestErrors(error);
		} finally {
			setUploadProgress((prev) => ({ ...prev, [uploadProgressKey]: 0 }));
		}

		const nextIndex = index + 1;

		if(response && uploadedDocuments[nextIndex]) {
			onHandleUploadClaimDocuments(obj, nextIndex);
		}
		else {
			setIsSubmiting(false);

			dispatch(promptAlertMessage({ message: "Claim has been submit successfully" }));

			navigate(pathnames.insuranceCoverage.claim);
		}
		
	}, [uploadedDocuments, navigate, dispatch]);

	const onHandleSubmit = useCallback(async () => {
		setIsSubmiting(true);

		let response = null;

		try {
			const payload = {
				orderId: location.state.policyNo,
				ebClaimTypeId: location.state.claimType,
				companyEmployeeDetailId: location.state.claimant,
			};

			response = await api.post.coverages.submitClaim(payload);
		} catch (error) {
			setIsSubmiting(false);
			serveRequestErrors(error);
		}

		if (response) onHandleUploadClaimDocuments(response);
	}, [location.state, onHandleUploadClaimDocuments]);

	useEffect(() => {
		if (location.state) onHandleGetClaimDocuments(location.state);
	}, [location.state, onHandleGetClaimDocuments]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.COVERAGES.CLAIM_DOCUMENTS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-upload-claim-documents">
			<div className="upload-claim-documents">
				<div className="upload-claim-documents__card">
					<div className="upload-claim-documents__section">
						<h1 className="upload-claim-documents__title">Download & Guideline</h1>
						<p className="upload-claim-documents__description">You are required to download and upload the all the required document.</p>

						<div className="upload-claim-documents__section-button">
							<AppButton type="button" label="Download" icon={downloadIcon} onClick={onHandleDownloadClaimDocument} />
						</div>
					</div>

					<div className="upload-claim-documents__section">
						<h1 className="upload-claim-documents__title">Additional Required Documents</h1>

						<ul className="upload-claim-documents__list">
							{claimDocuments[COMMON.INSURANCE.CLAIMS.DOCUMENTS.ADDITIONAL_REQUIRED_DOC]?.map((o, i) => {
								return (
									<li className="upload-claim-documents__item" key={i}>
										<p className="upload-claim-documents__description"> • {o.label}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="upload-claim-documents__section">
						<h1 className="upload-claim-documents__title">Upload Documents</h1>
						<p className="upload-claim-documents__description">Please ensure that the document is in the correct format (jpg, pdf) with file size not exceeding 10MB.</p>

						<div className="upload-claim-documents__body">
							{Object.keys(claimDocuments).map((a) => {
								const documents = claimDocuments[a];

								return documents.map((b) => {
									const documentId = b.id.toString();
									const uploadedItems = uploadedDocuments.filter((o) => o.id.toString() === documentId);
									const maxLength = uploadedItems.length >= 5;
									const isMandatory = b.mandatory === "Y";

									return (
										<Fragment key={b.label}>
											<div className="upload-claim-documents__list-header">
												<p className="upload-claim-documents__label">
													{b.label} {isMandatory && <span className="upload-claim-documents__asterisk">*</span>}
												</p>
												<p className="upload-claim-documents__label"></p>
												<p className="upload-claim-documents__label"></p>
											</div>

											{uploadedItems.map((c, i) => {
												const progressStatusKey = `${c.id}_${c.name}`;
												const progressStatus = uploadProgress[progressStatusKey];

												return (
													<div className="upload-claim-documents__input" key={i}>
														<p className="upload-claim-documents__text">{c.name}</p>
														<p className="upload-claim-documents__text">{converReadableFileSize(c.size, true)}</p>
														<button className="upload-claim-documents__delete" onClick={() => onHandleRemoveDocument(c)}>
															<img src={removeIcon} alt="remove" />
														</button>

														<div className="upload-claim-documents__progress-bar" style={{ width: `${progressStatus || 0}%` }} />
													</div>
												);
											})}

											{!maxLength && (
												<div className="upload-claim-documents__input">
													<button type="button" className="upload-claim-documents__upload-button" data-type={documentId} onClick={onHandleUploadDocument}>
														<p className="upload-claim-documents__upload">Uploaded</p>
														<UploadIcon />
													</button>
												</div>
											)}

											{/* prettier-ignore */}
											<input type="file" name={documentId} accept="image/jpeg, image/jpg, image/png, .pdf, .doc, .docx" hidden ref={(ref) => (uploadInputRef.current[documentId] = ref)} onChange={onHandleUploadDocumentChange} />
										</Fragment>
									);
								});
							})}
						</div>
					</div>
				</div>

				<div className="upload-claim-documents__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleCancel} />
					<AppButton type="button" label="Next" onClick={onHandleSubmit} disabled={disabled} />
				</div>
			</div>

			<AppDownloadClaimDocumentModal ref={downloadClaimDocumentModalRef} documents={claimDocuments[COMMON.INSURANCE.CLAIMS.DOCUMENTS.BASIC_REQUIRED_DOC]} />
		</div>
	);
};

export default PageUploadClaimDocuments;
