import React, { useRef, useCallback, useMemo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { setProfile } from "store/slices/profile";
import sanitizeObject from "common/sanitize-object";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppSelectInput from "components/app-select-input";
import AppTable, { AppTableCell } from "components/app-table";
import AppInsurancePlanIcon from "components/icons/app-insurance-plan-icon";
import exclamationIcon from "assets/images/exclamation-icon.svg";

const PageSetupCoverages = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const [anchorEl, setAnchorEl] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [insurancePlanOptions, setInsurancePlanOptions] = useState([]);
	const [utilizationInsurance, setUtilizationInsurance] = useState([]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const [filterBy, setFilterBy] = useState({ [COMMON.INSURANCE.PLANS.PREMIER]: false, [COMMON.INSURANCE.PLANS.REGULAR_PLUS]: false, [COMMON.INSURANCE.PLANS.REGULAR]: false, [COMMON.INSURANCE.PLANS.NOT_COVERED]: false });
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", ebInsurancePlan: searchParams.get("ebInsurancePlan") || "all" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleOpenMenu = useCallback((event) => {
		setAnchorEl(event.target);
	}, []);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.setup.coverages(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}
		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleGetInsurancePlanList = useCallback(async () => {
		let response = null;
		let insuranceResponse = null;

		try {
			response = await api.get.setup.utilizationInsurance();
			insuranceResponse = await api.get.setup.insurancePlansList();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setUtilizationInsurance(response);
		}

		if (insuranceResponse) {
			const options = response.map(({ planName, internalCode, ...o }) => ({ ...o, label: planName, value: internalCode }));
			options.push({ level: 0, quantity: 0, label: "Not Covered", value: COMMON.INSURANCE.PLANS.NOT_COVERED });
			setInsurancePlanOptions(options);
		}
	}, []);

	const onHandleUpdateCoverage = useCallback(async (obj) => {
		let response = null;

		const newEbInsurancePlan = obj.newEbInsurancePlan === COMMON.INSURANCE.PLANS.NOT_COVERED ? null : obj.newEbInsurancePlan || obj.ebInsurancePlan;

		const payload = { employeeDetailId: obj.id, newEbInsurancePlan, businessRegNo: obj.businessRegNo };

		try {
			response = await api.post.setup.updateCoverage(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				items: prev.items.map((o) => {
					if (o.id === payload.employeeDetailId) {
						return { ...o, ebInsurancePlan: payload.newEbInsurancePlan, businessRegNo: payload.businessRegNo };
					} else {
						return o;
					}
				}),
			}));

			setUtilizationInsurance(response);
		}
	}, []);

	const onHandleSubmit = async () => {
		const invalidUtilizationCoverage = utilizationInsurance.find((o) => o.balance < 0 || o.balance !== 0);

		if (invalidUtilizationCoverage) return serveRequestErrors("The number of eligible employees for coverage added does not align with the purchased plan. Please review and adjust accordingly");

		let response = null;

		setSubmitting(true);

		try {
			await api.post.setup.submit();
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setSubmitting(false);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "Sent all necessary information and documents to the provider for review" }));

			dispatch(setProfile({ ...profile, company: { ...profile.company, setupFlag: COMMON.EMPLOYEE.SETUP.COMPLETED } }));

			navigate(pathnames.orders);
		}
	};

	const onHandleGetCompanies = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.setup.companies();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const list = response.map((o) => ({ ...o, label: o.name, value: o.businessRegistrationNo }));
			
			setCompanies(list);
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleFilterBy = useCallback((event) => {
			const name = event.target.name;

			setFilterBy((prev) => {
				const next = { ...prev };

				Object.keys(next).forEach((o) => (next[o] = false));

				next[name] = !prev[name];

				if (!next[name]) {
					/* if current filterBy is false return to all */
					paramsRef.current.ebInsurancePlan = "all";
				} else if (name === COMMON.INSURANCE.PLANS.NOT_COVERED) {
					/* if all filterBy is not covered return to empty */
					paramsRef.current.ebInsurancePlan = "";
				} else {
					/* if all filterBy is true return to current [name] */
					paramsRef.current.ebInsurancePlan = name;
				}

				return next;
			});

			onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		switch (id) {
			case "organisation":
                paramsRef.current.sort = order ? "c.name," + order : "";
                break;
            case "name":
                paramsRef.current.sort = order ? "p.name," + order : "";
                break;
			default:
				paramsRef.current.sort = order ? id + "," + order : "";
				break;
		}

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	const EligbleInsuranceCell = useCallback(({ row }) => !row.original.isAgeEligible && <AppTableCell className="table__note" center value="" onMouseOver={onHandleOpenMenu} />, [onHandleOpenMenu]);

	//prettier-ignore
	const EbInsurancePlanCell = useCallback(({ row }) => {
        const optionValue = row.original?.ebInsurancePlan || COMMON.INSURANCE.PLANS.NOT_COVERED;

        const onSelectInsurancePlan = (event) => {
            const value = event.target.value;
			onHandleUpdateCoverage({ ...row.original, newEbInsurancePlan: value });
        }

        return <AppSelectInput type="text" name="insurancePlan" placeholder="Please Select" options={insurancePlanOptions} value={optionValue} onChange={onSelectInsurancePlan} disabled={!row.original.isAgeEligible} />;
    }, [insurancePlanOptions, onHandleUpdateCoverage]);

	//prettier-ignore
	const OrganisationCell = useCallback(({ row }) => {

		const onHandleSelectOrganisation = (event) => {
			const value = event.target.value;
			onHandleUpdateCoverage({ ...row.original, businessRegNo: value });
        }

		
		return <AppSelectInput searchable={false} type="text" name="businessRegNo" placeholder="Please Select" options={companies} value={row.original.businessRegNo} onChange={onHandleSelectOrganisation} />;
    }, [companies, onHandleUpdateCoverage]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "NRIC/Passport",
			accessor: "identity",
			disableSortBy: true,
		},
		{
			Header: "Employee Name",
			accessor: "name",
			disableSortBy: false,
		},
		{
			Header: "Level",
			accessor: "level",
			disableSortBy: false,
		},
		{
			Header: "Organisation",
			accessor: "organisation",
			disableSortBy: false,
			Cell: OrganisationCell
		},
		{
			Header: "Insurance Plan",
			accessor: "ebInsurancePlan",
			disableSortBy: false,
            Cell: EbInsurancePlanCell
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: EligbleInsuranceCell
		},
	], [EligbleInsuranceCell, EbInsurancePlanCell, OrganisationCell]);

	const Plans = useCallback((obj) => {
		return obj.utilizationInsurance.map((a) => {
			const className = classNames({
				"setup-coverages__plan": true,
				"setup-coverages__plan--premier": a.internalCode === COMMON.INSURANCE.PLANS.PREMIER,
				"setup-coverages__plan--regular-plus": a.internalCode === COMMON.INSURANCE.PLANS.REGULAR_PLUS,
				"setup-coverages__plan--regular": a.internalCode === COMMON.INSURANCE.PLANS.REGULAR,
			});
			const classNameWrapper = classNames({
				"setup-coverages__wrapper": true,
				"setup-coverages__wrapper--premier": a.internalCode === COMMON.INSURANCE.PLANS.PREMIER,
				"setup-coverages__wrapper--regular-plus": a.internalCode === COMMON.INSURANCE.PLANS.REGULAR_PLUS,
				"setup-coverages__wrapper--regular": a.internalCode === COMMON.INSURANCE.PLANS.REGULAR,
			});

			return (
				<div className={className} key={a.internalCode}>
					<div className={classNameWrapper}>
						<AppInsurancePlanIcon />
						<p className="setup-coverages__text">{a.planName}</p>
					</div>
					<p className="setup-coverages__quantity">
						{a.total - a.balance}
						<span className="setup-coverages__total">/ {a.total}</span>
					</p>
				</div>
			);
		});
	}, []);

	const CheckboxLabel = useCallback((obj) => {
		const className = classNames({
			"setup-coverages__label": true,
			"setup-coverages__label--premier": obj.code === COMMON.INSURANCE.PLANS.PREMIER,
			"setup-coverages__label--regular-plus": obj.code === COMMON.INSURANCE.PLANS.REGULAR_PLUS,
			"setup-coverages__label--regular": obj.code === COMMON.INSURANCE.PLANS.REGULAR,
		});

		return <span className={className}>{obj.label}</span>;
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		onHandleGetCompanies();
	}, [onHandleGetCompanies]);

	useEffect(() => {
		onHandleGetInsurancePlanList();
	}, [onHandleGetInsurancePlanList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.COVERAGES);
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.COMPANIES);
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.INSURANCE_PLANS_LIST);
			cancelRequest(COMMON.ENDPOINT_PATH.SETUP.UTILIZATION_INSURANCE);
		};
	}, [cancelRequest]);

	return (
		<div className="page-setup-coverages">
			<div className="setup-coverages">
				<div className="setup-coverages__header">
					<h1 className="setup-coverages__title">Coverage Allocation for Employees</h1>
					<p className="setup-coverages__description">Allocate specific insurance plans to employees to ensure comprehensive coverage</p>
				</div>

				<div className="setup-coverages__plans">
					<Plans utilizationInsurance={utilizationInsurance} />
				</div>

				<div className="setup-coverages__filter">
					<p className="setup-coverages__filter-text">Filter by</p>

					<div className="setup-coverages__checkboxs">
						{utilizationInsurance.map((o) => {
							return <AppCheckbox key={o.internalCode} onClick={onHandleFilterBy} label={<CheckboxLabel label={o.planName} code={o.internalCode} />} name={o.internalCode} value={filterBy[o.internalCode]} />;
						})}
						<AppCheckbox onClick={onHandleFilterBy} label={<CheckboxLabel label="Not Covered" code={COMMON.INSURANCE.PLANS.NOT_COVERED} />} name={COMMON.INSURANCE.PLANS.NOT_COVERED} value={filterBy[COMMON.INSURANCE.PLANS.NOT_COVERED]} />
					</div>
				</div>

				<AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />

				<Menu classes={{ root: "app-table-menu app-coverages-menu" }} anchorEl={anchorEl} open={!!anchorEl} anchorOrigin={{ vertical: "center", horizontal: "right" }} transformOrigin={{ vertical: "center", horizontal: "right" }}>
					<MenuItem onMouseLeave={onhandleCloseMenu}>
						<img className="app-coverages-menu__icon" src={exclamationIcon} alt="exclamation" />
						Not eligible to cover insurance according to age criteria set by provider
					</MenuItem>
				</Menu>

				<div className="setup-coverages__footer">
					<AppButton type="button" label="Submit" onClick={onHandleSubmit} disabled={submitting} />
				</div>
			</div>
		</div>
	);
};

export default PageSetupCoverages;
