const classNames = (conditions) => {
	const classes = [];

	Object.keys(conditions).forEach((key) => {
		const condition = conditions[key];
		if (condition) classes.push(key);
	});

	return classes.join(" ");
};

export default classNames;
