import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo, Fragment, useRef } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import formatStringPattern from "common/format-string-pattern";
import converReadableFileSize from "common/convert-readable-file-size";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";
import AppUploadAdditionalClaimDocumentsModal from "components/pages/insurance-coverage/app-upload-additional-claim-documents-modal";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";

const TAB = {
	SUMMARY: "SUMMARY",
	REMARKS: "REMARKS",
	UPLOADED_DOCUMENTS: "UPLOADED_DOCUMENTS",
	CLAIM_SETTLEMENT_DOCUMENT: "CLAIM_SETTLEMENT_DOCUMENT",
};

const TABS = [
	{ label: "Summary", value: TAB.SUMMARY },
	{ label: "Uploaded Documents", value: TAB.UPLOADED_DOCUMENTS },
	{ label: "Claim Settlement Document", value: TAB.CLAIM_SETTLEMENT_DOCUMENT },
	{ label: "Remarks", value: TAB.REMARKS },
];

export const AppInsuranceClaimModal = (props, ref) => {
	const uploadAdditionalDocumentRef = useRef();
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS[0].value);
	const isRequiredAddinalDocument = useMemo(() => COMMON.STATUS_ID.DEFERMENT === data.status, [data.status]);

	const claimInfo = useMemo(() => {
		const info = [
			{ label: "Claim Ref. No.", value: data.refNo || "-" },
			{ label: "Policy No.", value: data.policyNo || "-" },
			{ label: "NRIC/Passport", value: data.nric ? formatStringPattern(data.nric, COMMON.MASKING.NRIC) : data.passport ? data.passport : "-" },
			{ label: "Type of Claim", value: data.claimType || "-" },
		].filter((o) => o.label);

		return info;
	}, [data]);

	const onHandleShow = useCallback((obj) => {
		setTab(TABS[0].value);

		setData(obj);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	const onHandleUploadAdditionalDocuments = useCallback(() => {
		onHandleDismiss();
		uploadAdditionalDocumentRef.current.onHandleShow(data);
	}, [data, onHandleDismiss]);

	const Summary = useCallback((obj) => {
		if (obj.tab !== TAB.SUMMARY) return null;

		return (
			<div className="insurance-claim-modal__body">
				<div className="insurance-claim-modal__wrapper">
					{obj.claimInfo.map((a, i) => {
						return (
							<div className="insurance-claim-modal__info" key={i}>
								<p className="insurance-claim-modal__label">{a.label}</p>
								<p className="insurance-claim-modal__value">{a.value || "-"}</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	}, []);

	const Remarks = useCallback((obj) => {
		if (obj.tab !== TAB.REMARKS) return null;

		return (
			<div className="insurance-claim-modal__body">
				<p className="insurance-claim-modal__value">{obj.remarks || "-"}</p>
			</div>
		);
	}, []);

	const UploadedDocuments = useCallback((obj) => {
		if (obj.tab !== TAB.UPLOADED_DOCUMENTS) return null;

		if (!obj.documents?.length) {
			return (
				<div className="insurance-claim-modal__body">
					<p className="insurance-claim-modal__value">No Records</p>
				</div>
			);
		}

		return (
			<div className="insurance-claim-modal__body">
				<div className="insurance-claim-modal__docs-header">
					<p className="insurance-claim-modal__label">Uploaded</p>
					<p className="insurance-claim-modal__label">File Size</p>
				</div>

				<ul className="insurance-claim-modal__documents">
					{obj.documents?.map((a, i) => {
						return (
							<li className="insurance-claim-modal__item" key={i}>
								<p className="insurance-claim-modal__label">
									<a className="insurance-claim-modal__link" href={a.filePath} target="_blank" rel="noreferrer">
										{a.fileName}
									</a>
								</p>
								<p className="insurance-claim-modal__label">{converReadableFileSize(a.fileSize, true)}</p>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}, []);

	const ClaimSettlementDocuments = useCallback((obj) => {
		if (obj.tab !== TAB.CLAIM_SETTLEMENT_DOCUMENT) return null;

		if (!obj.documents?.length) {
			return (
				<div className="insurance-claim-modal__body">
					<p className="insurance-claim-modal__value">No Records</p>
				</div>
			);
		}

		return (
			<div className="insurance-claim-modal__body">
				<div className="insurance-claim-modal__docs-header">
					<p className="insurance-claim-modal__label">Uploaded</p>
					<p className="insurance-claim-modal__label">File Size</p>
				</div>

				<ul className="insurance-claim-modal__documents">
					{obj.documents?.map((a, i) => {
						return (
							<li className="insurance-claim-modal__item" key={i}>
								<p className="insurance-claim-modal__label">
									<a className="insurance-claim-modal__link" href={a.filePath} target="_blank" rel="noreferrer">
										{a.fileName}
									</a>
								</p>
								<p className="insurance-claim-modal__label">{converReadableFileSize(a.fileSize, true)}</p>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				response = await api.get.coverages.claim(data.id);
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Fragment>
			<Modal classes={{ root: "app-insurance-claim-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="insurance-claim-modal" aria-describedby="insurance-claim-modal">
				<div className="insurance-claim-modal">
					<div className="insurance-claim-modal__main">
						<button className="insurance-claim-modal__close-button" onClick={onHandleDismiss}>
							<AppCloseIcon color="#666666" />
						</button>

						<div className="insurance-claim-modal__header">
							<div className="insurance-claim-modal__profile">
								<p className="insurance-claim-modal__title">{data.refNo}</p>
								<p className="insurance-claim-modal__description">Claim Submission Date: {formatDatePattern(data.createdDate)}</p>
							</div>
						</div>

						<div className="info">
							<div className="info__box">
								<p className="insurance-claim-modal__text">Employee Name</p>
								<p className="insurance-claim-modal__title">{data.name}</p>
							</div>
							<div className="info__box">
								<p className="insurance-claim-modal__text">Status</p>
								<div className="insurance-claim-modal__title">
									<AppStatus status={data?.status || "-"} />
								</div>
							</div>
						</div>

						{isRequiredAddinalDocument && (
							<div className="additional-document">
								<div className="additional-document__document">
									<div className="additional-document__header">
										<p className="additional-document__title">Remarks</p>

										<AppButton type="button" label="Upload Document" icon={<UploadIcon />} onClick={onHandleUploadAdditionalDocuments} />
									</div>

									<div className="additional-document__content">
										<p className="additional-document__text">{data.remarks || "-"}</p>
									</div>
								</div>
							</div>
						)}

						<AppScrollableTabs tabs={TABS} onChange={setTab} />

						<Summary tab={tab} claimInfo={claimInfo} />

						<Remarks tab={tab} remarks={data.remarks} />

						<UploadedDocuments tab={tab} documents={data.docs} />

						<ClaimSettlementDocuments tab={tab} documents={data.settlementDocs} />
					</div>
				</div>
			</Modal>

			<AppUploadAdditionalClaimDocumentsModal ref={uploadAdditionalDocumentRef} onHandleGetList={props.onHandleGetList} />
		</Fragment>
	);
};

export default memo(forwardRef(AppInsuranceClaimModal));

AppInsuranceClaimModal.propTypes = {
	ref: PropTypes.object,
};
