import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { AppTableEditIcon, AppTableCell } from "components/app-table";
import AppPolicyModal from "components/pages/insurance-coverage/app-policy-modal";
import viewIcon from "assets/images/view-icon.svg";

const PagePolicy = (props) => {
	const policyModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", orderRef: searchParams.get("orderRef") || "" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.orderRef, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.policies.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleViews = useCallback((obj) => {
		policyModalRef.current.onHandleShow(obj)
	}, []);

	//prettier-ignore
	const onHandleSearch = (event) => {
		const value = event.target.value;
		paramsRef.current.orderRef = value;
		onHandleGetList();
	};

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
        return <AppTableEditIcon icon={viewIcon} onClick={() => onHandleViews(row.original)} />;
    }, [onHandleViews]);

	const EffectiveDateCell = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.policyEffectiveDate);
		return <AppTableCell left value={date} />;
	}, []);

	const ExpiryDateCell = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.policyExpiryDate);
		return <AppTableCell left value={date} />;
	}, []);

	const PremiumAmountCell = useCallback(({ row }) => <AppTableCell right value={"RM " + formatCurrency(row.original.nettAnnualPremiumPrice)} />, []);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.values.policyStatus} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Ref. No.",
			accessor: "policyNo",
			disableSortBy: false,
		},
		{
			Header: "Plan Name",
			accessor: "planName",
			disableSortBy: true,
		},
		{
			Header: "Benefit Type",
			accessor: "benefitTypes",
			disableSortBy: true,
		},
		{
			Header: "Policy Effective Date",
			accessor: "policyEffectiveDate",
			disableSortBy: false,
			Cell: EffectiveDateCell
		},
		{
			Header: "Policy Expiry Date",
			accessor: "policyExpiryDate",
			disableSortBy: false,
			Cell: ExpiryDateCell
		},
		{
			Header: "Premium Amount",
			accessor: "nettAnnualPremiumPrice",
			disableSortBy: true,
			Cell: PremiumAmountCell
		},
		{
			Header: "Status",
			accessor: "policyStatus",
			disableSortBy: true,
			Cell: StatusCell
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, EffectiveDateCell, ExpiryDateCell, PremiumAmountCell, StatusCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.POLICIES.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-policy">
			<div className="policy">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput defaultValue={paramsRef.current.orderNo} onChange={onHandleDebounceSearch} />
						
						{paramsRef.current.orderRef && (
							<div className="policy__results">
								<p className="policy__text">{data.totalElements} results found</p>
							</div>
						)}
						
						<AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}

				<AppPolicyModal ref={policyModalRef} />
			</div>
		</div>
	);
};

export default PagePolicy;
