import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import { setLogo } from "store/slices/profile";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import validateImageDimension from "common/validate-image-dimension";
import converReadableFileSize from "common/convert-readable-file-size";
import AppButton from "components/app-button";
import uploadIcon from "assets/images/upload-icon.svg";

export const AppUploadCompanyProfileModal = (props, ref) => {
	const dispatch = useDispatch();
	const uploadInputRef = useRef();
	const onCallback = useRef();
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ file: "" }), []);
	//prettier-ignore
	const formik = useFormik({
        initialValues,
		validationSchema: yup.object({
            file: yup.mixed().required(ERRORS.REQUIRED).test("fileSize", ERRORS.PROFILE_IMAGE_SIZE, (v) => validateFileSize(v)).test("fileDimension", ERRORS.PROFILE_IMAGE_SIZE, (v) => validateImageDimension({ width: 160, height: 50 }, v)),
		}),
		onSubmit: (values) => {
            onHandleSubmit(values);
		},
	});
	const fileSize = useMemo(() => formik.values?.file?.size, [formik.values]);

	const onHandleShow = useCallback((callback) => {
		onCallback.current = callback;
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		if (onCallback?.current) onCallback.current();
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		const file = event.target.files[0];
		formik.setFieldValue("file", file);
	}, [formik]);

	const onHandleUploadFile = useCallback(() => {
		uploadInputRef.current.click();
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

        try {
            const formData = new FormData();
            formData.append("file", values.file);

            response = await api.post.general.uploadCompanyLogo(formData);
        }
        catch(error) {
            serveRequestErrors(error);
        } finally {
            formik.setSubmitting(false);
        }

		if(response) {
			if(onCallback?.current) onCallback.current();
			dispatch(setLogo(response.url));
            dispatch(promptAlertMessage({ message: "Profile has been updated successfull" }));
			setVisible(false);
		}
    }, [dispatch, formik]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-upload-company-profile-modal" }} open={visible} aria-labelledby="upload-company-profile" aria-describedby="upload-company-profile-modal">
			<form className="upload-company-profile" onSubmit={formik.handleSubmit}>
				<div className="upload-company-profile__header">
					<p className="upload-company-profile__title">Upload Company Logo</p>
					<p className="upload-company-profile__description">Your logo will be displayed on the top left of HR Portal.</p>
					<p className="upload-company-profile__note">Please ensure that your image is in the correct format (jpg, png, 160px x 50px) with file size not exceeding 5MB.</p>
				</div>

				<div className="upload-company-profile__input">
					<p className="upload-company-profile__label">Company Logo</p>

					{formik.values?.file && (
						<button type="button" className="upload-company-profile__upload-button" onClick={onHandleUploadFile}>
							{formik.values?.file?.name}
							<img className="upload-company-profile__icon" src={uploadIcon} alt="upload" />
						</button>
					)}

					{!formik.values?.file && (
						<button type="button" className="upload-company-profile__upload-button" onClick={onHandleUploadFile}>
							Uploaded
							<img className="upload-company-profile__icon" src={uploadIcon} alt="upload" />
						</button>
					)}
					<p className="upload-company-profile__label">{converReadableFileSize(fileSize, true) || "-"}</p>
					<input type="file" name="file" accept="image/jpeg, image/jpg, image/png" hidden ref={uploadInputRef} onChange={onHandleChange} />
				</div>

				{formik.touched.file && formik.errors.file && <div className="upload-company-profile__footer">{formik.errors.file}</div>}

				<div className="upload-company-profile__button-container">
					<AppButton type="button" label="Upload Later" outline disabled={formik.isSubmitting} onClick={onHandleDismiss} />
					<AppButton type="submit" label="Update Now" disabled={formik.isSubmitting} />
				</div>
			</form>
		</Modal>
	);
};

export default memo(forwardRef(AppUploadCompanyProfileModal));

AppUploadCompanyProfileModal.propTypes = {
	ref: PropTypes.object,
};
