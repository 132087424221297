import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import classNames from "common/class-names";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import { CALENDAR_FORMAT, formatDatePattern, addDays, getTotalDays } from "common/calendar";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppEmptyState from "components/app-empty-state";
import AppSelectInput from "components/app-select-input";
import AppSearchInput from "components/app-search-input";
import AppCalendarInput from "components/app-calendar-input";
import AppTable, { AppTableCell } from "components/app-table";
import AppInsurancePlanIcon from "components/icons/app-insurance-plan-icon";
import AppUpdateCoverageModal from "components/pages/insurance-coverage/app-update-coverage-modal";
import editIcon from "assets/images/edit-icon.svg";
import cancelIcon from "assets/images/reject-icon.svg";
import checkedIcon from "assets/images/checked-icon.svg";
import exclamationIcon from "assets/images/exclamation-icon.svg";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_SHORT_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const PageCoverages = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const searchInputRef = useRef();
	const updateCoverageRef = useRef();
	const [employees, setEmployees] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [editable, setEditable] = useState(false);
	const [insurancePlanOptions, setInsurancePlanOptions] = useState([]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	//prettier-ignore
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", name: searchParams.get("name") || "", employeeId: searchParams.get("employeeId") || "", identity: searchParams.get("identity") || "", organisation: searchParams.get("organisation") || "", ebInsurancePlan: searchParams.get("ebInsurancePlan") || "all" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const [filterBy, setFilterBy] = useState({ [COMMON.INSURANCE.PLANS.PREMIER]: false, [COMMON.INSURANCE.PLANS.REGULAR_PLUS]: false, [COMMON.INSURANCE.PLANS.REGULAR]: false, [COMMON.INSURANCE.PLANS.NOT_COVERED]: false });
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.name && !paramsRef.current.organisation && !paramsRef.current.identity && !paramsRef.current.employeeId && paramsRef.current.ebInsurancePlan === "all", [data.status]);
	const initialValues = useMemo(() => ({ employeeId: "", name: "", identity: "", organisation: "" }), []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.coverages.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleGetInsurancePlanList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.coverages.utilizationInsurance();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const options = response.map(({ planName, internalCode, ...o }) => ({ ...o, label: planName, value: internalCode }));
			options.push({ level: 0, quantity: 0, label: "Not Covered", value: COMMON.INSURANCE.PLANS.NOT_COVERED });
			setInsurancePlanOptions(options);
		}
	}, []);

	const onHandleGetCompanies = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.coverages.companies();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const list = response.map((o) => ({ ...o, label: o.name, value: o.businessRegistrationNo }));

			setCompanies(list);
		}
	}, []);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { name: params.name, employeeId: params.employeeId, identity: params.identity, organisation: params.organisation };

		return values;
	}, [searchParams]);

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandleSaveInsurancePlans = useCallback(async () => {
		updateCoverageRef.current.onHandleShow(employees);
	}, [employees]);

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleOpenMenu = useCallback((event) => {
		setAnchorEl(event.target);
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleFilterBy = useCallback((event) => {
			const name = event.target.name;

			setFilterBy((prev) => {
				const next = { ...prev };

				Object.keys(next).forEach((o) => (next[o] = false));

				next[name] = !prev[name];

				if (!next[name]) {
					/* if current filterBy is false return to all */
					paramsRef.current.ebInsurancePlan = "all";
				} else if (name === COMMON.INSURANCE.PLANS.NOT_COVERED) {
					/* if all filterBy is not covered return to empty */
					paramsRef.current.ebInsurancePlan = "";
				} else {
					/* if all filterBy is true return to current [name] */
					paramsRef.current.ebInsurancePlan = name;
				}

				return next;
			});

			onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDisabledEdit = useCallback(() => {
		setEmployees([]);
		setEditable(false);
	}, []);

	const Plans = useCallback((obj) => {
		return obj.utilizationInsurance.map((a) => {
			if (a.value === COMMON.INSURANCE.PLANS.NOT_COVERED) return null;

			const className = classNames({
				coverages__plan: true,
				"coverages__plan--premier": a.value === COMMON.INSURANCE.PLANS.PREMIER,
				"coverages__plan--regular-plus": a.value === COMMON.INSURANCE.PLANS.REGULAR_PLUS,
				"coverages__plan--regular": a.value === COMMON.INSURANCE.PLANS.REGULAR,
			});
			const classNameWrapper = classNames({
				coverages__wrapper: true,
				"coverages__wrapper--premier": a.value === COMMON.INSURANCE.PLANS.PREMIER,
				"coverages__wrapper--regular-plus": a.value === COMMON.INSURANCE.PLANS.REGULAR_PLUS,
				"coverages__wrapper--regular": a.value === COMMON.INSURANCE.PLANS.REGULAR,
			});

			return (
				<div className={className} key={a.value}>
					<div className={classNameWrapper}>
						<AppInsurancePlanIcon />
						<p className="coverages__title">{a.label}</p>
					</div>
					<p className="coverages__quantity">{a.total}</p>
				</div>
			);
		});
	}, []);

	//prettier-ignore
	const EbInsurancePlanCell = useCallback(({ row }) => {
        const updatedEmployeePlan = employees.find(o => o.id === row.original.id);

		const optionValue = updatedEmployeePlan ? updatedEmployeePlan.ebInsurancePlan : row.original?.ebInsurancePlan || COMMON.INSURANCE.PLANS.NOT_COVERED;

        const onSelectInsurancePlan = (event) => {
            let nextEmployees = employees.slice(0);
            const value = event.target.value;
            const foundIndex = employees.findIndex(o => o.id === row.original.id);

            if(foundIndex > -1) {
				if(employees[foundIndex] !== COMMON.INSURANCE.PLANS.NOT_COVERED) {
					nextEmployees = nextEmployees.map((o) => o.id === row.original.id ? { ...o, ebInsurancePlan: value } : o);
				} else {
					nextEmployees.splice(foundIndex, 1);
				}
            }
            else {
                nextEmployees.push({ ...row.original, ebInsurancePlan: value });
            }

            setEmployees(nextEmployees); 
        }

        if (editable) {
            return <AppSelectInput searchable={false} type="text" name="insurancePlan" placeholder="Please Select" options={insurancePlanOptions} value={optionValue} onChange={onSelectInsurancePlan} disabled={!row.original.isAgeEligible} />;
        }


		const classNamePlan = classNames({
			"table__insurance": true,
			"table__insurance--not-eligible": !row.original.isAgeEligible,
		});

		const onMouseOver = (event) => !row.original.isAgeEligible && onHandleOpenMenu(event);

        return <span className={classNamePlan} onMouseOver={onMouseOver} onFocus={() =>{}}>{capitalizeCharacter(row.original.ebInsurancePlan?.split("_")?.join(" ")) || "Not Covered"}</span>
    }, [editable, employees, insurancePlanOptions, onHandleOpenMenu]);

	//prettier-ignore
	const OrganisationCell = useCallback(({ row }) => {
		const updatedEmployeePlan = employees.find(o => o.id === row.original.id);

		const optionValue = updatedEmployeePlan?.newBusinessRegNo ? updatedEmployeePlan.newBusinessRegNo : row.original.businessRegNo;

		const onHandleSelectOrganisation = (event) => {
			let nextEmployees = employees.slice(0);
            const value = event.target.value;
            const foundIndex = employees.findIndex(o => o.id === row.original.id);

            if(foundIndex > -1) {
				nextEmployees[foundIndex].newBusinessRegNo = value;
            }
            else {
                nextEmployees.push({ ...row.original, newBusinessRegNo: value });
            }

            setEmployees(nextEmployees); 
        }

		
        if (editable) {
            return <AppSelectInput searchable={false} type="text" name="businessRegNo" placeholder="Please Select" options={companies} value={optionValue} onChange={onHandleSelectOrganisation} />;
        }

        return row.original.organisation; 
    }, [editable, employees, companies]);

	//prettier-ignore
	const EffectiveDateCell = useCallback(({ row }) => {
		const originNotCovered = !row.original.ebInsurancePlan;

		const notCovered = employees.findIndex(o => o.id === row.original.id && o.ebInsurancePlan === COMMON.INSURANCE.PLANS.NOT_COVERED) > -1;

		const employeeIndex = employees.findIndex(o => o.id === row.original.id) ;

		const employee = employees[employeeIndex];

		const ebInsuranceEffectiveDateValue = employee && "effectiveDate" in employee ? employee?.effectiveDate : row.original.ebInsuranceEffectiveDate ? row.original.ebInsuranceEffectiveDate : "";

		const onSelectEffectiveDate = (name, value) => {
            let nextEmployees = employees.slice(0);

            if(employeeIndex > -1) {
				if(employees[employeeIndex] !== COMMON.INSURANCE.PLANS.NOT_COVERED) {
					nextEmployees = nextEmployees.map(o => o.id === row.original.id ? { ...o, [name]: value } : o);
				} else {
					nextEmployees.splice(employeeIndex, 1);
				}
            }
            else {
                nextEmployees.push({ ...row.original, [name]: value });
            }

            setEmployees(nextEmployees);
        }	

		const onHandleMinDate = () => {
			const policyEffectiveDate = row.original.policyEffectiveDate;
			
			const totalDays = getTotalDays(new Date(), policyEffectiveDate);

			if(totalDays >= 30) {
				return addDays(new Date(), -30);
			}
			else {
				return policyEffectiveDate;
			}
		}

		if(!editable || notCovered || (originNotCovered && !employee?.ebInsurancePlan) || (editable && !row.original.isEffectiveDateEditable) || (!employee && !row.original.isEffectiveDateEditable)) {
			return <AppTableCell left value={formatDatePattern(row?.original?.ebInsuranceEffectiveDate)} />;
		}

		return <AppCalendarInput name="effectiveDate" placeholder="01 Jan 2023" minDate={onHandleMinDate()} maxDate={new Date()} displayFormat={calendarDisplayFormat} value={ebInsuranceEffectiveDateValue} onChange={onSelectEffectiveDate} />;
	}, [employees, editable]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Employee ID",
			accessor: "employeeId",
			disableSortBy: false,
		},
		{
			Header: "Employee Name",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "NRIC/Passport",
			accessor: "identity",
			disableSortBy: true,
		},
		{
			Header: "Level",
			accessor: "level",
			disableSortBy: false,
		},
		{
			Header: "Organisation",
			accessor: "organisation",
			disableSortBy: false,
			Cell: OrganisationCell
		},
		{
			Header: "Insurance Plan",
			accessor: "ebInsurancePlan",
			disableSortBy: false,
            Cell: EbInsurancePlanCell
		},
		{
			Header: "Coverage Effective Date",
			accessor: "ebInsuranceEffectiveDate",
			disableSortBy: false,
			Cell: EffectiveDateCell
		},
	], [EbInsurancePlanCell, EffectiveDateCell, OrganisationCell]);

	const headerOptions = useMemo(() => {
		const onHandleEditInsurance = () => {
			setEditable(true);
		};

		const editableOptions = [
			{ label: "Cancel", value: "CANCEL", outline: true, icon: cancelIcon, onClick: onHandleDisabledEdit },
			{ label: "Save", value: "SAVE", outline: false, icon: checkedIcon, onClick: onHandleSaveInsurancePlans, disabled: !employees?.length },
		];

		const options = [{ label: "Edit Plan", value: "EDIT_PLAN", outline: true, icon: editIcon, onClick: onHandleEditInsurance }];

		if (editable) return editableOptions;
		else return options;
	}, [editable, employees, onHandleSaveInsurancePlans, onHandleDisabledEdit]);

	const CheckboxLabel = useCallback((obj) => {
		const className = classNames({
			coverages__label: true,
			"coverages__label--premier": obj.code === COMMON.INSURANCE.PLANS.PREMIER,
			"coverages__label--regular-plus": obj.code === COMMON.INSURANCE.PLANS.REGULAR_PLUS,
			"coverages__label--regular": obj.code === COMMON.INSURANCE.PLANS.REGULAR,
		});

		return <span className={className}>{obj.label}</span>;
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		onHandleGetCompanies();
	}, [onHandleGetCompanies]);

	useEffect(() => {
		onHandleGetInsurancePlanList();
	}, [onHandleGetInsurancePlanList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.COVERAGES.LIST);
			cancelRequest(COMMON.ENDPOINT_PATH.COVERAGES.COMPANIES);
			cancelRequest(COMMON.ENDPOINT_PATH.COVERAGES.INSURANCE_PLANS_LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-coverages">
			<div className="coverages">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttons={headerOptions}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="employeeId" label="Employee ID" placeholder="Enter employee ID" value={formik.values.employeeId} onChange={formik.handleChange} />

										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter employee name" value={formik.values.name} onChange={formik.handleChange} />

										<AppInput type="text" name="identity" label="NRIC/Passport" placeholder="Enter NRIC/Passport" value={formik.values.identity} onChange={formik.handleChange} />

										<AppInput type="text" name="organisation" label="Organisation" placeholder="Enter organisation" value={formik.values.organisation} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>

						<div className="coverages__plans">
							<Plans utilizationInsurance={insurancePlanOptions} />
						</div>

						<div className="coverages__filter">
							<p className="coverages__filter-text">Filter by</p>

							<div className="coverages__checkboxs">
								{insurancePlanOptions?.map((o) => {
									return <AppCheckbox key={o.value} onClick={onHandleFilterBy} label={<CheckboxLabel label={o.label} code={o.value} />} name={o.value} value={filterBy[o.value]} />;
								})}
							</div>
						</div>

						{(paramsRef.current.name || paramsRef.current.ebInsurancePlan !== "all") && (
							<div className="coverages__results">
								<p className="coverages__text">{data.totalElements} results found</p>
							</div>
						)}
						<AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}
			</div>

			<Menu classes={{ root: "app-table-menu app-coverages-menu" }} anchorEl={anchorEl} open={!!anchorEl} anchorOrigin={{ vertical: "center", horizontal: "right" }} transformOrigin={{ vertical: "center", horizontal: "right" }}>
				<MenuItem onMouseLeave={onhandleCloseMenu}>
					<img className="app-coverages-menu__icon" src={exclamationIcon} alt="exclamation" />
					Not eligible to cover insurance according to age criteria set by provider
				</MenuItem>
			</Menu>

			<AppUpdateCoverageModal ref={updateCoverageRef} onHandleGetList={onHandleGetList} onHandleGetInsurancePlanList={onHandleGetInsurancePlanList} onHandleDisabledEdit={onHandleDisabledEdit} />
		</div>
	);
};

export default PageCoverages;
