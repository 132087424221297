import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";

import classNames from "common/class-names";

//prettier-ignore
const AppButtonInput = (props) => {
	const className = useCallback((type) => {
		return classNames({
			"app-button-input__button": true,
			"app-button-input__button--active": type === props.value,
		});
	}, [props.value]);

	return (
        <div className="app-button-input">
            {props.label && <p className="app-button-input__label">{props.label}<span className="app-button-input__required">*</span></p>}

            <div className="app-button-input__container">
				{props.options.map(o => {
					return <button key={o.value} type="button" className={className(o.value)} disabled={props.disabled} onClick={() => props.onChange(props.name, o.value)}>{o.label}</button>
				})}
            </div>

            <p className="app-button-input__error">{props.touched && props.error ? props.error : ""}</p>
        </div>
    );
};

export default memo(AppButtonInput);

AppButtonInput.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	touched: PropTypes.bool,
	error: PropTypes.string,
	value: PropTypes.string,
};
