import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import formatStringPattern from "common/format-string-pattern";
import getAllClaimTypeListing from "services/get-all-claim-type-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppTable, { AppTableEditIcon, AppTableCell } from "components/app-table";
import AppClaimantModal from "components/pages/insurance-coverage/app-claimant-modal";
import AppInsuranceClaimModal from "components/pages/insurance-coverage/app-insurance-claim-modal";
import addIcon from "assets/images/add-icon.svg";
import viewIcon from "assets/images/view-icon.svg";

const PageInsuranceClaim = (props) => {
	const searchInputRef = useRef();
	const selectInputRef = useRef();
	const claimantModalRef = useRef();
	const insuranceClaimModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	//prettier-ignore
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", name: searchParams.get("name") || "", identity: searchParams.get("identity") || "", ebClaimTypeId: searchParams.get("ebClaimTypeId") || "", statusListStr: searchParams.get("statusListStr") || "" });

	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.name && !paramsRef.current.ebClaimTypeId && !paramsRef.current.identity && !paramsRef.current.statusListStr, [data.status]);

	const initialValues = useMemo(() => ({ name: "", ebClaimTypeId: "", identity: "", statusListStr: "" }), []);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.coverages.claims(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleViews = useCallback((obj) => {
		insuranceClaimModalRef.current.onHandleShow(obj)
	}, []);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);

		const claimTypeOptions = selectInputRef.current?.getOptionsData();

		const claimType = claimTypeOptions?.find((o) => o.value === +params.ebClaimTypeId)?.label || "";

		let values = { name: params.name, identity: params.identity, ebClaimTypeId: claimType, statusListStr: capitalizeCharacter(params.statusListStr) };

		return values;
	}, [searchParams]);

	//prettier-ignore
	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};
	const onHandleCreate = () => {
		claimantModalRef.current.onHandleShow();
	};

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
        return <AppTableEditIcon icon={viewIcon} onClick={() => onHandleViews(row.original)} />;
    }, [onHandleViews]);

	const SubmitDateCell = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.createdDate);
		return <AppTableCell left value={date} />;
	}, []);

	const IdentityCell = useCallback(({ row }) => {
		const identity = row.original.nric ? formatStringPattern(row.original.nric, COMMON.MASKING.NRIC) : row.original.passport;
		return <AppTableCell left value={identity} />;
	}, []);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.values.status} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Employee Name",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "NRIC/Passport",
			accessor: "identity",
			disableSortBy: true,
			Cell: IdentityCell
		},
		{
			Header: "Type of Claims",
			accessor: "claimType",
			disableSortBy: true,
		},
		{
			Header: "Submit Date",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: SubmitDateCell
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, SubmitDateCell, StatusCell, IdentityCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.COVERAGES.CLAIMS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-insurance-claim">
			<div className="insurance-claim">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttons={[{ label: "New Claim", icon: addIcon, onClick: onHandleCreate }]}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter employee name" value={formik.values.name} onChange={formik.handleChange} />

										<AppInput type="text" name="identity" label="NRIC/Passport" placeholder="Enter NRIC/Passport" value={formik.values.identity} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppSelectInput ref={selectInputRef} type="text" name="ebClaimTypeId" label="Type of Claims" placeholder="Please Select" loadOptions={getAllClaimTypeListing} value={formik.values.ebClaimTypeId} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppSelectInput type="text" name="statusListStr" label="Status" placeholder="Please Select" options={COMMON.DROPDOWNS.INSURANCE_CLAIM_STATUS} value={formik.values.statusListStr} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>

						{(paramsRef.current.name || paramsRef.current.identity || paramsRef.current.ebClaimTypeId || paramsRef.current.statusListStr) && (
							<div className="insurance-claim__results">
								<p className="insurance-claim__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}

				<AppInsuranceClaimModal ref={insuranceClaimModalRef} onHandleGetList={onHandleGetList} />

				<AppClaimantModal ref={claimantModalRef} />
			</div>
		</div>
	);
};

export default PageInsuranceClaim;
