import React, { useRef, useCallback, useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppTabs from "components/app-tabs";
import AppStatus from "components/app-status";
import AppSearchInput from "components/app-search-input";
import AppLeaveSummary from "components/pages/leave-management/app-leave-summary";
import AppLeaveHistory from "components/pages/leave-management/app-leave-history";
import AppLeaveUpcoming from "components/pages/leave-management/app-leave-upcoming";

const PageLeave = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const tabsNav = useMemo(() => [
		{ label: "Leave Summary", value: "Leave Summary" }, 
        { label: "Upcoming Leave", value: "Upcoming Leave" }, 
        { label: "Leave History", value: "Leave History" }, 
    ], []);
	const paramsRef = useRef({ tab: searchParams.get("tab") || tabsNav[0].value });
	const [tab, setTab] = useState(paramsRef.current.tab);
	const [user, setUser] = useState();

	const onHandleChangeTab = useCallback((v) => {
		setTab(v);
		paramsRef.current.tab = v;
		memoSetSearchParams.current({ tab: v }, { replace: true });
	}, []);

	const onHandlePendingApproval = useCallback(() => {
		if (user) navigate(pathnames.leaveManagement.leavesApproval + "?name=" + user.name);
	}, [user, navigate]);

	const Tab = useCallback(() => {
		switch (tab) {
			case "Leave Summary":
				return <AppLeaveSummary />;
			case "Upcoming Leave":
				return <AppLeaveUpcoming />;
			case "Leave History":
				return <AppLeaveHistory />;
			default:
				return null;
		}
	}, [tab]);

	useEffect(() => {
		if (state) {
			sessionStorage.setItem("page-leave-user-info", JSON.stringify(state));
			setUser(state);
		} else {
			const data = sessionStorage.getItem("page-leave-user-info");
			if (data) setUser(JSON.parse(data));
		}
	}, [state]);

	return (
		<div className="page-leave">
			<div className="leave">
				<div className="leave__header">
					<p className="leave__name">{user?.name || "-"}</p>
					<p className="leave__employee-id"> {user?.employeeId || "-"}</p>
					<AppStatus status={user?.workStatus || ""} />
				</div>

				<AppSearchInput buttons={[{ label: "Pending Approval", onClick: onHandlePendingApproval }]} />

				<AppTabs tabs={tabsNav} value={tab} onChange={onHandleChangeTab} />
				<Tab />
			</div>
		</div>
	);
};

export default PageLeave;
