import React, { memo } from "react";

import AppButton from "components/app-button";
import emptyBox from "assets/images/components/app-empty-state/empty-box.png";

const AppEmptyData = (props) => {
	return (
		<div className="app-empty-data">
			<div className="empty-data">
				<img className="empty-data__image" src={emptyBox} alt="is empty" />
				<p className="empty-data__title">{props.title}</p>
				<p className="empty-data__description">{props.description}</p>

				{!props.disabledButton && <AppButton type="button" label={props.label || "Add New"} onClick={props.onClick} />}
			</div>
		</div>
	);
};

export default memo(AppEmptyData);
