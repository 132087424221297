import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getAllClaimTypeListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.coverages.allClaimType();

		const content = res.map((o) => ({ ...o, label: o.label, value: o.id }));

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getAllClaimTypeListing;
