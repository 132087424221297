import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import AppButton from "components/app-button";

export const AppInsufficientInsurerModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-insufficient-insurer-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="insufficient-insurer-modal" aria-describedby="insufficient-insurer-modal">
			<div className="insufficient-insurer-modal">
				<div className="insufficient-insurer-modal__main">
					<div className="insufficient-insurer-modal__header">
						<p className="insufficient-insurer-modal__title">Discrepancy Detected</p>
					</div>

					<div className="insufficient-insurer-modal__body">
						<p className="insufficient-insurer-modal__description">The number of eligible employees for coverage added does not align with the purchased plan. Please review and adjust accordingly</p>

						<div className="insufficient-insurer-modal__button-container">
							<AppButton type="button" label="Ok" onClick={onHandleDismiss} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInsufficientInsurerModal));

AppInsufficientInsurerModal.propTypes = {
	ref: PropTypes.object,
};
