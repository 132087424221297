const ROLES = {
	OWNER: "owner",
	ADMIN: "admin",
	VIEW_ADMIN: "admin:view",
	CREATE_ADMIN: "admin:create",
	UPDATE_ADMIN: "admin:update",
	DELETE_ADMIN: "admin:delete",
	ROLE_PRIVILEGES: "hr:role",
	VIEW_ROLE_PRIVILEGES: "hr:role:view",
	CREATE_ROLE_PRIVILEGES: "hr:role:create",
	UPDATE_ROLE_PRIVILEGES: "hr:role:update",
	BENEFITS: "company:benefit",
	VIEW_BENEFITS: "company:benefit:view",
	CREATE_BENEFITS: "company:benefit:create",
	UPDATE_BENEFITS: "company:benefit:update",
};

export default ROLES;
