import React, { memo, forwardRef, useRef, useImperativeHandle, useState, useCallback, useEffect, useMemo, Fragment } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import capitalizeCharacter from "common/capitalize-character";
import { currencyFormatter, formatCurrency } from "common/format-currency-pattern";
import serveRequestErrors from "common/serve-request-errors";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";
import AppAdditionalDocumentsModal from "components/pages/orders/app-additional-documents-modal";
import uploadIcon from "assets/images/upload-white-icon.svg";

const TABS = [
	{ label: "Summary", value: "SUMMARY" },
	{ label: "Insured Person(s)", value: "INSURED_PERSONS" },
	{ label: "Attached Documents", value: "ATTACHED_DOCUMENTS" },
];

export const AppOrderModal = (props, ref) => {
	const additionalDocumentsRef = useRef();
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS[0].value);
	const isPendingAdditionalDocuments = useMemo(() => data.orderStatus === COMMON.STATUS_ID.PENDING_ADDITIONAL_DOCS, [data]);

	const organisationAddress = useMemo(() => {
		let address = [];

		if (!data.ebCompany) return "";

		address.push(data.ebCompany?.address1);

		if (data.ebCompany?.address2) address.push(data.ebCompany?.address2);

		address.push(data.ebCompany?.postcode);

		address.push(data.ebCompany?.state);

		address.push(data.ebCompany?.region);

		return address.filter((o) => o).join(", ");
	}, [data.ebCompany]);

	const tabsInfo = useMemo(() => {
		const info = {
			basic: [
				{ label: "Customer ID", value: data.id },
				{ label: "Insurance Provider", value: data.insuranceProviderName },
				{ label: "Plan Name", value: data.productName },
				{ label: "Customer Name", value: data.createdByName },
				{ label: "Organisation Name", value: data.ebCompany?.companyName },
				{ label: "Office No.", value: data.ebCompany?.mobileNoPrefix + data.ebCompany?.mobileNo },
				{ label: "Email Address", value: data.ebCompany?.email },
				{ label: "Organisation Address", value: organisationAddress },
				{ label: "Business Reg. No.", value: data.ebCompany?.businessRegistrationNo },
				{ label: "Nature of Business", value: data.ebCompany?.natureOfBusiness },
			],
			price: [
				{ label: "Annual Premium Price", value: "RM " + formatCurrency(data.annualPremiumPrice) },
				{ label: "TPA Fees", value: "RM " + formatCurrency(data.tpaFee) },
				{ label: `SST (${data.sst}%)`, value: "RM " + formatCurrency(data.sstAmount) },
				{ label: "Stamp Duty", value: "RM " + formatCurrency(data.stampDuty) },
				{ label: "Payment Method", value: "" },
				{ label: "Bank Details", value: "" },
			],
		};
		return info;
	}, [data, organisationAddress]);

	const onHandleShow = useCallback((id) => {
		setTab(TABS[0].value);

		setData((prev) => ({ ...prev, id }));

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	const onHandleUploadAdditionalDocuments = useCallback(() => {
		additionalDocumentsRef.current.onHandleShow(data);
	}, [data]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				response = await api.get.orders.order(data.id);
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	const Tabs = useCallback(() => {
		switch (tab) {
			case "SUMMARY":
				return (
					<div className="order-modal__body">
						<div className="order-modal__container">
							{tabsInfo.basic.map((a, i) => {
								return (
									<div className="order-modal__info" key={i}>
										<p className="order-modal__label">{a.label}</p>
										<p className="order-modal__value">{a.value || "-"}</p>
									</div>
								);
							})}
						</div>
						<div className="order-modal__container">
							<div className="order-modal__headline-wrapper">
								<p className="order-modal__headline">PLAN COVERAGE</p>
							</div>

							{data.items?.map((a, i) => {
								return (
									<div className="plans" key={i}>
										<div className="plans__header">
											<p className="plans__plan">{a.planName}</p>
											<p className="plans__label">Pax {a.quantity}</p>
										</div>

										<div className="plans__body">
											{a.benefits.map((b, j) => {
												const header = (
													<Fragment key={j}>
														<div className="plans__item">
															<p className="plans__label">{b.name}</p>
															<p className="plans__price">RM {formatCurrency(b.premiumAmount * a.quantity)}</p>
														</div>

														<p className="plans__benefit-amount">
															{a.quantity} x {formatCurrency(b.premiumAmount)}
														</p>
													</Fragment>
												);

												switch (b.productEbCode) {
													case COMMON.INSURANCE.PRODUCT_EB_CODE.GHS:
														return (
															<Fragment key={j}>
																{header}

																<ul className="plans__sub-item">
																	{b.productEbPlanBenefitCoverages.map((c, k) => {
																		const roomAndBoardCoverage = c.code === COMMON.INSURANCE.COVERAGES.ROOM_AND_BOARD;

																		if (!roomAndBoardCoverage) return null;

																		const coverage = c.coverageAmount > 1 ? `RM ${currencyFormatter(c.coverageAmount)}` : "Unlimited";

																		return (
																			<li className="plans__benefits" key={k}>
																				{/* prettier-ignore */}
																				<p className="plans__benefit">{" "}• {c.name} <b className="plans__coverage-amount"> {coverage}</b></p>
																			</li>
																		);
																	})}

																	<li className="plans__benefits">
																		{/* prettier-ignore */}
																		<p className="plans__benefit">{" "}• Overall Annual Limit <b className="plans__coverage-amount">RM {currencyFormatter(b.overallAnnualLimit)}</b></p>
																	</li>
																</ul>
															</Fragment>
														);
													case COMMON.INSURANCE.PRODUCT_EB_CODE.GO:
														return (
															<Fragment key={j}>
																{header}
																<ul className="plans__sub-item">
																	<li className="plans__benefits">
																		<p className="plans__benefit">Group Outpatient Clinical & Specialist</p>
																	</li>

																	<li className="plans__benefits">
																		{/* prettier-ignore */}
																		<p className="plans__benefit">{" "}• Combined GP & SP Annual Limit <b className="plans__coverage-amount">RM {currencyFormatter(b.overallAnnualLimit)}</b></p>
																	</li>
																</ul>
															</Fragment>
														);
													case COMMON.INSURANCE.PRODUCT_EB_CODE.GTL:
														const optionalRiders = b.productEbPlanBenefitRiders;
														const optionalRidersAmount = optionalRiders?.reduce((o, j) => o + j?.premiumAmount, 0);

														return (
															<Fragment key={j}>
																{header}

																<ul className="plans__sub-item">
																	<li className="plans__benefits">
																		<p className="plans__benefit">Group Term Life (GTL) due to All Causes</p>
																	</li>

																	<li className="plans__benefits">
																		{/* prettier-ignore */}
																		<p className="plans__benefit">{" "}• Sum Assured <b className="plans__coverage-amount">RM {currencyFormatter(b.overallAnnualLimit)}</b></p>
																	</li>
																</ul>

																{optionalRiders?.length && (
																	<Fragment>
																		<div className="plans__item">
																			<p className="plans__label">Optional Rider for GTL</p>
																			<p className="plans__price">RM {formatCurrency(optionalRidersAmount * a.quantity)}</p>
																		</div>

																		<p className="plans__benefit-amount">
																			{a.quantity} x {formatCurrency(optionalRidersAmount)}
																		</p>

																		<ul className="plans__sub-item">
																			{optionalRiders.map((c, k) => {
																				return (
																					<li className="plans__benefits" key={k}>
																						{/* prettier-ignore */}
																						<p className="plans__benefit">{" "}• {c.name}</p>
																						<p className="plans__benefit-amount-gtl">{formatCurrency(c.premiumAmount)}/pax</p>
																					</li>
																				);
																			})}
																		</ul>
																	</Fragment>
																)}
															</Fragment>
														);
													default:
														return null;
												}
											})}
										</div>
									</div>
								);
							})}

							<div className="order-modal__wrapper">
								<p className="order-modal__section">Total No. of Employee</p>
								<p className="order-modal__section">{data.totalNoOfEmployee}</p>
							</div>
						</div>

						<p className="order-modal__headline">PAYMENT DETAILS</p>

						{tabsInfo.price.map((a, i) => {
							return (
								<div className="order-modal__wrapper" key={i}>
									<p className="order-modal__section">{a.label}</p>
									<p className="order-modal__section">{a.value || "-"}</p>
								</div>
							);
						})}
					</div>
				);
			case "INSURED_PERSONS":
				const sanitizedInsured = { parent: {}, subsidiary: {} };

				data?.insuredPersons?.forEach((o) => {
					const type = o.isParentCompany ? "parent" : "subsidiary";

					if (!sanitizedInsured[type][o.companyName]) {
						sanitizedInsured[type][o.companyName] = {};
					}

					if (!sanitizedInsured[type][o.companyName][o.ebInsurancePlan]) {
						sanitizedInsured[type][o.companyName][o.ebInsurancePlan] = [];
					}

					sanitizedInsured[type][o.companyName][o.ebInsurancePlan].push(o);
				});

				return (
					<div className="order-modal__body">
						{Object.keys(sanitizedInsured).map((a) => {
							const plans = sanitizedInsured[a];

							return Object.keys(plans).map((b) => {
								const plan = plans[b];
								const pax = Object.keys(plan).reduce((j, k) => (j += plan[k].length), 0);

								return (
									<Fragment key={a}>
										<div className="plan">
											<div className="plan__header">
												<p className="plan__title">{a.toUpperCase()} COMPANY</p>
											</div>
										</div>

										<div className="box">
											<div className="box__container">
												<div className="box__header">
													<p className="box__headline">{b}</p>
													<p className="box__sub-headline">{pax} pax</p>
												</div>

												<div className="box__divider" />

												<div className="box__body">
													{Object.keys(plan).map((c) => {
														return (
															<Fragment key={c}>
																<ul className="box__list">
																	<li className="box__insurer">
																		<p className="box__title">{capitalizeCharacter(c.split("_").join(" "))}</p>
																	</li>

																	{plan[c].map((d) => {
																		return (
																			<li key={d.email} className="box__insurer">
																				<div className="box__avatar" style={{ backgroundImage: `url(${d.avatar})` }} />

																				<div className="box__center">
																					<p className="box__headline">{d.name}</p>
																					<p className="box__sub-headline">{d.email}</p>
																				</div>

																				<p className="box__text">{d.employeeId || "-"}</p>
																			</li>
																		);
																	})}
																</ul>
															</Fragment>
														);
													})}
												</div>
											</div>
										</div>
									</Fragment>
								);
							});
						})}
					</div>
				);
			case "ATTACHED_DOCUMENTS":
				return (
					<div className="order-modal__body">
						<div className="files">
							<div className="files__header">
								<p className="files__label">File Name</p>
								<p className="files__label">Uploaded</p>
								<p className="files__label">Date</p>
							</div>

							<ul className="files__body">
								{data.files?.map((a, i) => {
									return (
										<li className="files__item" key={i}>
											<p className="files__text">{a.fileCode}</p>
											<p className="files__name">
												<a href={a.ossPath} target="_blank" rel="noreferrer" className="files__link">
													{a.fileName}
												</a>
											</p>
											<p className="files__text">{formatDatePattern(a.date)}</p>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				);
			default:
				return null;
		}
	}, [tabsInfo, tab, data]);

	return (
		<Fragment>
			<Modal classes={{ root: "app-order-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="order-modal" aria-describedby="leave-profile-modal">
				<div className="order-modal">
					<div className="order-modal__main">
						<button className="order-modal__close-button" onClick={onHandleDismiss}>
							<AppCloseIcon color="#666666" />
						</button>

						<div className="order-modal__header">
							<p className="order-modal__title">{data.orderNo}</p>
							<p className="order-modal__date">Order Submission Date: {formatDatePattern(data.createdDate)}</p>
						</div>

						<div className="info">
							<div className="info__box">
								<p className="order-modal__text">Nett Annual Premium Price</p>
								<p className="order-modal__amount">RM {formatCurrency(data.nettAnnualPremiumPrice)}</p>
							</div>
							<div className="info__box">
								<p className="order-modal__text">Status</p>
								<div className="order-modal__title">
									<AppStatus status={data?.orderStatus || "-"} />
								</div>
							</div>
						</div>

						<div className="order-modal__scrollable">
							{isPendingAdditionalDocuments && (
								<div className="additional-document">
									<div className="additional-document__document">
										<div className="additional-document__header">
											<p className="additional-document__title">Remarks</p>
										</div>

										<div className="additional-document__content">
											<p className="additional-document__text">{data.remark}</p>
											<AppButton type="button" label="Upload Document" icon={uploadIcon} onClick={onHandleUploadAdditionalDocuments} />
										</div>
									</div>
								</div>
							)}

							<AppScrollableTabs tabs={TABS} onChange={setTab} />

							<Tabs />
						</div>
					</div>
				</div>
			</Modal>

			<AppAdditionalDocumentsModal ref={additionalDocumentsRef} onHandleDismiss={onHandleDismiss} />
		</Fragment>
	);
};

export default memo(forwardRef(AppOrderModal));

AppOrderModal.propTypes = {
	ref: PropTypes.object,
};
