import React, { useCallback, useMemo, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppTable from "components/app-table";
import AppEmptyState from "components/app-empty-state";

const AppLeaveSummary = () => {
	const { id } = useParams();
	const context = useContext(AxiosContext);
	const [data, setData] = useState({ page: 0, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData((prev) => ({ ...prev, items: [] }));

		try {
			response = await api.get.leaves.summary(id);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({ ...prev, items: response, status: !response.length ? COMMON.TABLE_REQUEST_STATUS.INSTANCE : "" }));
		}
	}, [id]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Leave Type",
			accessor: "leaveType",
			disableSortBy: true,
		},
		{
			Header: "Entitled Days",
			accessor: "entitledDays",
			disableSortBy: true,
		},
		{
			Header: "Balance Days",
			accessor: "balanceDays",
			disableSortBy: true,
		},
		{
			Header: "Days Taken",
			accessor: "daysTaken",
			disableSortBy: true,
		},
	], []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.LEAVES.SUMMARY);
		};
	}, [context]);

	return (
		<div className="app-leave-summary">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}
			{!isEmptyState && <AppTable columns={columns} pages={data} />}
		</div>
	);
};

export default AppLeaveSummary;
