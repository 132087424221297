import { isDateBeforeToday } from "common/calendar";

const getIdDobYear = (value) => {
	const nricLast4Digit = value.slice(10, 14);

	const years90 = parseInt(nricLast4Digit[0]) >= 5;

	let dobYear = parseInt(value.slice(0, 2));

	if (!years90) {
		dobYear = 2000 + dobYear;
	} else {
		dobYear = 1900 + dobYear;
	}
	return dobYear;
};

export const isValidDate = (dateObj) => {
	return new Date(dateObj).toString() !== "Invalid Date";
};

export const validateNRIC = (value) => {
	let isValid = false;

	if (value?.length >= 14) {
		const dob = value.slice(0, 6);
		const dobMonth = dob.slice(2, 4);
		const dobDay = dob.slice(4, 6);
		const dobYear = getIdDobYear(value);

		const d = new Date(`${dobYear}-${dobMonth}-${dobDay}`);

		isValid = isValidDate(d) && isDateBeforeToday(d, new Date());
	}

	return isValid;
};

export const getIdDob = (value) => {
	if (value?.length >= 14) {
		const dob = value.slice(0, 6);
		const dobMonth = dob.slice(2, 4);
		const dobDay = dob.slice(4, 6);
		const dobYear = getIdDobYear(value);

		const d = new Date(new Date(`${dobYear}-${dobMonth}-${dobDay}`).setHours(0, 0, 0, 0));

		if (isValidDate(d)) return d;
	}

	return "";
};

export const getDobAgeInRange = (dob, range) => {
	const year = new Date(dob).getFullYear();
	const currentYear = new Date().getFullYear();
	const age = currentYear - year;

	return age <= range.max && age >= range.min;
};
