import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getClaimantsListing = async (params) => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.coverages.claimants(params);

		const content = res.map((o) => ({ label: o.name, value: o.id }));

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getClaimantsListing;
