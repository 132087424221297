import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";
import queryParamsEntries from "common/query-params-entries";
import capitalizeCharacter from "common/capitalize-character";
import { formatDatePattern } from "common/calendar";
import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import AppAmendmentStatusModal from "components/pages/user-management/app-amendment-status-modal";
import viewIcon from "assets/images/view-icon.svg";

const PageAmendmentStatus = (props) => {
	const tableRef = useRef();
	const searchInputRef = useRef();
	const amendmentStatusModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	//prettier-ignore
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
		refNo: searchParams.get("refNo") || "",
		name: searchParams.get("name") || "",
		employeeId: searchParams.get("employeeId") || "",
		statusListStr: searchParams.get("statusListStr") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const initialValues = useMemo(() => ({ refNo: "", namet: "", employeeId: "", statusListStr: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.refNo && !paramsRef.current.name && !paramsRef.current.employeeId, [data.status]);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { refNo: params.refNo, name: params.name, employeeId: params.employeeId, statusListStr: capitalizeCharacter(params.statusListStr?.split("_")?.join(" ")) };

		return values;
	}, [searchParams]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.amendmentRequest.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	const onHandleTableMenu = (obj) => {
		amendmentStatusModalRef.current.onHandleShow(obj);
	};

	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={viewIcon} onClick={() => onHandleTableMenu(row.original)} />;
	}, []);

	const SubmissionDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.createdDate))} />;
	}, []);

	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />;
	}, []);

	const OrganisationCell = useCallback(({ row }) => {
		return <AppTableCell left value={row.original.organisation || "-"} />;
	}, []);

	const EmployeeIdCell = useCallback(({ row }) => {
		return <AppTableCell left value={row.original.employeeId || "-"} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Ref. No.",
			accessor: "refNo",
			disableSortBy: false,
		},
		{
			Header: "Employee ID",
			accessor: "employeeId",
			disableSortBy: false,
			Cell: EmployeeIdCell
		},
		{
			Header: "Employee Name",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "Email Address",
			accessor: "email",
			disableSortBy: true,
		},
		{
			Header: "Organisation",
			accessor: "organisation",
			disableSortBy: false,
			Cell: OrganisationCell,
		},
		{
			Header: "Submission Date",
			accessor: "createdDate",
			disableSortBy: false,
			Cell: SubmissionDateCell,
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, StatusCell, OrganisationCell, SubmissionDateCell, EmployeeIdCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-amendment-status">
			<div className="amendment-status">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="refNo" label="Reference No." placeholder="Enter Reference No." value={formik.values.refNo} onChange={formik.handleChange} />

										<AppInput type="text" name="employeeId" label="Employee ID" placeholder="Enter Employee ID" value={formik.values.employeeId} onChange={formik.handleChange} />

										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter Employee Name" value={formik.values.name} onChange={formik.handleChange} />

										{/*prettier-ignore */}
										<AppSelectInput type="text" name="statusListStr" label="Status" placeholder="Please Select" options={COMMON.DROPDOWNS.AMENDMENT_REQUEST_STATUS} value={formik.values.statusListStr} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>
						{(paramsRef.current.name || paramsRef.current.refNo || paramsRef.current.employeeId || paramsRef.current.statusListStr) && (
							<div className="amendment-status__results">
								<p className="amendment-status__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}
			</div>

			<AppAmendmentStatusModal ref={amendmentStatusModalRef} />
		</div>
	);
};

export default PageAmendmentStatus;
