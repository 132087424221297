import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo, useEffect } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

export const AppDownloadQuotationModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [documents, setDocuments] = useState([]);
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [selectedQuotation, setSelectedQuotation] = useState([]);
	const selectedAll = useMemo(() => {
		let total = documents.length;

		return selectedQuotation.length === total;
	}, [selectedQuotation, documents]);

	const onHandleGetDocuments = useCallback(async (id) => {
		let response = null;

		try {
			response = await api.get.policies.quotations(id);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setDocuments(response);
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		setVisible(true);
		onHandleGetDocuments(obj.renewal.id);
	}, [onHandleGetDocuments]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setSelectedQuotation([]);
		setDownloadProgress(0);
		setDocuments([]);
	}, []);

	//prettier-ignore
	const onHandleSelectPolicy = useCallback((event) => {
        const name = event.target.name;
        const isSelectedAllField = name === "all";

        if (isSelectedAllField) {
            let next = [];

            if(!selectedAll && documents.length) next = documents.map((a) => a.quotationNo.toString());
            
            setSelectedQuotation(next);
        } else {
            setSelectedQuotation((prev) => {
                const current = [...prev];
                const found = current.findIndex((o) => o === name) > -1;
                const next = current.filter((o) => o !== name);

                if (!found) next.push(name);

                return next;
            });
        }
    }, [documents, selectedAll]);

	const onHandleDownloadDocuments = useCallback(async () => {
		let response = null;
		let fileName = "";
		try {
			const onDownloadProgress = (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setDownloadProgress(percentCompleted);
			};

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];
	
				if (fileName) return data;
	
				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));
					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			const payload = { quotationNos: selectedQuotation };

			response = await api.post.policies.downloadQuotations(payload, { onDownloadProgress, transformResponse });
		} catch (error) {
			setDownloadProgress(0);
			serveRequestErrors(error);
		}
		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();
			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setDownloadProgress(0);
			}, 1000);
		}
	}, [selectedQuotation]);

	useEffect(() => {
		const onHandleScrollHeight = () => {
			const element = document.querySelector(".download-quotation__list");

			if (element) element.style.maxHeight = `${window.innerHeight - 400}px`;
		};

		const debouncedCheck = debounce(onHandleScrollHeight, 50);

		if (documents.length) onHandleScrollHeight();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [documents]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-download-quotation-modal" }} open={visible} aria-labelledby="download-quotation" aria-describedby="download-quotation-modal">
			<div className="download-quotation">
				<div className="download-quotation__header">
					<p className="download-quotation__title">Download Quotation</p>
					<p className="download-quotation__description">Please select document to download</p>
				</div>

				{!documents.length && (
					<div className="download-quotation__loading">
						<LoadingLogo />
					</div>
				)}

				{Boolean(documents.length) && (
					<ul className="download-quotation__list">
						<div className="download-quotation__list-header">
							All
							<AppCheckbox label="" onClick={onHandleSelectPolicy} name="all" value={selectedAll} />
						</div>

						{documents?.map((a) => {
							const quotationNo = a.quotationNo;
							const found = selectedQuotation?.findIndex((c) => c === quotationNo) > -1;

							return (
								<li className="download-quotation__item" key={quotationNo}>
									{quotationNo}
									<AppCheckbox label="" onClick={onHandleSelectPolicy} name={quotationNo} value={found} />
								</li>
							);
						})}
					</ul>
				)}

				<div className="download-quotation__progress-bar" style={{ width: `${downloadProgress}%` }} />

				<div className="download-quotation__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Download" onClick={onHandleDownloadDocuments} disabled={!!downloadProgress || !selectedQuotation.length} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDownloadQuotationModal));

AppDownloadQuotationModal.propTypes = {
	ref: PropTypes.object,
};
