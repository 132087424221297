import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import { promptAlertMessage } from "store/slices/alert";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import { formatCurrency } from "common/format-currency-pattern";
import getGeneralClaimListing from "services/get-general-claim-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppClaimModal from "components/pages/claim-management/app-claim-modal";
import AppTable, { AppTableEditIcon, AppTableCell } from "components/app-table";
import AppRejectReasonModal from "components/pages/claim-management/app-claim-reject-reason-modal";
import viewIcon from "assets/images/view-icon.svg";
import rejectIcon from "assets/images/components/pages/leave-management/reject-icon.svg";
import checkedIcon from "assets/images/components/pages/leave-management/checked-icon.svg";

const AppClaimApproval = (props) => {
	const dispatch = useDispatch();
	const tableRef = useRef();
	const claimModalRef = useRef();
	const searchInputRef = useRef();
	const rejectReasonRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
		name: searchParams.get("name") || "",
		claimType: searchParams.get("claimType") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const initialValues = useMemo(() => ({ name: "", claimType: "" }), []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { claimType: capitalizeCharacter(params.claimType?.split("_")?.join(" ")), name: params.name };

		return values;
	}, [searchParams]);

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.claims.pendingApprovalList(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	const onHandleRejectClaim = useCallback(() => {
		const { selectedFlatRows } = tableRef.current.onHandleGetSelectedRows();
		rejectReasonRef.current.onHandleShow(selectedFlatRows.map((o) => o.original.id));
	}, []);

	const onHandleApproveClaim = useCallback(async () => {
		let response = null;
		const { selectedFlatRows } = tableRef.current.onHandleGetSelectedRows();
		try {
			const payload = {
				ids: selectedFlatRows.map((o) => o.original.id),
			};
			await api.post.claims.approve(payload);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "Claim has been approved successfully" }));
			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	const onHandleViews = useCallback((id) => {
		claimModalRef.current.onHandleShow(id);
	}, []);

	const CreateDateCell = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.createdDate);
		return <AppTableCell left value={date} />;
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={viewIcon} onClick={() => onHandleViews(row.original.id)} />;
	}, [onHandleViews]);

	const AmountCell = useCallback(({ row }) => <AppTableCell right value={formatCurrency(row.original.claimValue)} />, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Username",
			accessor: "employeeDetail.personalInfo.name",
			disableSortBy: true,
		},
		{
			Header: "Claim Type",
			accessor: "claimTypeMapped",
			disableSortBy: true,
		},
		{
			Header: "Submit Date",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: CreateDateCell,
		},
		{
			Header: () => <div className="table__amount">Amount (RM)</div>,
			accessor: "claimValue",
			disableSortBy: true,
			Cell: AmountCell,
		},
		{
			Header: "",
			accessor: "**",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, CreateDateCell, AmountCell]);

	//prettier-ignore
	const checkerButtons = useMemo(() => [
		{ label: "Reject Claim", outline: true, icon: rejectIcon, onClick: onHandleRejectClaim },
		{ label: "Approve Claim", icon: checkedIcon, onClick: onHandleApproveClaim },
	], [onHandleRejectClaim, onHandleApproveClaim]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CLAIMS.PENDING_APPROVAL_LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-claim-approval">
			<div className="claim-approval">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter employee name" value={formik.values.name} onChange={formik.handleChange} />

										<AppSelectInput name="claimType" label="Claim Type" placeholder="Please select" loadOptions={getGeneralClaimListing} value={formik.values.claimType} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>

						{(paramsRef.current.employeeId || paramsRef.current.claimType) && (
							<div className="claim-approval__results">
								<p className="claim-approval__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable ref={tableRef} checker checkerButtons={checkerButtons} columns={columns} pages={data} onHandlePagination={onHandlePagination} />
					</Fragment>
				)}
			</div>

			<AppClaimModal ref={claimModalRef} />

			<AppRejectReasonModal ref={rejectReasonRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default AppClaimApproval;
