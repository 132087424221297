import React, { memo } from "react";

const AppOrdersIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="orders-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8 2L8 5.69231" stroke={color} strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M2 5.69238L14 5.69238L14 13.077C14 13.3218 13.9027 13.5566 13.7296 13.7297C13.5566 13.9028 13.3217 14.0001 13.0769 14.0001L2.92308 14.0001C2.67826 14.0001 2.44347 13.9028 2.27036 13.7297C2.09725 13.5566 2 13.3218 2 13.077L2 5.69238Z"
				stroke={color}
				strokeWidth="0.85"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 5.69231L3.38462 3.02462C3.53389 2.72394 3.76231 2.46966 4.04532 2.28912C4.32832 2.10858 4.65519 2.00863 4.99077 2L11.0092 2C11.3527 2.00018 11.6893 2.09617 11.9812 2.27718C12.2731 2.45819 12.5087 2.71704 12.6615 3.02462L14 5.69231"
				stroke={color}
				strokeWidth="0.85"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.2385 9.56486C10.1907 10.3225 9.80166 11.0186 9.07818 11.6466C8.61406 12.0492 8.30693 12.3154 8.11582 12.3154C7.92472 12.3154 7.63806 12.0629 7.16029 11.6466C6.43681 11.0186 6.04778 10.3225 6 9.56486L6 8.10426C6.98283 7.93363 7.67901 7.68109 8.12265 7.33301C8.25915 7.44221 8.42296 7.54459 8.61407 7.63332C8.62772 7.64014 8.64137 7.64697 8.65502 7.65379C9.06453 7.8449 9.5969 7.99506 10.2453 8.11109L10.2453 9.57169L10.2385 9.56486Z"
				stroke={color}
				strokeWidth="0.85"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.82218 10.1382L7.37172 9.71501C7.26934 9.61945 7.10553 9.62628 7.00998 9.72866C6.91443 9.83104 6.92125 9.99484 7.02363 10.0904L7.6652 10.691C7.71298 10.7388 7.77441 10.7593 7.84266 10.7593C7.84266 10.7593 7.84266 10.7593 7.84948 10.7593C7.91774 10.7593 7.97916 10.7251 8.02694 10.6774L9.32374 9.25089C9.41929 9.14851 9.41246 8.98471 9.30326 8.88915C9.20088 8.7936 9.03708 8.80042 8.94152 8.90963L7.82218 10.145L7.82218 10.1382Z"
				fill={color}
			/>
		</svg>
	);
};

export default memo(AppOrdersIcon);
