import React, { memo, useState, useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import AppArrowIcon from "components/icons/app-arrow-icon";
import AppForgotPasswordModal from "components/pages/login/app-forgot-password-modal";
import notificationIcon from "assets/images/components/app-header/notification-icon.svg";

const AppHeader = ({ title = "Dashboard", backTitle = "Back to lists", ...props }) => {
	const profile = useSelector((state) => state.profile);
	const navigate = useNavigate();
	const forgotPasswordRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const profileName = useMemo(() => profile?.fullName?.split(" ")?.[0]?.[0] || "-", [profile.fullName]);

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleOpenMenu = useCallback((event) => {
		setAnchorEl(event.target);
	}, []);

	const onHandleChangePassword = useCallback(() => {
		onhandleCloseMenu();
		forgotPasswordRef.current.onHandleShow();
	}, [onhandleCloseMenu]);

	return (
		<header className="app-header">
			<div className="header">
				<button id="header-menu" className="header__menu" onClick={props.onHandleToggleSideNav} />
				{title && <h1 className="header__title">{title}</h1>}
				{!title && (
					<button className="header__back" onClick={() => navigate(-1)}>
						<div className="header__back-icon">
							<AppArrowIcon color="#018897" />
						</div>
						{backTitle}
					</button>
				)}

				<div className="header__profile">
					<div className="header__content">
						<p className="header__name">{profile.fullName}</p>
						<p className="header__text">{profile?.role?.roleNameEn}</p>
					</div>

					<div className="header__image" onClick={onHandleOpenMenu}>
						{!profile.avatar && <p className="header__name">{profileName}</p>}
						{profile.avatar && <img src={profile.avatar} alt="profile" />}
					</div>

					<div className="header__notification">
						<img src={notificationIcon} alt="notifications" />
					</div>
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-header-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
                <MenuItem onClick={onHandleChangePassword}>
					<p className="app-header-menu__text">Change Password</p>
				</MenuItem>
            </Menu>

			<AppForgotPasswordModal ref={forgotPasswordRef} />
		</header>
	);
};

export default memo(AppHeader);
