import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

const otherReason = "Other reason";

const AppRejectPolicyRenewalModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ rejectReason: "", remark: "" }), []);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: yup.object({
			rejectReason: yup.string().required(ERRORS.REQUIRED),
			remark: yup.string().when(["rejectReason"], {
				is: (rejectReason) => rejectReason === otherReason,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { ebPolicyRenewalId: props.renewal.id, rejectReason: values.remark, rejectReasonCategory: values.rejectReason };

			await api.post.policies.rejectPolicyRenewal(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false)
		}

		if (response) {
			onHandleDismiss();
			props.onHandleDimissPolicyModal();
            dispatch(promptAlertMessage({ message: "Policy has been rejected." }));
		}
	}, [onHandleDismiss, formik, dispatch, props]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-reject-policy-renewal-modal" }} open={visible} aria-labelledby="reject-policy-renewal" aria-describedby="reject-policy-renewal-modal">
			<div className="reject-policy-renewal">
				<p className="reject-policy-renewal__title">Reason of Rejection</p>

				{/* prettier-ignore */}
				<AppSelectInput searchable={false} required type="text" name="rejectReason" label="Reject Reason" placeholder="Please Select" options={COMMON.DROPDOWNS.REJECT_POLICY_REASON} value={formik.values.rejectReason} touched={formik.touched.rejectReason} error={formik.errors.rejectReason} disabled={formik.isSubmitting} onChange={formik.handleChange} />

				{/* prettier-ignore */}
				<AppInput required multiline multilineRow={4} maxLength={255} type="text" name="remark" label="Reason" placeholder="Please specify your reason" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} disabled={formik.isSubmitting} onChange={formik.handleChange} />

				<div className="reject-policy-renewal__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Confirm" onClick={formik.handleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppRejectPolicyRenewalModal));

AppRejectPolicyRenewalModal.propTypes = {
	ref: PropTypes.object,
};
