const initialiseAmitabha = () => {
	const dev = process.env.NODE_ENV === "development";
	if (!dev) return;
	//pretter-ignore
	console.log(
		`%c
                              _oo0oo_
                             o8888888o
                            88 -ATF- 88
                             (| -_- |)
                             0\\  =  /0
                           ___/'---'\___
                        .' \\|        |// '.
                        / \\|||   :   |||// \\  
                       / _|||||  -:-  |||||- \  
                       |   | \\\  -  /// |   |
                       | \_|  ''\---/''  |_/ |
                       \  .-\__  '-'  ___/-. /
                     ___'. .'  /--.--\  '. .'___
                  ."" '<  '.___\_<|>_/___.' >' "".
                 | | :  '- \'.;'\ _ /';.'/ - ' : | |
                 \  \ '_.   \_ __\ /__ _/   .-' /  /
            ====='-.____'.___ \_____/___.-'___.-'=====
                              '=---='
                              
        ::::    :::  ::::::::       :::::::::  :::    :::  :::::::: 
        :+:+:   :+: :+:    :+:      :+:    :+: :+:    :+: :+:    :+: 
        :+:+:+  +:+ +:+    +:+      +:+    +:+ +:+    +:+ +:+         
    +#+ +:+ +#+ +#+    +:+      +#++:++#+  +#+    +:+ :#:          
    +#+  +#+#+# +#+    +#+      +#+    +#+ +#+    +#+ +#+   +#+#    
    #+#   #+#+# #+#    #+#      #+#    #+# #+#    #+# #+#    #+#     
    ###    ####  ########       #########   ########   ########`,
		`color: #a9a9a9`
	);
  	// eslint-disable-next-line react-hooks/exhaustive-deps
};
export default initialiseAmitabha;
