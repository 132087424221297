import React, { useCallback, useMemo, useRef, useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

import AppTabs from "components/app-tabs";
import AppEmployeeInfoTab from "components/pages/user-management/app-employee-info-tab";
import AppPersonalInfoTab from "components/pages/user-management/app-personal-info-tab";

const PageEmployee = (props) => {
	const { state } = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	//prettier-ignore
	const tabsNav = useMemo(() => [
		{ label: "Personal Info", value: "Personal Info" },
		{ label: "Employee Info", value: "Employee Info" },
	], []);
	const tabParams = useRef(searchParams.get("tab") || tabsNav[0].value);
	const memoSetSearchParams = useRef(setSearchParams);
	const [tab, setTab] = useState(tabParams.current);
	const [user, setUser] = useState();

	const Tabs = useCallback(() => {
		switch (tab) {
			case "Employee Info":
				return <AppEmployeeInfoTab />;
			case "Personal Info":
				return <AppPersonalInfoTab />;
			default:
				return null;
		}
	}, [tab]);

	const onHandleChangeTab = useCallback((v) => {
		setTab(v);
		memoSetSearchParams.current({ tab: v }, { replace: true });
	}, []);

	useEffect(() => {
		if (state) {
			sessionStorage.setItem("page-employee-user-info", JSON.stringify(state));
			setUser(state);
		} else {
			const data = sessionStorage.getItem("page-employee-user-info");
			if (data) setUser(JSON.parse(data));
		}
	}, [state]);

	return (
		<div className="page-employee">
			<div className="employee">
				<h1 className="employee__name">{user?.name}</h1>

				<AppTabs tabs={tabsNav} value={tab} onChange={onHandleChangeTab} />

				<div className="employee__container">
					<Tabs />
				</div>
			</div>
		</div>
	);
};

export default PageEmployee;
