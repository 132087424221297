import React, { memo, forwardRef, useImperativeHandle, useMemo, useRef, useState, useCallback, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import { CALENDAR_FORMAT, getISOString } from "common/calendar";
import getBankListing from "services/get-bank-listing";
import { promptAlertMessage } from "store/slices/alert";
import { validateNRIC, getIdDob } from "common/nric-helpers";
import serveRequestErrors from "common/serve-request-errors";
import formatStringPattern from "common/format-string-pattern";
import getPositionListing from "services/get-position-listing";
import getJobLevelListing from "services/get-job-level-listing";
import formatPassportString from "common/format-passport-string";
import getNationalityListing from "services/get-nationality-listing";
import getContractTypeListing from "services/get-contract-type-listing";
import getMaritalStatusListing from "services/get-marital-status-listing";
import getIdentificationListing from "services/get-identification-listing";
import getReportingManagerListing from "services/get-reporting-manager-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppMobileInput from "components/app-mobile-input";
import AppButtonInput from "components/app-button-input";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";
import AppCalendarInput from "components/app-calendar-input";
import checkedIcon from "assets/images/components/pages/user-management/checked-icon.svg";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

export const AppInviteEmployeeModal = (props, ref) => {
	const bankRef = useRef();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [invitations, setInvitations] = useState(0);
	const defaultOptions = useRef({ position: undefined, reportingManager: undefined });
	const isEmployeeInvitation = useMemo(() => props.type !== COMMON.EMPLOYEE.SETUP.SETUP, [props.type]);
	const getContractTypeOptions = useCallback(() => (isEmployeeInvitation ? getContractTypeListing() : getContractTypeListing(COMMON.EMPLOYEE.CONTRACT_TYPE.FULL_TIME_EMPLOYEE)), [isEmployeeInvitation]);
	//prettier-ignore
	const initialValues = useMemo(() => ({ name: "", email: "", prefixNo: "", mobileNo: "", idType: "", nric:"", passport: "", nationality: "", dateOfBirth: "", gender: "", position: "", level: "", contractType: "", dateJoined: "", reportingManager: "", bank: "", bankAccountNo: "", probationEndDate: "", maritalStatus: "", internshipEndDate: "", epfNo: "", socsoNo:"", incomeTaxNo: "", inviteAnother: false, purchaser: false }), []);

	function onHandleDuplicatedFields(current) {
		const self = this;

		const duplicated = props?.employees?.filter((o) => {
			if (self.path === "nric") {
				const value = formatStringPattern(o[self.path], COMMON.MASKING.NRIC);
				if (o.id !== self.parent.id) return value === current;
				else return false;
			} else if (o.id !== self.parent.id) {
				return o[self.path] === current;
			} else {
				return false;
			}
		});

		const isDuplicated = duplicated?.length >= 1;

		return !isDuplicated;
	}

	//prettier-ignore
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().test("validate duplicated field", ERRORS.DUPLICATED, onHandleDuplicatedFields).required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED),
			idType: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["idType"], {
				is: (idType) => idType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().test("validate duplicated field", ERRORS.DUPLICATED, onHandleDuplicatedFields).required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["idType"], {
				is: (idType) => idType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().test("validate duplicated field", ERRORS.DUPLICATED, onHandleDuplicatedFields).test("validate nric", ERRORS.INVALID_ID, validateNRIC).required(ERRORS.REQUIRED),
			}),
			nationality: yup.string().required(ERRORS.REQUIRED),
			dateOfBirth: yup.string().required(ERRORS.REQUIRED),
			gender: yup.string().required(ERRORS.REQUIRED),
			position: yup.string().required(ERRORS.REQUIRED),
			level: yup.string().required(ERRORS.REQUIRED),
			maritalStatus: yup.string().required(ERRORS.REQUIRED),
			contractType: yup.string().required(ERRORS.REQUIRED),
			dateJoined: yup.string().required(ERRORS.REQUIRED),
			bank: yup.string().required(ERRORS.REQUIRED),
			bankAccountNo: yup.string().required(ERRORS.REQUIRED),
			probationEndDate: yup.string().when(["contractType"], {
				is: (contractType) => contractType !== COMMON.STATUS_ID.INTERN,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			internshipEndDate: yup.string().when(["contractType"], {
				is: (contractType) => contractType === COMMON.STATUS_ID.INTERN,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});
	const memoSetValues = useMemo(() => formik.setValues, [formik]);
	const isPurchaser = useMemo(() => formik.values.purchaser, [formik.values.purchaser]);
	const isPassport = useMemo(() => formik.values.idType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.idType]);
	const intershipContractType = useMemo(() => formik.values.contractType === COMMON.STATUS_ID.INTERN, [formik.values.contractType]);
	const validIdentificationType = useMemo(() => !!formik.values.idType, [formik.values.idType]);

	const popupContent = useMemo(() => {
		const content = { title: "", description: "", button: "Save" };

		if (isPurchaser) {
			content.title = "Policy Owner Details";
			content.description = "You can fill all information and choose a insurance plan for yourself";
		} else {
			content.title = "Add Employee";
			content.description = "You can invite your employee by filling their information. \nInvitation link will only be sent to members once policy is In Force.";

			if (isEmployeeInvitation) content.button = "Add";
		}

		return content;
	}, [isPurchaser, isEmployeeInvitation]);
	//prettier-ignore
	const onHandleInviteAnother = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;
		formik.setFieldValue(name, !value);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateDob = useCallback((event) => {
		const value = event.target.value;
		const dob = getIdDob(value);
		formik.setFieldValue("dateOfBirth", dob ? getISOString(dob) : "");
	}, [formik]);

	//prettier-ignore
	const onHandleSelectIdentificationType = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;
		const currentValue = formik.values[name];
		const isNricType = value === COMMON.IDENTIFICATION_TYPE.NRIC;

		const payload = { ...formik.values };
		
		if(currentValue !== value) {
			payload.dateOfBirth = "";
			
			if(isNricType) { 
				payload.passport = "";
				payload.nationality = COMMON.NATIONALITY.MALAYSIAN;
			} else {
				payload.nric = "";
				payload.nationality = COMMON.NATIONALITY.FOREIGNER;
			}
		}
		
		payload[name] = value;
		
		formik.setValues(payload);
	}, [formik]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (data) => {
		const purchaserData = data.purchaser;
		
		let response = null;
		
		if(purchaserData) {
			response = data;
		} else {
			try {
				const payload = { email: data.email };

				response = await api.get.setup.employee(payload);
			} catch (error) {
				serveRequestErrors(error);
			}
		}
		
		if (response) {
			const payload = {
				id: response.id,
				name: response.fullName,
				email: response.email,
				prefixNo: response.mobileNoPrefix || "",
				mobileNo: response.mobileNo || "",
				idType: response.identificationType || "",
				nric: response?.nric ? formatStringPattern(response.nric, COMMON.MASKING.NRIC) : "",
				passport: response.passport || "",
				dateOfBirth: response.identificationType ? (response.dateOfBirth || "") : "",
				gender: response.gender || "",
				position: response.position || "",
				level: response.level || "",
				contractType: response.contractType || "",
				dateJoined: response.dateJoined || "",
				maritalStatus: response.maritalStatus || "",
				reportingManager: response.reportingManagerId || "",
				bank: response.bankName || "",
				bankAccountNo: response.bankAccountNo || "",
				probationEndDate: response.probationEndDate || "",
				internshipEndDate: response.internshipEndDate || "",
				epfNo: response.epfNo || "",
				socsoNo: response.socsoNo || "",
				incomeTaxNo: response.incomeTaxNo || "",
				purchaser: !!purchaserData,
			};

			switch(response.identificationType) {
				case COMMON.IDENTIFICATION_TYPE.NRIC:
					payload.nationality = COMMON.NATIONALITY.MALAYSIAN;
				break;
				case COMMON.IDENTIFICATION_TYPE.PASSPORT:
					payload.nationality = COMMON.NATIONALITY.FOREIGNER;
				break;
				default: 
					payload.nationality = "";
				break;
			}
		
			memoSetValues(payload);

			if (response.position) {
				defaultOptions.current.position = { label: response.position, value: response.position };
			}

			if (response.reportingManager) {
				defaultOptions.current.reportingManager = { label: response.reportingManagerName, value: response.reportingManagerId };
			}
		}
	}, [memoSetValues]);

	//prettier-ignore
	const onHandleShow = useCallback(async (data) => {
		setVisible(true);
		
		if(data) onHandleGetDetails(data);
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		formik.resetForm();
		setVisible(false);
	}, [formik]);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				id: values.id || null,
				name: values.name,
				identificationType: values.idType,
				passport: values.idType === COMMON.IDENTIFICATION_TYPE.PASSPORT ? values.passport : "",
				nric: values.idType === COMMON.IDENTIFICATION_TYPE.NRIC ? values.nric?.split("-")?.join("") : "",
				nationality: values.nationality,
				email: values.email,
				mobileNo: values.mobileNo,
				mobileNoPrefix: values.prefixNo,
				dateOfBirth: values.dateOfBirth,
				gender: values.gender,
				position: values.position,
				level: values.level,
				maritalStatus: values.maritalStatus,
				reportingManagerId: values.reportingManager,
				dateJoined: values.dateJoined,
				probationEndDate: values.probationEndDate,
				bankName: values.bank,
				contractType: values.contractType,
				bankNameDesc: bankRef.current.getOptionsData().find((o) => o.value === values.bank).label,
				bankAccountNo: values.bankAccountNo,
				internshipEndDate: values.internshipEndDate,
				epfNo: values.epfNo,
				socsoNo: values.socsoNo,
				incomeTaxNo: values.incomeTaxNo,
			};

			if(values.purchaser) {
				await api.post.setup.updatePurchaser(payload);
			}
			else if (isEmployeeInvitation) {
				await api.post.employee.create(payload);
			}
			else {
				await api.post.setup.invite(payload);
			}
			
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: values.purchaser ? "Information has been updated successfully" : "Invitation link will only be sent to members once policy is In Force." }));

			if (!values.inviteAnother) {
				onHandleDismiss();
			} else {
				setInvitations((prev) => prev + 1);
			}

			if(props.onHandleGetList) props.onHandleGetList()
		}
	}, [props, formik, dispatch, onHandleDismiss, isEmployeeInvitation]);

	const IdentificationField = useCallback((obj) => {
		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name="nric" label="NRIC No." placeholder="Nric No." disabled={obj.disabled} value={obj.values.nric} error={obj.errors.nric} touched={obj.touched.nric} onChange={obj.onChange} format={COMMON.MASKING.NRIC} onBlur={obj.onBlur} />
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name="passport" label="Passport" placeholder="Passport" disabled={obj.disabled} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-invite-employee-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="invite-employee-modal" aria-describedby="invite-employee-modal">
			<div className="invite-employee-modal">
				<div className="invite-employee-modal__main">
					<div className="invite-employee-modal__header">
						<p className="invite-employee-modal__title">{popupContent.title}</p>
						<p className="invite-employee-modal__description">{popupContent.description}</p>
					</div>

					<form className="invite-employee-modal__body" onSubmit={formik.handleSubmit}>
						{/* prettier-ignore */}
						<AppInput required type="text" name="name" label="Full Name" placeholder="Full Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} disabled={isPurchaser || formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppInput required type="text" name="email" label="Email Address" placeholder="An invitation will be send to this Email Address" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} disabled={isPurchaser || formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppMobileInput required type="number" name="mobileNo" label="Mobile No." placeholder="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} disabled={isPurchaser || formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="idType" label="Identification Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.idType} error={formik.errors.idType} touched={formik.touched.idType} onChange={onHandleSelectIdentificationType} disabled={formik.isSubmitting}  />

						{/* prettier-ignore */}
						<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} onBlur={onHandleUpdateDob} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="nationality" label="Nationality" placeholder="Please Select" disabled loadOptions={getNationalityListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppCalendarInput required name="dateOfBirth" label="Date of Birth" placeholder="01 Jan 2023" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.dateOfBirth} error={formik.errors.dateOfBirth} touched={formik.touched.dateOfBirth} disabled={!isPassport || formik.isSubmitting} onChange={formik.setFieldValue} />

						{/* prettier-ignore */}
						<AppButtonInput required name="gender" label="Gender" disabled={formik.isSubmitting} value={formik.values.gender} error={formik.errors.gender} touched={formik.touched.gender} onChange={formik.setFieldValue} options={COMMON.DROPDOWNS.GENDER} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="maritalStatus" label="Marital Status" placeholder="Please Select" disabled={formik.isSubmitting} loadOptions={getMaritalStatusListing} value={formik.values.maritalStatus} error={formik.errors.maritalStatus} touched={formik.touched.maritalStatus} onChange={formik.handleChange} />

						<div className="invite-employee-modal__wrapper">
							{/* prettier-ignore */}
							<AppSelectInput pagination required type="text" name="position" label="Position" placeholder="Please Select" defaultOptions={defaultOptions.current.position} loadOptions={getPositionListing} value={formik.values.position} error={formik.errors.position} touched={formik.touched.position} onChange={formik.handleChange} disabled={formik.isSubmitting} />
							{isEmployeeInvitation && (
								<p className="invite-employee-modal__link">
									<Link to={pathnames.companyBenefits.positions}>Click here</Link> to add/edit a position
								</p>
							)}
						</div>

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="level" label="Level" placeholder="Please Select" loadOptions={getJobLevelListing} value={formik.values.level} error={formik.errors.level} touched={formik.touched.level} onChange={formik.handleChange} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppSelectInput pagination type="text" name="reportingManager" label="Reporting Manager" placeholder="Please Select" defaultOptions={defaultOptions.current.reportingManager} loadOptions={getReportingManagerListing} value={formik.values.reportingManager} error={formik.errors.reportingManager} touched={formik.touched.reportingManager} onChange={formik.handleChange} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="contractType" label="Contract Type" placeholder="Please Select" loadOptions={getContractTypeOptions} value={formik.values.contractType} error={formik.errors.contractType} touched={formik.touched.contractType} onChange={formik.handleChange} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppCalendarInput required name="dateJoined" label="Join Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.dateJoined} error={formik.errors.dateJoined} touched={formik.touched.dateJoined} onChange={formik.setFieldValue} disabled={formik.isSubmitting} />

						{
							/* prettier-ignore */ !intershipContractType && <AppCalendarInput required name="probationEndDate" label="Probation End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.probationEndDate} error={formik.errors.probationEndDate} touched={formik.touched.probationEndDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />
						}

						{
							/* prettier-ignore */ intershipContractType && <AppCalendarInput required name="internshipEndDate" label="Internship End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.internshipEndDate} error={formik.errors.internshipEndDate} touched={formik.touched.internshipEndDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />
						}

						{/* prettier-ignore */}
						<AppSelectInput required ref={bankRef} type="text" name="bank" label="Bank Name" placeholder="Please Select" disabled={formik.isSubmitting} loadOptions={getBankListing} value={formik.values.bank} error={formik.errors.bank} touched={formik.touched.bank} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput required type="number" name="bankAccountNo" label="Bank Account No." placeholder="Bank Account No." disabled={formik.isSubmitting} value={formik.values.bankAccountNo} error={formik.errors.bankAccountNo} touched={formik.touched.bankAccountNo} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput type="text" name="epfNo" label="EPF No." placeholder="EPF No." disabled={formik.isSubmitting} value={formik.values.epfNo} error={formik.errors.epfNo} touched={formik.touched.epfNo} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput type="text" name="incomeTaxNo" label="Income Tax No." placeholder="Income Tax No." disabled={formik.isSubmitting} value={formik.values.incomeTaxNo} error={formik.errors.incomeTaxNo} touched={formik.touched.incomeTaxNo} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput type="text" name="socsoNo" label="Socso No." placeholder="Socso No." disabled={formik.isSubmitting} value={formik.values.socsoNo} error={formik.errors.socsoNo} touched={formik.touched.socsoNo} onChange={formik.handleChange} />

						<div className="invite-employee-modal__footer">
							{isEmployeeInvitation && (
								<Fragment>
									<AppCheckbox onClick={onHandleInviteAnother} label="Invite Another Employee" name="inviteAnother" value={formik.values.inviteAnother} disabled={formik.isSubmitting} />

									{invitations > 0 && (
										<p className="invite-employee-modal__invited">
											<img src={checkedIcon} alt="invitation sent" />
											{invitations} invitation sent
										</p>
									)}
								</Fragment>
							)}

							<div className="invite-employee-modal__button-container">
								<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} disabled={formik.isSubmitting} />
								<AppButton type="submit" label={popupContent.button} disabled={formik.isSubmitting} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInviteEmployeeModal));

AppInviteEmployeeModal.propTypes = {
	ref: PropTypes.object,
};
