import COMMON from "common";
import pathnames from "routes/pathnames";
import AppLayout from "components/app-layout";
import { publicRoutes, protectedRoutes } from "common/auth-routes";

import Page404 from "pages/page-404";
import PageMaintenance from "pages/page-maintenance";
import PageLogin from "pages/page-login";
import PageAccountSuspended from "pages/page-account-suspended";
import PageSetup from "pages/setup/page-setup";
import PageSetupCoverages from "pages/setup/page-setup-coverages";
import PageDashboard from "pages/page-dashboard";
import PageAdmins from "pages/user-management/page-admins";
import PageEmployee from "pages/user-management/page-employee";
import PageEmployees from "pages/user-management/page-employees";
import PageAmendmentStatus from "pages/user-management/page-amendment-status";
import PageBenefits from "pages/company-benefits/page-benefits";
import PageBenefit from "pages/company-benefits/page-benefit";
import PagePositions from "pages/company-benefits/page-positions";
import PageLeaves from "pages/leave-management/page-leaves";
import PageLeave from "pages/leave-management/page-leave";
import PageLeavesDirectory from "pages/leave-management/page-leaves-directory";
import PageLeavesApproval from "pages/leave-management/page-leaves-approval";
import PageClaimApproval from "pages/claim-management/page-claim-approval";
import PageClaimDirectory from "pages/claim-management/page-claim-directory";
import PageClaim from "pages/claim-management/page-claim";
import PageOrders from "pages/page-orders";
import PagePolicy from "pages/insurance-coverage/page-policy";
import PageCoverages from "pages/insurance-coverage/page-coverages";
import PageInsuranceClaim from "pages/insurance-coverage/page-insurance-claim";
import PageSelectClaimType from "pages/insurance-coverage/page-select-claim-type";
import PageUploadClaimDocuments from "pages/insurance-coverage/page-upload-claim-documents";

const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

const routes = [
	{ path: pathnames.login, element: <PageLogin />, authenticated: false },
	{ path: pathnames.accountSuspended, element: <PageAccountSuspended />, authenticated: false },
	{ path: pathnames.setup.setup, element: <PageSetup />, authenticated: true },
	{ path: pathnames.setup.setupCoverages, element: <PageSetupCoverages />, authenticated: true },
	{ path: pathnames.dashboard, element: <PageDashboard />, authenticated: true },
	{ path: pathnames.userManagement.employees, element: <PageEmployees />, authenticated: true },
	{ path: pathnames.userManagement.employee + ":id", element: <PageEmployee />, authenticated: true },
	{ path: pathnames.userManagement.amendmentStatus, element: <PageAmendmentStatus />, authenticated: true },
	{ path: pathnames.userManagement.admins, element: <PageAdmins />, authenticated: true },
	{ path: pathnames.companyBenefits.positions, element: <PagePositions />, authenticated: true },
	{ path: pathnames.companyBenefits.benefits, element: <PageBenefits />, authenticated: true },
	{ path: pathnames.companyBenefits.benefit + ":id", element: <PageBenefit />, authenticated: true },
	{ path: pathnames.leaveManagement.leaves, element: <PageLeaves />, authenticated: true },
	{ path: pathnames.leaveManagement.leave + ":id", element: <PageLeave />, authenticated: true },
	{ path: pathnames.leaveManagement.leaveDirectory, element: <PageLeavesDirectory />, authenticated: true },
	{ path: pathnames.leaveManagement.leavesApproval, element: <PageLeavesApproval />, authenticated: true },
	{ path: pathnames.claimManagement.claimDirectory, element: <PageClaimDirectory />, authenticated: true },
	{ path: pathnames.claimManagement.claimApproval, element: <PageClaimApproval />, authenticated: true },
	{ path: pathnames.claimManagement.claim + ":id", element: <PageClaim />, authenticated: true },
	{ path: pathnames.orders, element: <PageOrders />, authenticated: true },
	{ path: pathnames.insuranceCoverage.policy, element: <PagePolicy />, authenticated: true },
	{ path: pathnames.insuranceCoverage.coverages, element: <PageCoverages />, authenticated: true },
	{ path: pathnames.insuranceCoverage.claim, element: <PageInsuranceClaim />, authenticated: true },
	{ path: pathnames.insuranceCoverage.selectClaimType, element: <PageSelectClaimType />, authenticated: true },
	{ path: pathnames.insuranceCoverage.uploadClaimDocuments, element: <PageUploadClaimDocuments />, authenticated: true },
	{ path: pathnames.maintenance, element: <PageMaintenance />, authenticated: false, loader: () => true },
	{ path: "*", element: <Page404 />, authenticated: !!token },
];

const enrichedRouters = routes.map(({ authenticated, ...res }) => {
	if (!authenticated) return { ...res, loader: res.loader ?? publicRoutes };

	return { ...res, element: <AppLayout>{res.element}</AppLayout>, errorElement: <Page404 />, loader: protectedRoutes };
});

export default enrichedRouters;
