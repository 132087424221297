import React, { forwardRef, useCallback, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";

import COMMON from "common";
import api from "services/api";
import { setProfile } from "store/slices/profile";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";

const withProfile = (WrappedComponent) => {
	return forwardRef(({ ...props }, ref) => {
		const dispatch = useDispatch();
		const context = useContext(AxiosContext);

		//prettier-ignore
		const onHandleGetProfile = useCallback(async (callback) => {
			let response = null;
			let companyResponse = null;
	
			try {
				response = await api.get.general.profile();
				companyResponse = await api.get.general.company();
			} catch (error) {
				serveRequestErrors(error);
			}
	
			if (response && companyResponse) dispatch(setProfile({ ...response, company: companyResponse }));
			if(callback) callback(response);
		}, [dispatch]);

		const onHandleCancelProfile = useCallback(() => {
			context?.onHandleCancelRequest(COMMON.ENDPOINT_PATH.GENERAL.PROFILE);
			context?.onHandleCancelRequest(COMMON.ENDPOINT_PATH.GENERAL.COMPANY);
		}, [context]);

		const profileProps = useMemo(() => ({ onHandleGetProfile, onHandleCancelProfile }), [onHandleGetProfile, onHandleCancelProfile]);

		return <WrappedComponent {...props} {...context} {...profileProps} ref={ref} />;
	});
};
export default withProfile;
