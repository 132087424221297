import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";

export const AppConfirmDeleteModal = (props, ref) => {
	const dispatch = useDispatch();
	const [id, setId] = useState();
	const [visible, setVisible] = useState(false);
	const [isSubmiting, setIsSubmiting] = useState(false);

	const onHandleShow = useCallback((data) => {
		setId(data);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleConfirm = useCallback(async () => {
		let response = null;

		setIsSubmiting(true);

		try {
			await api.post.leaves.cancel(id);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmiting(false);
		}

		if (response) {
			onHandleDismiss();
			dispatch(promptAlertMessage({ message: "Leave has been cancelled successfully" }));
			props.onHandleGetList()
		}
	}, [dispatch, onHandleDismiss, id, props]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-confirm-delete-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="confirm-delete-modal" aria-describedby="confirm-delete-modal">
			<div className="confirm-delete-modal">
				<div className="confirm-delete-modal__main">
					<div className="confirm-delete-modal__header">
						<p className="confirm-delete-modal__title">Confirm Leave Cancellation</p>
						<p className="confirm-delete-modal__description">Are you sure you want to cancel the leave application? This action cannot be undone. The canceled leave days will be returned to the employee leave balance.</p>
					</div>

					<div className="confirm-delete-modal__button-container">
						<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} disabled={isSubmiting} />
						<AppButton type="button" label="Confirm" onClick={onHandleConfirm} disabled={isSubmiting} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppConfirmDeleteModal));

AppConfirmDeleteModal.propTypes = {
	ref: PropTypes.object,
};
