import React, { memo, forwardRef, useImperativeHandle, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import withProfile from "contexts/with-profile";
import { setCompany, setLogo } from "store/slices/profile";
import getCompanyListing from "services/get-company-listing";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

export const AppSelectCompanyModal = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const companyOptionsRef = useRef();
	const [visible, setVisible] = useState(false);
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [selectCompany, setSelectCompany] = useState(profile.company?.businessRegistrationNo || "");

	const onHandleShow = useCallback(async () => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		if (!profile.company?.name) {
			setVisible(false);
			localStorage.clear();
			sessionStorage.clear();
			dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
			navigate(pathnames.login);
		} else {
			setVisible(false);
			setIsSubmiting(false);
		}
	}, [profile, dispatch, navigate]);

	const onHandleChange = useCallback((event) => {
		const value = event.target.value;
		setSelectCompany(value);
	}, []);

	const onHandleUpdateCompany = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.general.company();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const isIncompleteSetupCompany = response.setupFlag === COMMON.EMPLOYEE.SETUP.INCOMPLETE;
			const isOrderCompletedCompany = response.orderStatus === COMMON.EMPLOYEE.SETUP.ORDER_COMPLETED;

			dispatch(setCompany(response));

			if (isIncompleteSetupCompany) {
				navigate(pathnames.setup.setup, { replace: true });
			} else if (!isOrderCompletedCompany && !isIncompleteSetupCompany) {
				navigate(pathnames.orders, { replace: true });
			} else {
				navigate(pathnames.dashboard, { replace: true });
			}
		}
	}, [dispatch, navigate]);

	//prettier-ignore
	const onHandleGetLogo = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.general.logo();
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmiting(false);
		}

		if(response) {
			setVisible(false);
			
			onHandleUpdateCompany();

			if (response) dispatch(setLogo(response?.url || ""));
		}

	}, [dispatch, onHandleUpdateCompany]);

	const onHandleComfirm = useCallback(async () => {
		let response = null;
		setIsSubmiting(true);

		try {
			await api.post.authenticate.user(selectCompany);
			response = true;
		} catch (error) {
			setIsSubmiting(false);
			serveRequestErrors(error);
		}

		//Delay for Backend need to know the which company selected
		if (response) setTimeout(() => onHandleGetLogo(selectCompany), 1000);
	}, [selectCompany, onHandleGetLogo]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-select-company" }} open={visible} aria-labelledby="select-company" aria-describedby="select-company-modal">
			<div className="select-company">
				<div className="select-company__header">
					<p className="select-company__title">Select a Company to access</p>
					<p className="select-company__description">Please select your company from the list below. This will ensure that you have access to the relevant resources and information specific to your organization.</p>
				</div>

				{/* prettier-ignore */}
				<AppSelectInput required ref={companyOptionsRef} type="text" name="company" label="Company" placeholder="Please Select" value={selectCompany} disabled={isSubmiting} loadOptions={getCompanyListing} onChange={onHandleChange} />

				<div className="select-company__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Confirm" onClick={onHandleComfirm} disabled={isSubmiting || !selectCompany} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(withProfile(forwardRef(AppSelectCompanyModal)));

AppSelectCompanyModal.propTypes = {
	ref: PropTypes.object,
};
