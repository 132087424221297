import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getCitiesListing = async (params) => {
	let response = null;
	let errorMessage = null;

	try {
		const payload = { size: 90, page: 0, state: params.state, sort: "label,asc" };
		const res = await api.get.general.cities(payload);
		const content = res.content.map((o) => ({ label: o.city, value: o.city }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getCitiesListing;
