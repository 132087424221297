import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import getClaimantsListing from "services/get-claimant-listing";
import getCoveragePoliciesListing from "services/get-coverage-policies-listing";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

const AppClaimantModal = (props, ref) => {
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ policyNo: "", claimant: "" }), []);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: yup.object({
			policyNo: yup.string().required(ERRORS.REQUIRED),
			claimant: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			navigate(pathnames.insuranceCoverage.selectClaimType, { state: values });
		},
	});

	const getClaimantListing = useCallback(() => getClaimantsListing(formik.values.policyNo), [formik.values.policyNo]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-claimant-modal" }} open={visible} aria-labelledby="claimant" aria-describedby="claimant-modal">
			<div className="claimant">
				<p className="claimant__title">Claims for</p>

				{/* prettier-ignore */}
				<AppSelectInput required type="text" name="policyNo" label="Policy No." placeholder="Please Select" loadOptions={getCoveragePoliciesListing} value={formik.values.policyNo} touched={formik.touched.policyNo} error={formik.errors.policyNo} onChange={formik.handleChange} />

				{/* prettier-ignore */}
				<AppSelectInput required disabled={!formik.values.policyNo} type="text" name="claimant" label="Claimant Name" placeholder="Please Select" loadOptions={getClaimantListing} value={formik.values.claimant} touched={formik.touched.claimant} error={formik.errors.claimant} onChange={formik.handleChange} />

				<div className="claimant__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Confirm" onClick={formik.handleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppClaimantModal));

AppClaimantModal.propTypes = {
	ref: PropTypes.object,
};
