import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import { useDispatch } from "react-redux";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";

const AppUpdateCoverageModal = (props, ref) => {
	const dispatch = useDispatch();
	const [employees, setEmployees] = useState([]);
	const [visible, setVisible] = useState(false);

	const onHandleShow = useCallback((obj) => {
		setVisible(true);
		setEmployees(obj);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleConfirm = useCallback(async () => {
		let response = null;

		const invalidItem = employees.find((o) => !o.effectiveDate && !o.ebInsuranceEffectiveDate && o.ebInsurancePlan !== COMMON.INSURANCE.PLANS.NOT_COVERED && !o.newBusinessRegNo);

		if (invalidItem) {
			setVisible(false);
			return serveRequestErrors(`Provide a effective date to ${invalidItem.name}.`);
		}

		try {
			const payload = {
				changes: employees.map((o) => {
					const item = {};

					item.employeeDetailId = o.id;
					item.effectiveDate = o.effectiveDate || o.ebInsuranceEffectiveDate;
					item.newEbInsurancePlan = o.ebInsurancePlan !== COMMON.INSURANCE.PLANS.NOT_COVERED ? o.ebInsurancePlan : null;
					item.newBusinessRegNo = o.newBusinessRegNo;

					return item;
				}),
			};

			await api.post.coverages.updateInsurerCoverage(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "Request has been sent. You will receive an invoice shortly." }));

			onHandleDismiss();

			props.onHandleDisabledEdit();

			props.onHandleGetList();

			props.onHandleGetInsurancePlanList();
		}
	}, [employees, onHandleDismiss, dispatch, props]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-update-coverage-modal" }} open={visible} aria-labelledby="update-coverage" aria-describedby="update-coverage-modal">
			<div className="update-coverage">
				<p className="update-coverage__title">Confirmation</p>
				<p className="update-coverage__description">Confirm the changes to the insurance plan? Adjustment bill if applicable will be issued accordingly.</p>

				<div className="update-coverage__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppUpdateCoverageModal));

AppUpdateCoverageModal.propTypes = {
	ref: PropTypes.object,
};
