import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import { formatDatePattern, formatDateTime } from "common/calendar";
import converReadableFileSize from "common/convert-readable-file-size";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";

export const AppAmendmentStatusModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);

	const onHandleShow = useCallback(async (obj) => {
		let response = null;

		try {
			response = await api.get.amendmentRequest.details(obj.id);
		} catch (error) {
			serveRequestErrors(error);
		}
		if (response) setData(response);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	const summary = useMemo(() => {
		const info = [
			{ label: "Reference No.", value: data.refNo },
			{ label: "Policy No.", value: data.policyNo },
			{ label: "Employee ID", value: data.employeeId || "-" },
			{ label: "Employee Name", value: data.name },
		];

		return info;
	}, [data]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-amendment-status-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="amendment-status-modal" aria-describedby="employee-profile-modal">
			<div className="amendment-status-modal">
				<div className="amendment-status-modal__main">
					<button className="amendment-status-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="amendment-status-modal__header">
						<h1 className="amendment-status-modal__name">{data.name}</h1>

						<p className="amendment-status-modal__ref-no">{data.refNo}</p>

						<p className="amendment-status-modal__date">Amendment Submission Date: {formatDateTime(new Date(data.createdDate))}</p>
					</div>

					<div className="amendment-status-modal__info">
						<div className="amendment-status-modal__box">
							<p className="amendment-status-modal__text">Employee ID</p>
							<p className="amendment-status-modal__title">{data.employeeId || "-"}</p>
						</div>
						<div className="amendment-status-modal__box">
							<p className="amendment-status-modal__text">Status</p>
							<div className="amendment-status-modal__title">
								<AppStatus status={data.status} />
							</div>
						</div>
					</div>

					<div className="amendment-status-modal__body">
						<p className="amendment-status-modal__title">Amendment Request Summary</p>

						<ul className="amendment-status-modal__list">
							{summary.map((o, i) => {
								return (
									<li className="amendment-status-modal__item" key={i}>
										<p className="amendment-status-modal__label">{o.label}</p>
										<p className="amendment-status-modal__value">{o.value}</p>
									</li>
								);
							})}
						</ul>

						<p className="amendment-status-modal__title">Amendment Details</p>

						<div className="amendment-status-modal__table">
							<div className="amendment-status-modal__table-header">
								<div className="amendment-status-modal__table-header-cell">
									<p className="amendment-status-modal__table-title"></p>
								</div>
								<div className="amendment-status-modal__table-header-cell">
									<p className="amendment-status-modal__table-title">Old</p>
								</div>
								<div className="amendment-status-modal__table-header-cell">
									<p className="amendment-status-modal__table-title">New</p>
								</div>
							</div>

							{data?.beforeValues?.name && (
								<div className="amendment-status-modal__table-body">
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">Full Name</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">{data.beforeValues.name}</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-value">{data.afterValues.name}</p>
									</div>
								</div>
							)}

							{data?.beforeValues?.identificationType && (
								<div className="amendment-status-modal__table-body">
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">Identification Type.</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">{data.beforeValues.identificationType}</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-value">{data.afterValues.identificationType}</p>
									</div>
								</div>
							)}

							{(data?.beforeValues?.nric || data?.beforeValues?.passport) && (
								<div className="amendment-status-modal__table-body">
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">NRIC No./Passport No.</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">{data.beforeValues.nric || data.beforeValues.passport}</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-value">{data.afterValues.nric || data.afterValues.passport}</p>
									</div>
								</div>
							)}

							{data?.beforeValues?.nationality && (
								<div className="amendment-status-modal__table-body">
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">Nationality</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">{data.beforeValues.nationality}</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-value">{data.afterValues.nationality}</p>
									</div>
								</div>
							)}

							{data?.beforeValues?.dateOfBirth && (
								<div className="amendment-status-modal__table-body">
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">Date of Birth</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-label">{formatDatePattern(data.beforeValues.dateOfBirth)}</p>
									</div>
									<div className="amendment-status-modal__table-body-cell">
										<p className="amendment-status-modal__table-value">{formatDatePattern(data.afterValues.dateOfBirth)}</p>
									</div>
								</div>
							)}
						</div>

						<p className="amendment-status-modal__title">Uploaded</p>

						<div className="amendment-status-modal__table amendment-status-modal__table--files">
							{data.uploadedDocs?.map((o, i) => {
								return (
									<div className="amendment-status-modal__table-body" key={i}>
										<div className="amendment-status-modal__table-body-cell">
											<a className="amendment-status-modal__table-link" href={o.filePath} target="_blank" rel="noopener noreferrer">
												{o.fileName}
											</a>
										</div>
										<div className="amendment-status-modal__table-body-cell">
											<p className="amendment-status-modal__table-file-size">{converReadableFileSize(o.fileSize, true)}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAmendmentStatusModal));

AppAmendmentStatusModal.propTypes = {
	ref: PropTypes.object,
};
