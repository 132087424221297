import React, { useRef, useCallback, useMemo, useEffect, useState, useContext, Fragment } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import COMMON from "common";
import api from "services/api";
import sanitizeObject from "common/sanitize-object";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppEmptyState from "components/app-empty-state";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import AppConfirmDeleteModal from "components/pages/leave-management/app-confirm-delete-leave";
import pdfIcon from "assets/images/pdf-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";
import sortbyIcon from "assets/images/components/app-table/sortby-icon.svg";

const AppLeaveHistory = () => {
	const { id } = useParams();
	const confirmDeleteRef = useRef();
	const context = useContext(AxiosContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const paramsRef = useRef({ statusListStr: searchParams.get("statusListStr") || "CANCELLED,APPROVED,REJECTED", page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", tab: "Leave History" });
	const filterDefaultStatus = useMemo(() => {
		const params = searchParams.get("statusListStr");
		return { approved: params?.includes("APPROVED"), rejected: params?.includes("REJECTED"), cancelled: params?.includes("CANCELLED") };
	}, [searchParams]);
	const [filterStatus, setFilterStatus] = useState(filterDefaultStatus);
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.leaves.list(sanitizeObject({ ...payload, id }));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, [id]);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	const onHandleStatusMenu = useCallback((event) => {
		const element = event.currentTarget;
		if (element) element.classList.add("table__sort--active");
		setAnchorEl(event.currentTarget);
	}, []);

	const onhandleCloseMenu = useCallback(() => {
		const element = document.querySelector(".table__sort");
		const filtered = Object.keys(filterStatus).some((o) => filterStatus[o]);

		if (filtered && element) element.classList.add("table__sort--active");
		else if (element) element.classList.remove("table__sort--active");

		setAnchorEl(null);
		onHandleGetList();
	}, [filterStatus, onHandleGetList]);

	//prettier-ignore
	const onHandleFilterStatus = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;

		setFilterStatus((prev) => {
			let status = { ...prev, [name]: !value };
            const allDisabled = Object.keys(status).map(o => status[o]).filter(o => o).length === 0;
            if(allDisabled) status = { ...prev, [name]: true };
			
            paramsRef.current.statusListStr = Object.keys(status).map((o) => (status[o] ? o.toUpperCase() : null)).filter((o) => o).join(",");

			return status;
		});
	}, []);

	//prettier-ignore
	const SortStatusButton = useCallback(() => {
		return <button className="table__sort table__sort--active" type="button" onClick={onHandleStatusMenu}><img src={sortbyIcon} alt="sort status" /></button>
	}, [onHandleStatusMenu]);

	const onHandleViews = useCallback((id) => {
		confirmDeleteRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		const isApproval = COMMON.STATUS_ID.APPROVED === row.original.status;
		if(!isApproval) return null;
		return <AppTableEditIcon icon={removeIcon} onClick={() => onHandleViews(row.original.id)} />; 
	}, [onHandleViews]);

	const StartDateCell = useCallback(({ row }) => <AppTableCell left value={formatDatePattern(row.original.companyLeaveApplicationDetails[0].date) || "-"} />, []);

	const EndDateCell = useCallback(({ row }) => <AppTableCell left value={formatDatePattern(row?.original?.companyLeaveApplicationDetails?.[row?.original?.companyLeaveApplicationDetails?.length - 1]?.date) || "-"} />, []);

	const StatusCell = useCallback(({ row }) => <AppStatus status={row.values.status} />, []);

	//prettier-ignore
	const AttachmentCell = useCallback(({ row }) => {
		const file = row.original?.companyLeaveApplicationFiles?.[0];
		return (<AppTableCell center value={file ? (<a href={file?.fileURL} target="_blank" rel="noopener noreferrer"><img className="table__file" src={pdfIcon} alt="attacment" /></a>) : ("-")} />);
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Leave Type",
			accessor: "leaveType",
			disableSortBy: true,
		},
		{
			Header: "Days",
			accessor: "noOfDays",
			disableSortBy: true,
		},
		{
			Header: "Start Date",
			id: "startDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
            Cell: StartDateCell
		},
		{
			Header: "End Date",
			id: "endDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
            Cell: EndDateCell
		},
		{
            Header: "Time Off",
			accessor: "timeOff.label",
			disableSortBy: true,
		},
		{
            Header: () => <div className="table__attachment">Attachment</div>,
			accessor: "companyLeaveApplicationFiles",
			disableSortBy: true,
			Cell: AttachmentCell
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Filter: <SortStatusButton />,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, StatusCell, StartDateCell, EndDateCell, AttachmentCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.LEAVES.LIST);
		};
	}, [context]);

	return (
		<Fragment>
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}
			{!isEmptyState && <AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} />}

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem><AppCheckbox onClick={onHandleFilterStatus} name="approved" label="Approved" value={filterStatus.approved} /></MenuItem>
				<MenuItem><AppCheckbox onClick={onHandleFilterStatus} name="rejected" label="Rejected" value={filterStatus.rejected} /></MenuItem>
				<MenuItem><AppCheckbox onClick={onHandleFilterStatus} name="cancelled" label="Cancelled" value={filterStatus.cancelled} /></MenuItem>
			</Menu>

			<AppConfirmDeleteModal ref={confirmDeleteRef} onHandleGetList={onHandleGetList} />
		</Fragment>
	);
};

export default AppLeaveHistory;
