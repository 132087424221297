import React, { memo } from "react";

const AppInsurancenIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="insurance=icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.00006 13.6667C9.28006 13.2734 10.3401 12.48 11.1801 11.2934C12.0201 10.1067 12.4401 8.78671 12.4401 7.34004L12.4401 3.94671L8.00006 2.33337L3.56006 3.94671L3.56006 7.34004C3.56006 8.78671 3.98006 10.1134 4.82006 11.2934C5.66006 12.48 6.72006 13.2734 8.00006 13.6667Z"
				stroke={color}
			/>
			<path
				d="M10.1868 8.25996C9.86683 8.65329 9.14016 9.34663 8.00016 10.3333C7.4135 9.81996 6.94683 9.39996 6.5935 9.07329C6.24683 8.73996 5.9735 8.45996 5.78683 8.23329C5.5935 8.00663 5.4735 7.80663 5.42016 7.63329C5.36683 7.45996 5.3335 7.27329 5.3335 7.07329C5.3335 6.68663 5.48016 6.35996 5.76683 6.07996C6.06016 5.80663 6.40016 5.66663 6.80016 5.66663C7.04683 5.66663 7.2735 5.71329 7.48016 5.79996C7.6935 5.89329 7.86016 6.01329 7.9935 6.17329C8.12683 6.01329 8.30016 5.88663 8.5135 5.79996C8.72683 5.70663 8.9535 5.66663 9.1935 5.66663C9.5935 5.66663 9.94016 5.80663 10.2268 6.07996C10.5135 6.35996 10.6602 6.68663 10.6602 7.07329C10.6602 7.45996 10.5002 7.85996 10.1802 8.25996L10.1868 8.25996Z"
				fill={color}
			/>
		</svg>
	);
};

export default memo(AppInsurancenIcon);
