import React, { memo } from "react";

const AppClaimManagemetnIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="claim-management-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_3807_62804)">
				<path d="M7.68668 11.4267L9.42001 13.2201L13.3933 9.40674" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
				<path d="M3.22665 8.89365L6.97999 8.89365L6.97999 6.16699L3.22665 6.16699L3.22665 3.49365L6.97999 3.49365" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
				<path d="M5.19998 2.68018L5.19998 3.49351" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
				<path d="M5.19998 9.1333L5.19998 9.61997" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_3807_62804">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default memo(AppClaimManagemetnIcon);
