import React, { useMemo, useEffect, useCallback, Fragment, useState } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";
import getSpecialLeaveListing from "services/get-special-leave-listing";
import getSpecialClaimListing from "services/get-special-claim-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppButtonInput from "components/app-button-input";
import AppSelectInput from "components/app-select-input";
import addIcon from "assets/images/add-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";

const PageBenefit = (props) => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [specialClaimLeaveTypes, setSpecialClaimTypes] = useState([]);
	const [specialLeaveTypes, setSpecialLeaveTypes] = useState([]);
	const extraLeaveBenefit = useMemo(() => ({ type: "", numberOfDays: "", expiryMonths: "", approval: true, workingDays: COMMON.BENEFITS.DAYS.CALENDAR_DAYS }), []);
	const extraClaimBenefit = useMemo(() => ({ type: "", amount: "", approval: true }), []);
	//prettier-ignore
	const monthExpiryOptions = useMemo(() => [{ label: "3 Months", value: "3" }, { label: "6 Months", value: "6" }, { label: "9 Months", value: "9" }, { label: "12 Months", value: "12" }], []);
	//prettier-ignore
	const initialValues = useMemo(() => ({
        name: "",
        description: "",
        leaveBenefits: [
            { type: COMMON.BENEFITS.LEAVES.ANNUAL_LEAVE, numberOfDays: "", expiryMonths: "", approval: true, workingDays: COMMON.BENEFITS.DAYS.CALENDAR_DAYS },
            { type: COMMON.BENEFITS.LEAVES.MEDICAL_LEAVE, numberOfDays: "", expiryMonths: "", approval: true, workingDays: COMMON.BENEFITS.DAYS.CALENDAR_DAYS },
            { type: COMMON.BENEFITS.LEAVES.HOSPITALISATION_LEAVE, numberOfDays: "", expiryMonths: "", approval: true, workingDays: COMMON.BENEFITS.DAYS.CALENDAR_DAYS },
            { type: COMMON.BENEFITS.LEAVES.MATERNITY_LEAVE, numberOfDays: "", expiryMonths: "", approval: true, workingDays: COMMON.BENEFITS.DAYS.CALENDAR_DAYS },
            { type: COMMON.BENEFITS.LEAVES.PATERNITY_LEAVE, numberOfDays: "", expiryMonths: "", approval: true, workingDays: COMMON.BENEFITS.DAYS.CALENDAR_DAYS },
        ],
        extraLeaveBenefits: [],
        claimBenefits: [
           	{ type: COMMON.BENEFITS.CLAIMS.MEDICAL_CLAIM, amount: "", approval: true },
            { type: COMMON.BENEFITS.CLAIMS.BODYCHECK_OPTICAL_DENTAL_CLAIM, amount: "", approval: true },
            { type: COMMON.BENEFITS.CLAIMS.OTHER_CLAIM, amount: "", approval: true },
		],
		extraClaimBenefits: []
    }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			leaveBenefits: yup.array().of(yup.object({ numberOfDays: yup.string().required(ERRORS.REQUIRED) })),
			extraLeaveBenefits: yup.array().of(
				yup.object({
					numberOfDays: yup.string().when("type", {
						is: (type) => !!type,
						then: () => yup.string().required(ERRORS.REQUIRED),
					}),
					expiryMonths: yup.string().when("type", {
						is: (type) => type === COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE,
						then: () => yup.string().required(ERRORS.REQUIRED),
					}),
				})
			),
			claimBenefits: yup.array().of(yup.object({ amount: yup.string().required(ERRORS.REQUIRED) })),
			extraClaimBenefits: yup.array().of(
				yup.object({
					amount: yup.string().when("type", {
						is: (type) => !!type,
						then: () => yup.string().required(ERRORS.REQUIRED),
					}),
				})
			),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const isCreate = useMemo(() => id === COMMON.APP_STATUS.NEW, [id]);
	const memoSetValues = useMemo(() => formik.setValues, [formik.setValues]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isDisabled = useMemo(() => formik.isSubmitting, [formik.isSubmitting]);
	const title = useMemo(() => (isCreate ? "New Package" : formik.values.name || "-"), [isCreate, formik.values.name]);
	//prettier-ignore
	const leaveBenefitsField = useMemo(() => [
        { label: "Annual Leave", type: COMMON.BENEFITS.LEAVES.ANNUAL_LEAVE },
        { label: "Medical/Sick", type: COMMON.BENEFITS.LEAVES.MEDICAL_LEAVE },
        { label: "Hospitalization", type: COMMON.BENEFITS.LEAVES.HOSPITALISATION_LEAVE },
        { label: "Maternity", type: COMMON.BENEFITS.LEAVES.MATERNITY_LEAVE },
        { label: "Paternity", type: COMMON.BENEFITS.LEAVES.PATERNITY_LEAVE },
    ], []);
	//prettier-ignore
	const claimBenefitsField = useMemo(() => [
        { label: "Medical Claim", type: COMMON.BENEFITS.CLAIMS.MEDICAL_CLAIM },
        { label: "Body Check/ Optical/ Dental", type: COMMON.BENEFITS.CLAIMS.BODYCHECK_OPTICAL_DENTAL_CLAIM },
        { label: "Others", type: COMMON.BENEFITS.CLAIMS.OTHER_CLAIM },
    ], []);

	const onHandleLeaveBenefitsPayload = (leaves) => {
		//prettier-ignore
		const payload = leaves.map((o) => {
			if (!o.type) return null;

			return {
				leaveType: o.type,
				totalDays: o.numberOfDays,
				approvalFlag: o.approval,
				workingDayFlag: o.workingDays === COMMON.BENEFITS.DAYS.WORKING_DAYS,
				expiryMonths: parseInt(o.expiryMonths) || 12,
				status: COMMON.STATUS_ID.ACTIVE,
			};
		}).filter(o => o);

		return payload;
	};

	const onHandleClaimBenefitsPayload = (claims) => {
		//prettier-ignore
		const payload = claims.map((o) => {
			if (!o.type) return null;

			return {
				claimType: o.type,
				value: parseFloat(o.amount.replace(/,/g,'')),
				approvalFlag: o.approval,
				status: COMMON.STATUS_ID.ACTIVE,
			};
		});

		return payload;
	};

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				name: values.name,
				description: values.description,
				status: COMMON.STATUS_ID.ACTIVE,
				companyBenefitLeaves: onHandleLeaveBenefitsPayload([...values.leaveBenefits, ...values.extraLeaveBenefits]),
				companyBenefitClaims: onHandleClaimBenefitsPayload([...values.claimBenefits, ...values.extraClaimBenefits]),
			};

			if (isCreate) {
				await api.post.benefits.create(payload);
			} else {
				payload.id = id;
				await api.post.benefits.update(payload);
			}

			response = true;
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(-1);
			dispatch(promptAlertMessage({ message: isCreate ? "Benefit has been created successfull" : "Benefit has been updated successfull" }));
		}
	};

	//prettier-ignore
	const onHandleSetApproval = useCallback((obj) => {
		formik.setFieldValue(obj.name, !obj.value);
	}, [formik]);

	//prettier-ignore
	const onHandleAddExtraBenefit = useCallback((arrayHelpers, data) => {
        arrayHelpers.push(data);
    }, []);

	//prettier-ignore
	const onHandleRemoveExtraBenefit = useCallback((arrayHelpers, index) => {
        arrayHelpers.remove(index);
    }, []);

	//prettier-ignore
	const onHandleGetBenefitOptions = useCallback((v, extraBenefits, list) => {
		let options = [];
		const extraBenefitTypes = extraBenefits.map(o => o.type);
		options = list.filter(o => !(extraBenefitTypes.includes(o.value) && o.value !== v));
		return options;
	}, []);

	const onHandleCancel = () => {
		navigate(-1);
	};

	useEffect(() => {
		const onHandleGetSpecialLeaveTypes = async () => {
			try {
				const leaveOptions = await getSpecialLeaveListing();
				const claimOptinos = await getSpecialClaimListing();
				setSpecialLeaveTypes(leaveOptions);
				setSpecialClaimTypes(claimOptinos);
			} catch (error) {
				if (process.env.NODE_ENV === "development") console.log(error);
			}
		};

		onHandleGetSpecialLeaveTypes();
	}, []);

	useEffect(() => {
		const isEdit = id !== COMMON.APP_STATUS.NEW;

		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.benefits.benefit(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				//prettier-ignore
				const onHandleLeaveBenefits = (data, type) => {
					return data.map((o) => {
						const companyBenefits = response.companyBenefitLeaves.find((a) => o[type] === a.leaveType);
						if (!companyBenefits) return null;
						return { type: companyBenefits.leaveType, numberOfDays: companyBenefits.totalDays.toString(), expiryMonths: companyBenefits.expiryMonths.toString(), approval: companyBenefits.approvalFlag, workingDays: companyBenefits.workingDayFlag ? COMMON.BENEFITS.DAYS.WORKING_DAYS : COMMON.BENEFITS.DAYS.CALENDAR_DAYS };
					}).filter((o) => o);
				};
				//prettier-ignore
				const onHandleClaimBenefits = (data, type) => {
					return data.map((o) => {
						const claimBenefits = response.companyBenefitClaims?.find((a) => o[type] === a.claimType);
						if (!claimBenefits) return null;
						return { type: claimBenefits.claimType, amount: formatCurrency(claimBenefits.value), approval: claimBenefits.approvalFlag };
					}).filter((o) => o);
				};

				//prettier-ignore
				memoSetValues({
					name: response.name,
					description: response.description,
					leaveBenefits: onHandleLeaveBenefits(leaveBenefitsField, "type"),
					extraLeaveBenefits: onHandleLeaveBenefits(specialLeaveTypes, "value"),
					claimBenefits: onHandleClaimBenefits(claimBenefitsField, "type"),
					extraClaimBenefits: onHandleClaimBenefits(specialClaimLeaveTypes, "value"),
				});
			}
		};

		if (isEdit && specialLeaveTypes.length) onHandleGetDetails();
	}, [id, specialLeaveTypes, specialClaimLeaveTypes, leaveBenefitsField, claimBenefitsField, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.GENERAL.LIST);
			cancelRequest(COMMON.ENDPOINT_PATH.BENEFITS.BENEFIT);
		};
	}, [cancelRequest]);

	//prettier-ignore
	const FeatureBenefitInput = useCallback((obj) => {

		const onHandleKeyDown = (event) => {
			const cursorPosition = event.target.selectionEnd;
			const value = event.target.value;

			if(cursorPosition !== value.length) {
				event.target.selectionEnd = value.length;
				event.target.selectionStart = value.length;
				event.preventDefault();
			}

			if (event.key.length === 1 && !event.key.match(/\d/i)) {
				event.preventDefault();
			}
		}

		return (
			<div className="benefit__input">
				<div className="benefit__input-header">
					<label className="app-input__label" htmlFor={obj.name}>{obj.label}
					<span className="app-input__required">*</span>
				</label>

					<div className="benefit__input-switch">
						<p className="benefit__input-label">Approval</p>
						<Switch checked={obj.approval.value} onChange={() => obj.approval.onChange({ ...obj.approval })} disabled={isDisabled} />
					</div>
				</div>

				{obj.numberOfDays && <AppInput type="number" name={obj.numberOfDays.name} placeholder="Eg: 1000" value={obj.numberOfDays.value} error={obj.numberOfDays.error} touched={obj.numberOfDays.touched} disabled={isDisabled} onChange={obj.numberOfDays.onChange} maxLength={3} />}

				{obj.amount && <AppInput type="text" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onKeyDown={onHandleKeyDown} onFormat={formatCurrencyPattern} />}

				{obj.days && (
					<div className="benefit__input-buttons">
						<p className="benefit__input-label">Day Calculation</p>
						<AppButtonInput name={obj.days.name} value={obj.days.value} onChange={obj.days.onChange} options={COMMON.DROPDOWNS.DAYS_CALCULATION}  disabled={isDisabled} />
					</div>
				)}
			</div>
		);
	}, [isDisabled]);

	//prettier-ignore
	const FeatureExtraBenefitInput = useCallback((obj) => {
        const values = obj.values;
		const isCarryForward = obj.types.value === COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE;
        const options = onHandleGetBenefitOptions(obj.types.value, values, specialLeaveTypes);

        return (
            <div className="benefit__input">
                <div className="benefit__input-section">
                    <p className="benefit__category">New Benefit</p>
                    {values.length > 1 && (
                        <button type="button" className="benefit__remove-button" disabled={isDisabled} onClick={obj.onRemoveExtraBenefit}>
                            <img src={removeIcon} alt="remove-extra-benefit" />
                        </button>
                    )}
                </div>


                <div className="benefit__input-header">
                    {/* prettier-ignore */}
                    <label className="app-input__label" htmlFor="type">Leave Type</label>

                    <div className="benefit__input-switch">
                        <p className="benefit__input-label">Approval</p>
                        <Switch checked={obj.approval.value} disabled={isDisabled} onChange={() => obj.approval.onChange({ ...obj.approval })} />
                    </div>
                </div>

                <AppSelectInput type="text" name={obj.types.name} placeholder="Please Select" value={obj.types.value} error={obj.types.error} touched={obj.types.touched} options={options} disabled={isDisabled} onChange={obj.types.onChange} />

                {obj.numberOfDays && <AppInput type="number" name={obj.numberOfDays.name} label="No. of Days" placeholder="Eg: 1000" value={obj.numberOfDays.value} error={obj.numberOfDays.error} touched={obj.numberOfDays.touched} disabled={isDisabled} onChange={obj.numberOfDays.onChange} maxLength={3}/>}
                
                {isCarryForward && <AppSelectInput type="text" name={obj.expiryMonths.name} label="Expired Date" placeholder="Please Select" value={obj.expiryMonths.value} error={obj.expiryMonths.error} touched={obj.expiryMonths.touched} options={monthExpiryOptions} disabled={isDisabled} onChange={obj.expiryMonths.onChange} />}
             
                {obj.days && (
                    <div className="benefit__input-buttons">
                        <p className="benefit__input-label">Day Calculation</p>
                        <AppButtonInput name={obj.days.name} value={obj.days.value} onChange={obj.days.onChange} options={COMMON.DROPDOWNS.DAYS_CALCULATION} disabled={isDisabled} />
                    </div>
                )}
            </div>
        );
    }, [onHandleGetBenefitOptions, monthExpiryOptions, isDisabled, specialLeaveTypes]);

	//prettier-ignore
	const FeatureClaimBenefitInput = useCallback((obj) => {
		const values = obj.values;
		const options = onHandleGetBenefitOptions(obj.types.value, values, specialClaimLeaveTypes);

		return (
			<div className="benefit__input">
				<div className="benefit__input-section">
					<p className="benefit__category">New Claim</p>
					{values.length > 1 && (
						<button type="button" className="benefit__remove-button" disabled={isDisabled} onClick={obj.onRemoveExtraBenefit}>
							<img src={removeIcon} alt="remove-extra-benefit" />
						</button>
					)}
				</div>

				<div className="benefit__input-header">
					<label className="app-input__label" htmlFor="type">Name</label>

					<div className="benefit__input-switch">
						<p className="benefit__input-label">Approval</p>
						<Switch checked={obj.approval.value} disabled={isDisabled} onChange={() => obj.approval.onChange({ ...obj.approval })} />
					</div>
				</div>

				<AppSelectInput type="text" name={obj.types.name} placeholder="Please Select" value={obj.types.value} error={obj.types.error} touched={obj.types.touched} options={options} disabled={isDisabled} onChange={obj.types.onChange} />

				<AppInput type="text" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onFormat={formatCurrencyPattern} />
			</div>
		);
	}, [onHandleGetBenefitOptions, isDisabled, specialClaimLeaveTypes]);

	return (
		<FormikProvider value={formik}>
			<div className="page-benefit">
				<div className="benefit">
					<h1 className="benefit__name">{title}</h1>

					<form className="benefit__form" onSubmit={formik.handleSubmit}>
						<div className="benefit__container">
							<div className="benefit__box">
								<div className="benefit__wrapper">
									<div className="benefit__box-body">
										{/* prettier-ignore */}
										<AppInput required type="text" name="name" label="Benefit Package Name" placeholder="e.g. Package A" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled} onChange={formik.handleChange} />

										{/*prettier-ignore*/}
										<AppInput multiline maxLength={5000} type="text" name="description" label="Description" placeholder="Please describe this benefit package" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={isDisabled} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<FieldArray
							name="extraLeaveBenefits"
							render={(arrayHelpers) => {
								const errors = formik.errors;
								const touched = formik.touched;
								const leaveBenefits = formik.values.leaveBenefits;
								const extraLeaveBenefits = formik.values.extraLeaveBenefits;
								const disabledAddExtraLeaveBenefits = isDisabled || specialLeaveTypes?.length === extraLeaveBenefits?.length;

								return (
									<Fragment>
										<div className="benefit__container">
											<div className="benefit__box">
												<div className="benefit__header">
													<p className="benefit__title">Leave Benefit</p>
													<AppButton type="button" label="Add New Leave" icon={addIcon} disabled={disabledAddExtraLeaveBenefits} onClick={() => onHandleAddExtraBenefit(arrayHelpers, extraLeaveBenefit)} />
												</div>

												<div className="benefit__wrapper">
													<div className="benefit__box-body">
														{leaveBenefitsField.map((a, i) => {
															const days = {
																name: `leaveBenefits.${i}.workingDays`,
																value: leaveBenefits[i]["workingDays"],
																onChange: formik.setFieldValue,
															};
															const numberOfDays = {
																name: `leaveBenefits.${i}.numberOfDays`,
																value: leaveBenefits[i]["numberOfDays"],
																error: errors.leaveBenefits?.[i]?.["numberOfDays"],
																touched: touched.leaveBenefits?.[i]?.["numberOfDays"],
																onChange: formik.handleChange,
															};
															const approval = {
																name: `leaveBenefits.${i}.approval`,
																value: leaveBenefits[i]["approval"],
																onChange: onHandleSetApproval,
															};
															return <FeatureBenefitInput key={a.type} {...a} numberOfDays={numberOfDays} days={days} approval={approval} />;
														})}
													</div>
												</div>

												{!!extraLeaveBenefits.length && (
													<Fragment>
														<div className="benefit__divider" />

														<div className="benefit__extra-benefit">
															<div className="benefit__box-body">
																{extraLeaveBenefits.map((a, i) => {
																	const onRemoveExtraBenefit = () => onHandleRemoveExtraBenefit(arrayHelpers, i);
																	const days = {
																		name: `extraLeaveBenefits.${i}.workingDays`,
																		value: a["workingDays"],
																		onChange: formik.setFieldValue,
																	};
																	const types = {
																		name: `extraLeaveBenefits.${i}.type`,
																		value: a["type"],
																		onChange: formik.handleChange,
																	};
																	const numberOfDays = {
																		name: `extraLeaveBenefits.${i}.numberOfDays`,
																		value: a["numberOfDays"],
																		error: errors.extraLeaveBenefits?.[i]?.["numberOfDays"],
																		touched: touched.extraLeaveBenefits?.[i]?.["numberOfDays"],
																		onChange: formik.handleChange,
																	};
																	const expiryMonths = {
																		name: `extraLeaveBenefits.${i}.expiryMonths`,
																		value: a["expiryMonths"],
																		error: errors.extraLeaveBenefits?.[i]?.["expiryMonths"],
																		touched: touched.extraLeaveBenefits?.[i]?.["expiryMonths"],
																		onChange: formik.handleChange,
																	};
																	const approval = {
																		name: `extraLeaveBenefits.${i}.approval`,
																		value: a["approval"],
																		onChange: onHandleSetApproval,
																	};

																	//prettier-ignore
																	return <FeatureExtraBenefitInput key={i} index={i} {...a} onRemoveExtraBenefit={onRemoveExtraBenefit} values={extraLeaveBenefits} expiryMonths={expiryMonths} numberOfDays={numberOfDays} types={types} days={days} approval={approval} />;
																})}
															</div>
														</div>
													</Fragment>
												)}
											</div>
										</div>
									</Fragment>
								);
							}}
						/>

						<FieldArray
							name="extraClaimBenefits"
							render={(arrayHelpers) => {
								const errors = formik.errors;
								const touched = formik.touched;
								const claimBenefits = formik.values.claimBenefits;
								const extraClaimBenefits = formik.values.extraClaimBenefits;
								const disabledAddExtraClaimBenefits = isDisabled || specialClaimLeaveTypes?.length === extraClaimBenefits?.length;

								return (
									<Fragment>
										<div className="benefit__container">
											<div className="benefit__box">
												<div className="benefit__header">
													<p className="benefit__title">Claim Benefit</p>
													<AppButton type="button" label="Add New Claim" icon={addIcon} disabled={disabledAddExtraClaimBenefits} onClick={() => onHandleAddExtraBenefit(arrayHelpers, extraClaimBenefit)} />
												</div>

												<div className="benefit__wrapper">
													<div className="benefit__box-body">
														{claimBenefitsField.map((a, i) => {
															const amount = {
																name: `claimBenefits.${i}.amount`,
																value: claimBenefits[i]["amount"],
																error: errors.claimBenefits?.[i]?.["amount"],
																touched: touched.claimBenefits?.[i]?.["amount"],
																onChange: formik.handleChange,
															};
															const approval = {
																name: `claimBenefits.${i}.approval`,
																value: claimBenefits[i]["approval"],
																onChange: onHandleSetApproval,
															};

															return <FeatureBenefitInput key={a.type} {...a} amount={amount} approval={approval} />;
														})}
													</div>
												</div>

												{!!extraClaimBenefits.length && (
													<Fragment>
														<div className="benefit__divider" />

														<div className="benefit__extra-benefit">
															<div className="benefit__box-body">
																{extraClaimBenefits?.map((a, i) => {
																	const onRemoveExtraBenefit = () => onHandleRemoveExtraBenefit(arrayHelpers, i);
																	const types = {
																		name: `extraClaimBenefits.${i}.type`,
																		value: a["type"],
																		onChange: formik.handleChange,
																	};
																	const amount = {
																		name: `extraClaimBenefits.${i}.amount`,
																		value: a["amount"],
																		error: errors.extraClaimBenefits?.[i]?.["amount"],
																		touched: touched.extraClaimBenefits?.[i]?.["amount"],
																		onChange: formik.handleChange,
																	};
																	const approval = {
																		name: `extraClaimBenefits.${i}.approval`,
																		value: a["approval"],
																		onChange: onHandleSetApproval,
																	};

																	return <FeatureClaimBenefitInput key={i} {...a} types={types} amount={amount} approval={approval} values={extraClaimBenefits} onRemoveExtraBenefit={onRemoveExtraBenefit} />;
																})}
															</div>
														</div>
													</Fragment>
												)}
											</div>
										</div>
									</Fragment>
								);
							}}
						/>
						<div className="benefit__button-container">
							<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
							<AppButton type="submit" disabled={isDisabled} label="Update" />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default PageBenefit;
