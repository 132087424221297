import React, { useRef, useCallback, useMemo, useEffect, useState, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import sanitizeObject from "common/sanitize-object";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import queryParamsEntries from "common/query-params-entries";
import capitalizeCharacter from "common/capitalize-character";
import getEmploymentStatusListing from "services/get-employement-status-listing";
import { isDateBeforeToday, formatDatePattern, formatDateTime } from "common/calendar";
import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppInviteEmployeeModal from "components/app-invite-employee-modal";
import AppEmployeeModal from "components/pages/user-management/app-employee-modal";
import AppTable, { AppTableCell, AppTableEditIcon, AppTableMoreIcon } from "components/app-table";
import AppProbationCompletedModal from "components/pages/user-management/app-probation-completed-modal";
import AppInternshipCompletedModal from "components/pages/user-management/app-internship-completed-modal";
import addIcon from "assets/images/add-icon.svg";
import viewIcon from "assets/images/view-icon.svg";
import editIcon from "assets/images/edit-icon.svg";
import resendIcon from "assets/images/resend-icon.svg";
import exclamationIcon from "assets/images/exclamation-icon.svg";
// import exportIcon from "assets/images/pages/user-management/export-icon.svg";
// import chevronDownIcon from "assets/images/pages/user-management/chevron-down-icon.svg";

const PageEmployees = (props) => {
	const tableRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const searchInputRef = useRef();
	const employeeModalRef = useRef();
	const probationCompletedRef = useRef();
	const internshipCompletedRef = useRef();
	const inviteEmployeeModalRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedUser, setSelectedUser] = useState({});
	const [searchParams, setSearchParams] = useSearchParams();
	//prettier-ignore
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
		employmentStatus: searchParams.get("employmentStatus") || "",
		email: searchParams.get("email") || "",
		name: searchParams.get("name") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const initialValues = useMemo(() => ({ employmentStatus: "", email: "", name: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.email && !paramsRef.current.name && !paramsRef.current.email && !paramsRef.current.employmentStatus, [data.status]);
	const isResendInviation = useMemo(() => {
		switch (selectedUser.employmentStatus) {
			case COMMON.STATUS_ID.RESIGNED:
			case COMMON.STATUS_ID.DISMISSAL:
			case COMMON.STATUS_ID.TERMINATION:
				return true;
			default:
				return false;
		}
	}, [selectedUser]);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { employmentStatus: capitalizeCharacter(params.employmentStatus?.split("_")?.join(" ")), email: params.email, name: params.name, phone: params.phone };

		return values;
	}, [searchParams]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.employee.list(sanitizeObject({ ...payload }));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleReinviteUser = async () => {
		let response = null;

		try {
			onhandleCloseMenu();

			const payload = { email: selectedUser.email };

			await api.post.employee.reinviteTerminatedUser(payload);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			onHandleGetList();
			dispatch(promptAlertMessage({ message: "An invitation has been resent successfully" }));
		}
	};

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		switch (id) {
			case "organisationName":
				paramsRef.current.sort = order ? "o.name," + order : "";
				break;
			default:
				paramsRef.current.sort = order ? id + "," + order : "";
				break;
		}

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	const onHandleTableMenu = (event, { row }) => {
		setSelectedUser(row.original);
		setAnchorEl(event.currentTarget);
	};

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
	};

	const onhandleViewProfile = () => {
		setAnchorEl(null);
		employeeModalRef.current.onHandleShow(selectedUser);
	};

	const onHandleEdit = () => {
		setAnchorEl(null);
		navigate(pathnames.userManagement.employee + selectedUser.email, { state: selectedUser });
	};

	const MenuCell = useCallback((obj) => {
		return <AppTableMoreIcon onClick={(event) => onHandleTableMenu(event, obj)} />;
	}, []);

	const DateJoinedCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.dateJoined))} />;
	}, []);

	const LastEmploymentDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.lastEmploymentDate))} />;
	}, []);

	const LastModifiedCell = useCallback(({ row }) => {
		return <AppTableCell left value={`${row.original.lastModifiedByName || "-"}\n${formatDateTime(new Date(row.original.lastModifiedDate))}`} />;
	}, []);

	const PositionCell = useCallback(({ row }) => {
		return <AppTableCell left value={row.original.position || "-"} />;
	}, []);

	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />;
	}, []);

	const StatusEmplomentCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.employmentStatus} />;
	}, []);

	const ExclamationCell = useCallback(({ row }) => {
		const today = new Date();
		const original = row.original;
		const internshipCompleted = original.employmentStatus === COMMON.STATUS_ID.INTERN && isDateBeforeToday(original.internshipEndDate, today);
		const probationCompleted = original.employmentStatus === COMMON.STATUS_ID.PROBATION && isDateBeforeToday(original.probationEndDate, today);
		const onClick = internshipCompleted ? () => internshipCompletedRef.current?.onHandleShow(original) : () => probationCompletedRef.current?.onHandleShow(original);

		if (internshipCompleted || probationCompleted) return <AppTableEditIcon className="table__exclamation" icon={exclamationIcon} onClick={onClick} />;
		else return null;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Employee ID",
			accessor: "employeeId",
			disableSortBy: false,
		},
		{
			Header: "Employee Name",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "Email Address",
			accessor: "email",
			disableSortBy: true,
		},
		{
			Header: "Position",
			accessor: "position",
			disableSortBy: false,
			Cell: PositionCell,
		},
		{
			Header: "Join Date",
			accessor: "dateJoined",
			disableSortBy: false,
			Cell: DateJoinedCell,
		},
		{
			Header: "Last Employment",
			accessor: "lastEmploymentDate",
			disableSortBy: false,
			Cell: LastEmploymentDateCell,
		},
		{
			Header: "Insurance Plan",
			accessor: "ebInsurancePlan",
			disableSortBy: false,
			Cell: ({ row }) => row.original.ebInsurancePlan ? capitalizeCharacter(row.original.ebInsurancePlan.split("_").join(" ")) : "",
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "Employement Status",
			accessor: "employmentStatus",
			disableSortBy: true,
			Cell: StatusEmplomentCell,
		},
		{
			Header: "Last Update by",
			accessor: "lastModifiedDate",
			disableSortBy: false,
			Cell: LastModifiedCell,
		},
		{
			Header: "",
			accessor: "**",
			disableSortBy: true,
			Cell: ExclamationCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, StatusCell, StatusEmplomentCell, LastModifiedCell, PositionCell, ExclamationCell, LastEmploymentDateCell, DateJoinedCell]);

	const onHandleInviteEmployee = useCallback(() => {
		inviteEmployeeModalRef.current.onHandleShow();
	}, []);

	const headerButtons = useMemo(() => {
		const buttons = [
			// { label: "Export", icon: exportIcon, outline: true, onClick: refreshPage },
			{ label: "Invite", icon: addIcon, outline: false, onClick: onHandleInviteEmployee },
		];
		return buttons;
	}, [onHandleInviteEmployee]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.EMPLOYEE.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-employees">
			<div className="employees">
				{isEmptyState && (
					<div className="employees__empty-state">
						<AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />
						<div className="employees__button-container">
							<AppButton type="button" label="invite" onClick={onHandleInviteEmployee} />
							{/* <AppButton type="button" label="Bulk Invite" outline onClick={() => {}} /> */}
						</div>
					</div>
				)}

				{!isEmptyState && (
					<Fragment>
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttons={headerButtons}>
							<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
								<div className="advance-form">
									<div className="advance-form__inputs">
										<AppInput type="text" name="name" label="Employee Name" placeholder="Enter Employee Name" value={formik.values.name} onChange={formik.handleChange} />

										<AppInput type="text" name="email" label="Email Address" placeholder="Enter Email Address" value={formik.values.email} onChange={formik.handleChange} />

										<AppSelectInput name="employmentStatus" label="Employment Status" placeholder="Select Employment Status" loadOptions={getEmploymentStatusListing} value={formik.values.employmentStatus} onChange={formik.handleChange} />
									</div>

									<div className="advance-form__button-container">
										<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
										<AppButton type="submit" label="Search" />
									</div>
								</div>
							</form>
						</AppSearchInput>

						{(paramsRef.current.email || paramsRef.current.employmentStatus || paramsRef.current.name) && (
							<div className="employees__results">
								<p className="employees__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}

				{/* prettier-ignore */}
				<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<MenuItem onClick={onhandleViewProfile}><img className="app-table-menu__icon" src={viewIcon} alt="view" />View</MenuItem>
					{!isResendInviation && <MenuItem onClick={onHandleEdit}><img className="app-table-menu__icon" src={editIcon} alt="edit" />Edit</MenuItem>}
					{isResendInviation && <MenuItem onClick={onHandleReinviteUser}><img className="app-table-menu__icon" src={resendIcon} alt="resend" />Resend</MenuItem>}
				</Menu>
			</div>

			<AppEmployeeModal ref={employeeModalRef} />

			<AppInviteEmployeeModal ref={inviteEmployeeModalRef} />

			<AppInternshipCompletedModal ref={internshipCompletedRef} onHandleGetList={onHandleGetList} />

			<AppProbationCompletedModal ref={probationCompletedRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default PageEmployees;
