import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import getEmployeeListing from "services/get-employee-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import serveRequestErrors from "common/serve-request-errors";

const AppAddAdminModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ name: "" }), []);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			await api.post.admin.assignRole({ email: values.name });

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();

			props.onHandleGetList();

			dispatch(promptAlertMessage({ message: "New user created successfully" }));
		}
	};

	const onHandleShow = useCallback(() => {
		formik.resetForm();

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-add-admin-modal" }} open={visible} aria-labelledby="add-admin" aria-describedby="add-admin-modal">
			<div className="add-admin">
				<p className="add-admin__title">Add User</p>

				{/* prettier-ignore */}
				<AppSelectInput required type="text" name="name" label="Full Name" placeholder="Please Select" loadOptions={getEmployeeListing} value={formik.values.name} touched={formik.touched.name} error={formik.errors.name} disabled={formik.isSubmitting} onChange={formik.handleChange} />

				{/* prettier-ignore */}
				<AppInput required disabled type="text" name="email" label="Email Address" placeholder="Email Address"  value={formik.values.name} />

				<div className="add-admin__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Confirm" onClick={formik.handleSubmit} disabled={formik.isSubmitting} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddAdminModal));

AppAddAdminModal.propTypes = {
	ref: PropTypes.object,
};
