import React, { useRef, useCallback, useMemo, useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import COMMON from "common";
import api from "services/api";
import sanitizeObject from "common/sanitize-object";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppEmptyState from "components/app-empty-state";
import AppClaimModal from "components/pages/claim-management/app-claim-modal";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import AppConfirmDeleteModal from "components/pages/claim-management/app-claim-confirm-delete-leave";
import pdfIcon from "assets/images/pdf-icon.svg";
import viewIcon from "assets/images/view-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";
import sortbyIcon from "assets/images/components/app-table/sortby-icon.svg";

const AppClaimHistory = (props) => {
	const { id } = useParams();
	const claimModalRef = useRef();
	const confirmDeleteRef = useRef();
	const context = useContext(AxiosContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const paramsRef = useRef({ statusListStr: searchParams.get("statusListStr") || "CANCELLED,APPROVED,REJECTED", page: parseInt(searchParams.get("page")) || 1, sort: searchParams.get("sort") || "", tab: "Claim History" });
	const filterDefaultStatus = useMemo(() => {
		const params = searchParams.get("statusListStr");
		return { approved: params?.includes("APPROVED"), rejected: params?.includes("REJECTED"), cancelled: params?.includes("CANCELLED") };
	}, [searchParams]);
	const [filterStatus, setFilterStatus] = useState(filterDefaultStatus);
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.claims.list(sanitizeObject({ ...payload, id }));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, [id]);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	const onHandleStatusMenu = useCallback((event) => {
		const element = event.currentTarget;
		if (element) element.classList.add("table__sort--active");
		setAnchorEl(event.currentTarget);
	}, []);

	const onhandleCloseMenu = useCallback(() => {
		const element = document.querySelector(".table__sort");
		const filtered = Object.keys(filterStatus).some((o) => filterStatus[o]);

		if (filtered && element) element.classList.add("table__sort--active");
		else if (element) element.classList.remove("table__sort--active");

		setAnchorEl(null);
		onHandleGetList();
	}, [filterStatus, onHandleGetList]);

	//prettier-ignore
	const onHandleFilterStatus = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;

		setFilterStatus((prev) => {
			let status = { ...prev, [name]: !value };
            const allDisabled = Object.keys(status).map(o => status[o]).filter(o => o).length === 0;
            if(allDisabled) status = { ...prev, [name]: true };
			
            paramsRef.current.statusListStr = Object.keys(status).map((o) => (status[o] ? o.toUpperCase() : null)).filter((o) => o).join(",");

			return status;
		});
	}, []);

	//prettier-ignore
	const SortStatusButton = useCallback(() => {
		return <button className="table__sort table__sort--active" type="button" onClick={onHandleStatusMenu}><img src={sortbyIcon} alt="sort status" /></button>
	}, [onHandleStatusMenu]);

	const onHandleRemove = useCallback((id) => {
		confirmDeleteRef.current.onHandleShow(id);
	}, []);

	const onHandleViews = useCallback((id) => {
		claimModalRef.current.onHandleShow(id);
	}, []);

	const SubmitDate = useCallback(({ row }) => {
		const date = formatDatePattern(row.original.createdDate);
		return <AppTableCell left value={date} />;
	}, []);

	const AmountCell = useCallback(({ row }) => <AppTableCell right value={formatCurrency(row.original.claimValue)} />, []);

	//prettier-ignore
	const AttachmentCell = useCallback(({ row }) => {
		const file = row.original?.companyClaimApplicationFiles?.[0];
		const value = file ? (<a href={file?.fileURL} target="_blank" rel="noopener noreferrer"><img className="table__file" src={pdfIcon} alt="attacment" /></a>) : ("-");
		return (
			<AppTableCell center value={value} />
		);
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		const isApproval = COMMON.STATUS_ID.APPROVED === row.original.status;
		if(isApproval) return <AppTableEditIcon icon={removeIcon} onClick={() => onHandleRemove(row.original.id)} />; 
		else return null;
	}, [onHandleRemove]);

	//prettier-ignore
	const MenuViewCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={viewIcon} onClick={() => onHandleViews(row.original.id)} />;
	}, [onHandleViews]);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.values.status} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Claim Type",
			accessor: "claimTypeMapped",
			disableSortBy: true,
		},
		{
			Header: "Submit Date",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: SubmitDate,
		},
		{
			Header: () => <div className="table__amount">Amount (RM)</div>,
			id: "claimValue",
			accessor: "companyClaimApplicationDetails",
			disableSortBy: true,
            Cell: AmountCell
		},
		{
            Header: () => <div className="table__attachment">Attachment</div>,
			accessor: "companyClaimApplicationFiles",
			disableSortBy: true,
			Cell: AttachmentCell
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Filter: <SortStatusButton />,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
		{
			Header: "",
			accessor: "**",
			disableSortBy: true,
			Cell: MenuViewCell,
		},
	], [MenuCell, MenuViewCell, StatusCell, AttachmentCell, AmountCell, SubmitDate]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.CLAIMS.LIST);
		};
	}, [context]);

	return (
		<div className="app-claim-history">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}
			{!isEmptyState && <AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} />}

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem><AppCheckbox onClick={onHandleFilterStatus} name="approved" label="Approved" value={filterStatus.approved} /></MenuItem>
				<MenuItem><AppCheckbox onClick={onHandleFilterStatus} name="rejected" label="Rejected" value={filterStatus.rejected} /></MenuItem>
				<MenuItem><AppCheckbox onClick={onHandleFilterStatus} name="cancelled" label="Cancelled" value={filterStatus.cancelled} /></MenuItem>
			</Menu>

			<AppConfirmDeleteModal ref={confirmDeleteRef} onHandleGetList={onHandleGetList} />

			<AppClaimModal ref={claimModalRef} />
		</div>
	);
};

export default AppClaimHistory;
