import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getPostcodeListing = async (params) => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.general.postcode(params);
		const content = res.map((o) => ({ label: o, value: o }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getPostcodeListing;
