import React, { memo, useRef, forwardRef, useImperativeHandle, useState, useCallback, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import { formatCurrency } from "common/format-currency-pattern";
import { formatDatePattern, CALENDAR_FORMAT } from "common/calendar";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";
import AppUserManagementIcon from "components/icons/app-user-management-icon";
import AppDownloadPolicyModal from "components/pages/insurance-coverage/app-download-policy-modal";
import AppDownloadInvoiceModal from "components/pages/insurance-coverage/app-download-invoice-modal";
import AppUnpaidDebitNoteModal from "components/pages/insurance-coverage/app-unpaid-debit-note-modal";
import AppDownloadQuotationModal from "components/pages/insurance-coverage/app-download-quotation-modal";
import AppRejectPolicyRenewalModal from "components/pages/insurance-coverage/app-reject-policy-renewal-modal";
import AppDownloadPaymentReceiptModal from "components/pages/insurance-coverage/app-download-payment-receipt-modal";
import AppReviseQuotationEmployeesModal from "components/pages/insurance-coverage/app-revise-quotation-employees-modal";
import AppUploadAdditionalQuotationDocumentsModal from "components/pages/insurance-coverage/app-upload-additional-quotation-documents-modal";
import uploadIcon from "assets/images/upload-icon.svg";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";
import { ReactComponent as ReviseIcon } from "assets/images/revise-icon.svg";
import { ReactComponent as CheckedIcon } from "assets/images/checked-icon.svg";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";
import { ReactComponent as PayIcon } from "assets/images/pages/insurance-coverage/pay-icon.svg";

const TABS = [
	{ label: "Group Hospitalisation & Surgical", value: "GHS" },
	{ label: "Group Term Life", value: "GTL" },
	{ label: "Group Outpatient", value: "GO" },
];

const PAGES = {
	POLICY: "Policy Info",
	INSURER: "Insured Person(s)",
	PAYMENT_HISTORY: "Payment History",
	CREDIT: "Credit Note",
	DEBIT: "Debit Note",
};

const PAGE_OPTIONS = [
	{ label: "Policy Info", value: PAGES.POLICY },
	{ label: "Insured Person(s)", value: PAGES.INSURER },
	{ label: "Payment History", value: PAGES.PAYMENT_HISTORY },
	{ label: "Debit Note", value: PAGES.DEBIT },
	{ label: "Credit Note", value: PAGES.CREDIT },
];

const PAYMENT_TYPES = [
	{ label: "Debit Note", value: COMMON.COVERAGES.PAYMENT_TYPE.DEBIT_NOTE },
	{ label: "Credit Note", value: COMMON.COVERAGES.PAYMENT_TYPE.CREDIT_NOTE },
];

const invoiceMonthFormat = CALENDAR_FORMAT.MONTH_FORMAT;

const invoiceDateFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_SHORT_FORMAT;

const manufacturingYear = "2023";

const currentYear = new Date().getFullYear().toString();

export const AppPolicyModal = (props, ref) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const upaidDebitNoteRef = useRef();
	const downloadPolicyRef = useRef();
	const downloadInvoiceRef = useRef();
	const downloadQuotationRef = useRef();
	const rejectPolicyRenewalRef = useRef();
	const downloadPaymentInvoiceRef = useRef();
	const reviseQuotationEmployeesRef = useRef();
	const uploadAdditionalQuotationDocumentRef = useRef();
	const [data, setData] = useState({});
	const [insurer, setInsurer] = useState();
	const [debit, setDebit] = useState();
	const [credit, setCredit] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [acceptingPayment, setAcceptingPayment] = useState(false);
	const [selectedDebitYear, setSelectedDebitYear] = useState(currentYear);
	const [selectedCreditYear, setSelectedCreditYear] = useState(currentYear);
	const [selectedPaymentHistory, setSelectedPaymentHistory] = useState(COMMON.COVERAGES.PAYMENT_TYPE.DEBIT_NOTE);
	const [selectedPaymentHistoryYear, setSelectedPaymentHistoryYear] = useState(currentYear);
	const [selectedRenewalQuotation, setSelectedRenewalQuotation] = useState(null);
	const [paymentHistory, setPaymentHistory] = useState();
	const [page, setPage] = useState(PAGES.POLICY);
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS[0].value);
	const planTabs = useMemo(() => TABS.filter((a) => data?.policies?.findIndex((b) => b.policyName === a.value) > -1), [data?.policies]);
	const policyIsExpired = useMemo(() => {
		const statusIsExpired = data.status === COMMON.STATUS_ID.EXPIRED;

		let displayExpired = false;

		if (data.renewal?.status) {
			switch (data.renewal.status) {
				case COMMON.STATUS_ID.COMPLETED:
				case COMMON.STATUS_ID.PENDING_ADDITIONAL_DOCS:
				case COMMON.STATUS_ID.PENDING_POLICY_NO:
				case COMMON.STATUS_ID.PENDING_IN_FORCE:
					displayExpired = false;
					break;
				default:
					displayExpired = statusIsExpired;
					break;
			}
		}

		return displayExpired;
	}, [data.status, data.renewal?.status]);

	const policies = useMemo(() => {
		const sanitizedData = {};

		try {
			data?.policies?.forEach((o) => {
				if (!sanitizedData[o.policyName]) sanitizedData[o.policyName] = [];
				sanitizedData[o.policyName].push(o);
			});
		} catch (error) {
			serveRequestErrors(error);
		}

		return sanitizedData;
	}, [data]);

	const renewalStatusPendingPayment = useMemo(() => {
		const pendingPayment = data?.renewal?.status === COMMON.STATUS_ID.PENDING_PAYMENT;
		const pendingInProgressPayment = data?.renewal?.status === COMMON.STATUS_ID.PAYMENT_IN_PROGRESS;

		return pendingPayment || pendingInProgressPayment;
	}, [data?.renewal?.status]);

	//prettier-ignore
	const tabClassName = useCallback((currentTab) => {
		return classNames({
			"policy-modal__tab": true,
			"policy-modal__tab--active": tab === currentTab,
		});
	}, [tab]);

	const policyInfo = useMemo(() => {
		const info = [
			{ label: "Company Name", value: data.companyName },
			{ label: "Business Reg. No.", value: data.businessRegNo },
			{ label: "No. of Insured", value: data.insuredCount },
			{ label: "Effective Date", value: formatDatePattern(data.policyEffectiveDate) },
			{ label: "Expiry Date", value: formatDatePattern(data.policyExpiryDate) },
			{ label: "Insurance Provider", value: data.providerName },
			{ label: "Plan Name", value: data.productName },
		];
		return info;
	}, [data]);

	const onHandleSelectRenewalPolicy = useCallback((quotationId) => {
		setSelectedRenewalQuotation(quotationId);
	}, []);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (payload) => {
		let response = null;

		try {
			response = await api.get.policies.policy(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setData(response);
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		setTab(TABS[0].value);

		setData(obj);

		setVisible(true);

		if (obj.orderId) {
			const payload = { orderId: obj.orderId, renewalId: obj.renewalId };

			onHandleGetDetails(payload);
		}
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setCredit();
		setInsurer();
		setVisible(false);
		setPage(PAGES.POLICY);
		setAcceptingPayment(false);
	}, []);

	//prettier-ignore
	const onHandleGetCreditNote = useCallback(async (creditYear) => {
		let response = null;
		let unclaimedResponse = 0;

		try {
			const payload = { orderId: data.orderId, renewalId: data.renewalId };

			response = await api.get.policies.creditNote({ ...payload, year: creditYear });

			unclaimedResponse = await api.get.policies.unclaimedCreditAmount(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedData = {};

			response.forEach((o) => {
				const month = formatDatePattern(o.invoiceDate, invoiceMonthFormat);

				if (!sanitizedData[month]) sanitizedData[month] = [];

				sanitizedData[month].push(o);
			});

			setCredit({ data: sanitizedData, amount: unclaimedResponse || 0 });
		}
	}, [data]);

	//prettier-ignore
	const onHandleGetDebitNote = useCallback(async (debitYear) => {
		let response = null;
		let unpaidDebitReponse = 0;

		try {
			const payload = { orderId: data.orderId, renewalId: data.renewalId };

			response = await api.get.policies.debitNote({ ...payload, year: debitYear });

			unpaidDebitReponse = await api.get.policies.unpaidDebitAmount(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedData = {};

			response.forEach((o) => {
				const month = formatDatePattern(o.invoiceDate, invoiceMonthFormat);

				if (!sanitizedData[month]) sanitizedData[month] = [];

				sanitizedData[month].push(o);
			});

			setDebit({ data: sanitizedData, amount: unpaidDebitReponse || 0 });
		}
	}, [data]);

	const onHandleRefundCredit = useCallback(async () => {
		setSubmitting(true);

		let response = null;

		try {
			const payload = { orderId: data.orderId, renewalId: data.renewalId };

			await api.get.policies.refundCredit(payload);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();
			dispatch(promptAlertMessage({ message: "Refund request has been sent out successfully. Please allow up to 14 business days for initial review and processing." }));
		}
	}, [data, dispatch, onHandleDismiss]);

	//prettier-ignore
	const onHandleGetPaymentHistory = useCallback(async (year, type) => {
		let response = null;

		try {
			if(type === COMMON.COVERAGES.PAYMENT_TYPE.CREDIT_NOTE) {
				response = await api.get.policies.creditPaymentHistory(year);
			}
			else {
				response = await api.get.policies.debitPaymentHistory(year);
			}
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedData = {};

			response.forEach((o) => {
				const month = formatDatePattern(o.createdDate, invoiceMonthFormat);

				if (!sanitizedData[month]) sanitizedData[month] = {};

				if (!sanitizedData[month][o.refNo]) sanitizedData[month][o.refNo] = [];

				sanitizedData[month][o.refNo].push(o);
			});

			setPaymentHistory(sanitizedData);
		}
	}, []);

	//prettier-ignore
	const onHandleGetInsurer = useCallback(async (payload) => {
		let response = null;

		try {
			response = await api.get.policies.insurer(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedInsured = { parent: {}, subsidiary: {} };

			response.forEach((o) => {
				const type = o.isParentCompany ? "parent" : "subsidiary";

				if (!sanitizedInsured[type][o.companyName]) {
					sanitizedInsured[type][o.companyName] = {};
				}

				if (!sanitizedInsured[type][o.companyName][o.ebInsurancePlan]) {
					sanitizedInsured[type][o.companyName][o.ebInsurancePlan] = [];
				}

				sanitizedInsured[type][o.companyName][o.ebInsurancePlan].push(o);
			});

			setInsurer(sanitizedInsured);
		}
	}, []);

	//prettier-ignore
	const onHandleChangePage = useCallback((event) => {
		const payload = { orderId: data.orderId, renewalId: data.renewalId };

		const value = event.target.value;

		switch (value) {
			case PAGES.POLICY:
				onHandleGetDetails(payload);
			break;
			case PAGES.PAYMENT_HISTORY:
				onHandleGetPaymentHistory(currentYear, selectedPaymentHistory);
			break;
			case PAGES.INSURER:
				onHandleGetInsurer(payload);
				break;
			case PAGES.CREDIT:
				setSelectedCreditYear(currentYear);
				onHandleGetCreditNote(currentYear);
				break;
			case PAGES.DEBIT:
				setSelectedDebitYear(currentYear);
				onHandleGetDebitNote(currentYear);
				break;
			default:
				break;
		}

		setPage(value);
	}, [onHandleGetDetails, onHandleGetInsurer, onHandleGetCreditNote, onHandleGetDebitNote, onHandleGetPaymentHistory, data, selectedPaymentHistory]);

	const onHandleTabChange = useCallback((index) => {
		setTab(index);
	}, []);

	const onHandleDownloadPolicy = useCallback((obj, currentTab) => {
		const currentPolicy = TABS.find((o) => o.value === currentTab);
		downloadPolicyRef.current.onHandleShow(obj, currentPolicy);
	}, []);

	const onHandleMakePayment = useCallback(() => {
		upaidDebitNoteRef.current.onHandleShow(data);
	}, [data]);

	const onHandleDownloadQuotation = useCallback(() => {
		downloadQuotationRef.current.onHandleShow(data);
	}, [data]);

	const onHandleReviseQuotation = useCallback(() => {
		reviseQuotationEmployeesRef.current.onHandleShow(data);
	}, [data]);

	const onHandleRejectRenewal = useCallback(() => {
		rejectPolicyRenewalRef.current.onHandleShow();
	}, []);

	const onHandleUploadAdditionalDocuments = useCallback(() => {
		let quotationId = selectedRenewalQuotation;

		if (!quotationId) quotationId = data.renewal.quotations[0].id;

		uploadAdditionalQuotationDocumentRef.current.onHandleShow(quotationId);
	}, [selectedRenewalQuotation, data]);

	const onHandleAcceptRenewal = useCallback(() => {
		let quotationId = selectedRenewalQuotation;

		if (!quotationId) quotationId = data.renewal.quotations[0].id;

		if (renewalStatusPendingPayment) {
			setAcceptingPayment(true);
			return uploadAdditionalQuotationDocumentRef.current.onHandleMakePayment(quotationId);
		}

		uploadAdditionalQuotationDocumentRef.current.onHandleShow(quotationId);
	}, [data, selectedRenewalQuotation, renewalStatusPendingPayment]);

	const onHandleDownloadInvoice = useCallback((obj) => {
		downloadInvoiceRef.current.onHandleShow(obj);
	}, []);

	const onHandleDownloadPaymentReceipt = useCallback((obj, paymentHistoryType) => {
		downloadPaymentInvoiceRef.current.onHandleShow(obj, paymentHistoryType);
	}, []);

	const onHandleManageInsurer = useCallback(() => {
		navigate(pathnames.userManagement.employees);
	}, [navigate]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	const PolicyInfo = useCallback((obj) => {
		if (obj.page !== PAGES.POLICY) return null;

		return (
			<Fragment>
				<div className="policy-modal__body">
					<div className="policy-modal__container">
						{obj.policyInfo.map((a, i) => {
							return (
								<div className="policy-modal__info" key={i}>
									<p className="policy-modal__label">{a.label}</p>
									<p className="policy-modal__value">{a.value || "-"}</p>
								</div>
							);
						})}
					</div>
				</div>

				{Boolean(obj.tabs.length) && <AppScrollableTabs tabs={obj.tabs} onChange={setTab} />}

				{!obj.policy && (
					<div className="policy-modal__loading">
						<LoadingLogo />
					</div>
				)}

				{obj.policy?.map?.((a) => {
					return (
						<Fragment key={a.policyNo}>
							<div className="policy-modal__body">
								<div className="policy-modal__container">
									<div className="policy-modal__info">
										<p className="policy-modal__label">Policy No.</p>
										<p className="policy-modal__value">{a.policyNo}</p>
									</div>
								</div>
							</div>

							<div className="policy-modal__divider" />

							<div className="plan">
								<div className="plan__header">
									<p className="plan__title">{a.policyFullName}</p>

									<AppButton type="button" outline label="Download Policy" icon={uploadIcon} onClick={() => obj.onHandleDownloadPolicy({ ...obj.data, policyNo: a.policyNo }, obj.tab)} />
								</div>
							</div>

							{a?.planCoverage.map((b) => {
								return (
									<div className="box" key={b.internalCode}>
										<div className="box__container">
											<div className="box__header">
												<p className="box__headline">{b.planName}</p>
												<p className="box__sub-headline">{b.quantity} pax</p>
											</div>

											<div className="box__divider" />

											<ul className="box__list">
												<div className="box__coverage">
													<p className="box__sub-headline">Overall Annual Limit</p>
													<p className="box__sub-headline">RM {formatCurrency(b.benefit.overallAnnualLimit)}</p>
												</div>

												{b.benefit.productEbPlanBenefitCoverages.map((c) => {
													return (
														<li className="box__coverage" key={c.name}>
															<p className="box__benefit">{c.name}</p>
															<p className="box__benefit">{c.coverageAmount ? `RM ${formatCurrency(c.coverageAmount)}` : c.coverageAmount === 0 ? "As Charged" : ""}</p>
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								);
							})}
						</Fragment>
					);
				})}
			</Fragment>
		);
	}, []);

	const InsurerInfo = useCallback((obj) => {
		if (obj.page !== PAGES.INSURER) return null;

		if (!obj.insurer) {
			return (
				<div className="policy-modal__loading">
					<LoadingLogo />
				</div>
			);
		}

		return (
			<Fragment>
				<div className="plan">
					<div className="plan__nav">
						<AppButton type="button" outline label="Manage" icon={<AppUserManagementIcon color="#0245a9" />} onClick={obj.onHandleManageInsurer} />
					</div>
				</div>
				{Object.keys(obj.insurer).map((a) => {
					const plans = obj.insurer[a];

					return Object.keys(plans).map((b) => {
						const plan = plans[b];
						const pax = Object.keys(plan).reduce((j, k) => (j += plan[k].length), 0);

						return (
							<Fragment key={a}>
								<div className="plan">
									<div className="plan__header">
										<p className="plan__title">{a.toUpperCase()} COMPANY</p>
									</div>
								</div>

								<div className="box">
									<div className="box__container">
										<div className="box__header">
											<p className="box__headline">{b}</p>
											<p className="box__sub-headline">{pax} pax</p>
										</div>

										<div className="box__divider" />

										<div className="box__body">
											{Object.keys(plan).map((c) => {
												return (
													<Fragment key={c}>
														<ul className="box__list">
															<li className="box__insurer">
																<p className="box__title">{capitalizeCharacter(c.split("_").join(" "))}</p>
															</li>

															{plan[c].map((d) => {
																return (
																	<li key={d.email} className="box__insurer">
																		<div className="box__avatar" style={{ backgroundImage: `url(${d.avatar})` }} />

																		<div className="box__center">
																			<p className="box__headline">{d.name}</p>
																			<p className="box__sub-headline">{d.email}</p>
																		</div>

																		<p className="box__text">{d.employeeId || "-"}</p>
																	</li>
																);
															})}
														</ul>
													</Fragment>
												);
											})}
										</div>
									</div>
								</div>
							</Fragment>
						);
					});
				})}
			</Fragment>
		);
	}, []);

	const CreditNoteInfo = useCallback((obj) => {
		const yearsOption = Array.from({ length: +currentYear + 1 - manufacturingYear }, (_, i) => +currentYear - i).map((o) => ({ label: o.toString(), value: o.toString() }));

		if (obj.page !== PAGES.CREDIT) return null;

		const onHandleSelectYear = (event) => {
			const value = event.target.value;
			setSelectedCreditYear(value);
			obj.onHandleGetCreditNote(value);
		};

		const onHandleColors = (status) => {
			let color = "rgb(0, 207, 156)";
			switch (status) {
				case COMMON.STATUS_ID.UNCLAIMED:
					color = "rgb(255, 199, 112)";
					break;
				case COMMON.STATUS_ID.REFUND_IN_PROGRESS:
					color = "#0245A9";
					break;
				default:
					break;
			}

			return color;
		};

		if (!obj.creditNote?.data) {
			return (
				<div className="policy-modal__loading">
					<LoadingLogo />
				</div>
			);
		}

		return (
			<div className="credit-debit-note">
				<div className="box">
					<div className="box__container">
						<div className="box__header">
							<p className="box__headline">Credit Balance</p>
						</div>

						<div className="box__divider" />

						<div className="box__body">
							<p className="box__text">Total Credit Balance Amount</p>

							<div className="box__content">
								<p className="box__amount">RM {formatCurrency(obj.creditNote.amount)}</p>

								<AppButton type="button" label="Request Refund" icon={<PayIcon />} disabled={obj.submitting || !obj.creditNote.amount} onClick={obj.onHandleRefundCredit} />
							</div>
						</div>

						<div className="box__footer">
							<p className="box__note">* Please ensure all outstanding premiums are settled before requesting a credit balance refund</p>
						</div>
					</div>
				</div>

				<AppSelectInput searchable={false} label="" placeholder="Please Select" value={obj.selectedCreditYear} options={yearsOption} onChange={onHandleSelectYear} />

				<div className="history">
					{Object.keys(obj.creditNote.data).map((month) => {
						const item = obj.creditNote.data[month];

						return (
							<div className="history__container" key={month}>
								<div className="history__header">
									<p className="history__month">{month}</p>
								</div>

								{item.map((o, i) => {
									const firstItem = i === 0;
									const checkpointVisible = (!firstItem && o.status !== item[i - 1].status) || firstItem;
									const checkboxLineClassName = classNames({ "history__checkpoint-line": true, "history__checkpoint-line--extend": !checkpointVisible });

									return (
										<div className="history__body" key={o.id}>
											{checkpointVisible && <div className="history__checkpoint" style={{ backgroundColor: onHandleColors(o.status) }} />}
											<div className={checkboxLineClassName} />

											<div className="history__start">
												<p className="history__invoice-no">{o.invoiceNo}</p>
												<p className="history__date">{formatDatePattern(o.invoiceDate, invoiceDateFormat)}</p>
											</div>

											<div className="history__center">
												<p className="history__amount">RM {formatCurrency(o.invoiceAmount)}</p>
												<AppStatus status={o.status} />
											</div>

											<div className="history__end">
												<button type="button" className="history__download-button" onClick={() => obj.onHandleDownloadInvoice(o)}>
													<img className="history__download-icon" src={uploadIcon} alt="download" />
												</button>
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		);
	}, []);

	const DebitNoteInfo = useCallback((obj) => {
		const yearsOption = Array.from({ length: +currentYear + 1 - +manufacturingYear }, (_, i) => +currentYear - i).map((o) => ({ label: o.toString(), value: o.toString() }));

		if (obj.page !== PAGES.DEBIT) return null;

		const onHandleSelectYear = (event) => {
			const value = event.target.value;
			setSelectedDebitYear(value);
			obj.onHandleGetDebitNote(value);
		};

		const onHandleColors = (status) => {
			let color = "rgb(0, 207, 156)";
			switch (status) {
				case COMMON.STATUS_ID.UNPAID:
					color = "rgb(255, 199, 112)";
					break;
				case COMMON.STATUS_ID.PAID:
					color = "#0245A9";
					break;
				default:
					break;
			}

			return color;
		};

		if (!obj.debitNote?.data) {
			return (
				<div className="policy-modal__loading">
					<LoadingLogo />
				</div>
			);
		}

		return (
			<div className="credit-debit-note">
				<div className="box">
					<div className="box__container">
						<div className="box__header">
							<p className="box__headline">Outstanding Premium</p>
						</div>

						<div className="box__divider" />

						<div className="box__body">
							<p className="box__text">Total Outstanding Premium Amount</p>

							<div className="box__content">
								<p className="box__amount">RM {formatCurrency(obj.debitNote.amount)}</p>

								<AppButton type="button" label="Make Payment" icon={<PayIcon />} disabled={obj.submitting || !obj.debitNote.amount} onClick={obj.onHandleMakePayment} />
							</div>
						</div>

						<div className="box__footer">
							<p className="box__note">
								* Kindly ensure all outstanding premium exceeding 60 days (as per policy terms) from the invoice date are settled to ensure that Guarantee Letter (GL) or Cashless Facilities (if any) for admission or any claims claim
								paid out are not interrupted.
							</p>
						</div>
					</div>
				</div>

				<AppSelectInput searchable={false} label="" placeholder="Please Select" value={obj.selectedDebitYear} options={yearsOption} onChange={onHandleSelectYear} />

				<div className="history">
					{Object.keys(obj.debitNote.data).map((month) => {
						const item = obj.debitNote.data[month];

						return (
							<div className="history__container" key={month}>
								<div className="history__header">
									<p className="history__month">{month}</p>
								</div>

								{item.map((o, i) => {
									const firstItem = i === 0;
									const checkpointVisible = (!firstItem && o.status !== item[i - 1].status) || firstItem;
									const checkboxLineClassName = classNames({ "history__checkpoint-line": true, "history__checkpoint-line--extend": !checkpointVisible });

									return (
										<div className="history__body" key={o.id}>
											{checkpointVisible && <div className="history__checkpoint" style={{ backgroundColor: onHandleColors(o.status) }} />}
											<div className={checkboxLineClassName} />

											<div className="history__start">
												<p className="history__invoice-no">{o.invoiceNo}</p>
												<p className="history__date">{formatDatePattern(o.invoiceDate, invoiceDateFormat)}</p>
											</div>

											<div className="history__center">
												<p className="history__amount">RM {formatCurrency(o.invoiceAmount)}</p>
												<AppStatus status={o.status} />
											</div>

											<div className="history__end">
												<button type="button" className="history__download-button" onClick={() => obj.onHandleDownloadInvoice(o)}>
													<img className="history__download-icon" src={uploadIcon} alt="download" />
												</button>
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		);
	}, []);

	const PaymentHistory = useCallback((obj) => {
		const yearsOption = Array.from({ length: +currentYear + 1 - +manufacturingYear }, (_, i) => +currentYear - i).map((o) => ({ label: o.toString(), value: o.toString() }));

		if (obj.page !== PAGES.PAYMENT_HISTORY) return null;

		const onHandleSelectYear = (event) => {
			const value = event.target.value;
			setSelectedPaymentHistoryYear(value);
			obj.onHandleGetPaymentHistory(value, obj.selectedPaymentHistory);
		};

		const onHandleSelectPaymentHistory = (event) => {
			const value = event.target.value;
			setSelectedPaymentHistory(value);
			obj.onHandleGetPaymentHistory(obj.selectedYear, value);
		};

		return (
			<div className="payment-history">
				<div className="payment-history__header">
					<AppSelectInput className="payment-history__type-select-input" searchable={false} label="" placeholder="Please Select" value={obj.selectedPaymentHistory} options={PAYMENT_TYPES} onChange={onHandleSelectPaymentHistory} />

					<AppSelectInput searchable={false} label="" placeholder="Please Select" value={obj.selectedYear} options={yearsOption} onChange={onHandleSelectYear} />
				</div>

				{!obj.paymentHistory && (
					<div className="policy-modal__loading">
						<LoadingLogo />
					</div>
				)}

				{obj.paymentHistory && (
					<div className="history">
						{Object.keys(obj.paymentHistory).map((month) => {
							const items = obj.paymentHistory[month];

							return (
								<div className="history__container" key={month}>
									<div className="history__header">
										<p className="history__month">{month}</p>
									</div>

									{Object.keys(items).map((a) => {
										const item = items[a];

										return item.map((b, i) => {
											const firstItem = i === 0;
											const checkpointVisible = (!firstItem && b.status !== item[i - 1].status) || firstItem;
											const checkboxLineClassName = classNames({ "history__checkpoint-line": true, "history__checkpoint-line--extend": !checkpointVisible });
											const total = item.reduce((o, j) => o + j.invoices.reduce((k, l) => k + l.invoiceAmount, 0), 0);

											return (
												<Fragment key={b.id}>
													<div className="history__body">
														<div className="history__checkpoint" />
														<div className={checkboxLineClassName} />

														<div className="history__start">
															<p className="history__invoice-no">{b.trxNo || b.refNo}</p>
															<p className="history__date">{formatDatePattern(b.createdDate, invoiceDateFormat)}</p>
														</div>

														<div className="history__center">
															<p className="history__amount">RM {formatCurrency(total)}</p>
															<AppStatus status={b.status || b.invoiceStatus} />
														</div>

														<div className="history__end">
															<button type="button" className="history__download-button" onClick={() => obj.onHandleDownloadPaymentReceipt(b, obj.selectedPaymentHistory)}>
																<img className="history__download-icon" src={uploadIcon} alt="download" />
															</button>
														</div>
													</div>
													<div className="history__body">
														<div className="history__checkpoint-line history__checkpoint-line--extend" />

														<ul className="history__list">
															{b.invoices.map((c) => {
																return (
																	<li key={c.invoiceNo} className="history__item">
																		<div className="history__start">
																			<p className="history__invoice-no">{c.invoiceNo}</p>
																			<p className="history__date">{formatDatePattern(c.invoiceDate, invoiceDateFormat)}</p>
																		</div>

																		<div className="history__center">
																			<p className="history__invoice-amount">RM {formatCurrency(c.invoiceAmount)}</p>
																		</div>
																	</li>
																);
															})}
														</ul>
													</div>
												</Fragment>
											);
										});
									})}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}, []);

	const RenewalPolicy = useCallback((obj) => {
		if (!obj.renewal) return null;

		const disabledRenewalQuotation = () => {
			const requestRevisedQuotation = obj?.status === COMMON.STATUS_ID.REQUEST_REVISED_QUOTATION;
			const pendingRevisedQuotation = obj?.status === COMMON.STATUS_ID.PENDING_REVISED_QUOTATION;

			return requestRevisedQuotation || pendingRevisedQuotation;
		};

		const disabled = obj.acceptingPayment || disabledRenewalQuotation();

		const header = () => {
			return (
				<div className="policy-renewal__header">
					<p className="policy-renewal__title">Policy Renewal</p>

					<AppButton outline type="button" label="Download Quotation" icon={uploadIcon} onClick={obj.onHandleDownloadQuotation} />
				</div>
			);
		};

		const acceptedRenewalPremium = () => {
			const quotation = obj.renewal.quotations.find((o) => o.status === COMMON.STATUS_ID.ACCEPTED);
			if (quotation) return formatCurrency(quotation.nettPremium);
			else return formatCurrency(0);
		};

		const policyIsExpired = obj.policyStatus === COMMON.STATUS_ID.EXPIRED;

		switch (obj.status) {
			case COMMON.STATUS_ID.COMPLETED:
				return (
					<div className="policy-renewal">
						{header()}

						<div className="policy-renewal__document">
							<div className="policy-renewal__content">
								<p className="policy-renewal__text">Renewal completed and the effective date will be updated upon the current expiration.</p>

								<div className="policy-renewal__list">
									<p className="policy-renewal__label">Renewal Ref. No.</p>
									<p className="policy-renewal__value">{obj.renewal?.refNo || "-"}</p>
								</div>
								<div className="policy-renewal__list">
									<p className="policy-renewal__label">Renewal Status</p>
									<p className="policy-renewal__value">Completed</p>
								</div>
								<div className="policy-renewal__list">
									<p className="policy-renewal__label">Renewal Premium</p>
									<p className="policy-renewal__value">{acceptedRenewalPremium()}</p>
								</div>
							</div>
						</div>
					</div>
				);
			case COMMON.STATUS_ID.PENDING_POLICY_NO:
			case COMMON.STATUS_ID.PENDING_IN_FORCE:
				return (
					<div className="policy-renewal">
						<div className="policy-renewal__document">
							<div className="policy-renewal__content">
								<p className="policy-renewal__text">Renewal processing in progress.</p>

								<div className="policy-renewal__list">
									<p className="policy-renewal__label">Renewal Ref. No.</p>
									<p className="policy-renewal__value">{obj.renewal?.refNo || "-"}</p>
								</div>
								<div className="policy-renewal__list">
									<p className="policy-renewal__label">Renewal Status</p>
									<p className="policy-renewal__value">{capitalizeCharacter(obj?.status?.replaceAll("_", " "))}</p>
								</div>
								<div className="policy-renewal__list">
									<p className="policy-renewal__label">Renewal Premium</p>
									<p className="policy-renewal__value">{acceptedRenewalPremium()}</p>
								</div>
							</div>
						</div>
					</div>
				);
			case COMMON.STATUS_ID.REJECTED:
				if (policyIsExpired) return null;

				return (
					<div className="policy-renewal">
						<div className="policy-renewal__document">
							<div className="policy-renewal__content">
								<p className="policy-renewal__text">
									Dear customer, your policy is expiring soon. Please note that access to the HR portal associated with your account will be terminated in 30 workings days from the policy expired date.
								</p>

								<div className="policy-renewal__list">
									<p className="policy-renewal__label">Renewal Status</p>
									<p className="policy-renewal__value">Rejected</p>
								</div>
							</div>
						</div>
					</div>
				);
			case COMMON.STATUS_ID.PENDING_ADDITIONAL_DOCS:
				return (
					<div className="policy-renewal">
						{header()}

						<div className="additional-document">
							<div className="additional-document__document">
								<div className="additional-document__header">
									<p className="additional-document__title">Remarks</p>

									<AppButton type="button" label="Upload Document" icon={<UploadIcon />} onClick={obj.onHandleUploadAdditionalDocuments} />
								</div>

								<div className="additional-document__content">
									<p className="additional-document__text">{obj?.renewal?.remarks || "-"}</p>
								</div>
							</div>
						</div>
					</div>
				);
			default:
				if (policyIsExpired) return null;

				return (
					<div className="policy-renewal">
						{header()}

						<div className="policy-renewal__content">
							<p className="policy-renewal__text">Your policy will expire soon. Kindly renew your policy within 30 days before the expiry date to ensure uninterrupted coverage.</p>

							<p className="policy-renewal__label">Renewal Premium</p>

							<ul className="policy-renewal__quotations">
								{obj.renewal?.quotations?.map((o, i) => {
									const oneQuotationOnly = obj.renewal.quotations.length === 1;
									const radioButtonClassName = classNames({ "policy-renewal__radio-button": true, "policy-renewal__radio-button--active": obj.selectedRenewalQuotation === o.id || oneQuotationOnly });

									return (
										<li className="policy-renewal__quotation" key={i}>
											<div className="policy-renewal__radio">
												<button type="button" className={radioButtonClassName} onClick={() => obj.onHandleSelectRenewalPolicy(o.id)} disabled={disabled} />
												<p className="policy-renewal__quotation-no">{o.quotationNo}</p>
											</div>

											<p className="policy-renewal__premium">RM {formatCurrency(o.nettPremium)}</p>
										</li>
									);
								})}
							</ul>
						</div>

						<div className="policy-renewal__button-container">
							{/* prettier-ignore */}
							<button type="button" className="policy-renewal__button policy-renewal__button--revise" disabled={disabled} onClick={obj.onHandleReviseQuotation}>Revise <ReviseIcon /></button>
							{/* prettier-ignore */}
							<button type="button" className="policy-renewal__button policy-renewal__button--reject" disabled={disabled} onClick={obj.onHandleRejectRenewal}>Reject <AppCloseIcon color="#f64444" /></button>
							{/* prettier-ignore */}
							<button type="button" className="policy-renewal__button policy-renewal__button--accept" disabled={disabled} onClick={obj.onHandleAcceptRenewal}>Accept <CheckedIcon /></button>
						</div>
					</div>
				);
		}
	}, []);

	return (
		<Fragment>
			<Modal classes={{ root: "app-policy-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="policy-modal" aria-describedby="policy-modal">
				<div className="policy-modal">
					<div className="policy-modal__main">
						<button type="button" className="policy-modal__close-button" onClick={onHandleDismiss}>
							<AppCloseIcon color="#666666" />
						</button>

						<div className="policy-modal__header">
							<p className="policy-modal__title">{data.productName}</p>
						</div>

						<div className="info">
							<div className="info__box">
								<p className="policy-modal__text">Nett Annual Premium Price</p>
								<p className="policy-modal__amount">RM {formatCurrency(data.nettAnnualPremiumPrice)}</p>
							</div>
							<div className="info__box">
								<p className="policy-modal__text">Status</p>
								<div className="policy-modal__title">
									<AppStatus status={data?.status || "-"} />
								</div>
							</div>
						</div>

						<div className="policy-modal__scrollable">
							{policyIsExpired && (
								<div className="policy-expired">
									<div className="policy-expired__content">
										<p className="policy-expired__text">Your policy is expiring soon. Please be reminded that your access to our HR portal will be terminated within 30 working days from the policy expiry date.</p>
									</div>
								</div>
							)}

							{/* prettier-ignore */}
							<RenewalPolicy renewal={data?.renewal} status={data?.renewal?.status} policyStatus={data?.status} acceptingPayment={acceptingPayment} selectedRenewalQuotation={selectedRenewalQuotation} onHandleReviseQuotation={onHandleReviseQuotation} onHandleRejectRenewal={onHandleRejectRenewal} onHandleAcceptRenewal={onHandleAcceptRenewal} onHandleSelectRenewalPolicy={onHandleSelectRenewalPolicy} onHandleUploadAdditionalDocuments={onHandleUploadAdditionalDocuments} onHandleDownloadQuotation={onHandleDownloadQuotation} />

							{/* prettier-ignore */}
							<AppSelectInput searchable={false} type="text" name="page" placeholder="Please Select" options={PAGE_OPTIONS} value={page} onChange={onHandleChangePage} />

							{/* prettier-ignore */}
							<PolicyInfo tabs={planTabs} page={page} tab={tab} data={data} policy={policies?.[tab]} policyInfo={policyInfo} tabClassName={tabClassName} onHandleTabChange={onHandleTabChange} onHandleDownloadPolicy={onHandleDownloadPolicy} />

							<InsurerInfo page={page} insurer={insurer} onHandleManageInsurer={onHandleManageInsurer} />

							{/* prettier-ignore */}
							<CreditNoteInfo page={page} selectedCreditYear={selectedCreditYear} creditNote={credit} submitting={submitting} onHandleRefundCredit={onHandleRefundCredit} onHandleGetCreditNote={onHandleGetCreditNote} onHandleDownloadInvoice={onHandleDownloadInvoice} />

							{/* prettier-ignore */}
							<DebitNoteInfo page={page} selectedDebitYear={selectedDebitYear} debitNote={debit} submitting={submitting} onHandleGetDebitNote={onHandleGetDebitNote} onHandleDownloadInvoice={onHandleDownloadInvoice} onHandleMakePayment={onHandleMakePayment} />

							{/* prettier-ignore */}
							<PaymentHistory page={page} selectedYear={selectedPaymentHistoryYear} selectedPaymentHistory={selectedPaymentHistory} paymentHistory={paymentHistory} onHandleGetPaymentHistory={onHandleGetPaymentHistory} onHandleDownloadPaymentReceipt={onHandleDownloadPaymentReceipt}/>
						</div>
					</div>
				</div>
			</Modal>

			<AppDownloadPolicyModal ref={downloadPolicyRef} />

			<AppDownloadInvoiceModal ref={downloadInvoiceRef} />

			<AppUnpaidDebitNoteModal ref={upaidDebitNoteRef} />

			<AppDownloadPaymentReceiptModal ref={downloadPaymentInvoiceRef} />

			<AppRejectPolicyRenewalModal ref={rejectPolicyRenewalRef} {...data} onHandleDimissPolicyModal={onHandleDismiss} />

			<AppDownloadQuotationModal ref={downloadQuotationRef} />

			<AppReviseQuotationEmployeesModal ref={reviseQuotationEmployeesRef} onHandleDimissPolicyModal={onHandleDismiss} />

			<AppUploadAdditionalQuotationDocumentsModal ref={uploadAdditionalQuotationDocumentRef} {...data} onHandleDimissPolicyModal={onHandleDismiss} />
		</Fragment>
	);
};

export default memo(forwardRef(AppPolicyModal));

AppPolicyModal.propTypes = {
	ref: PropTypes.object,
};
